import React, { useContext } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

const WrapperTextField = styled(TextField)`
  width: 100%;

  label {
    width: 100%;
  }
  .MuiInputLabel-root {
    font-size: 16px;
    line-height: 16px;
    color: #292d32;
    font-family: outfit_regular;
    transform: none;
  }

  .MuiInput-root {
    border-bottom: 1px solid #efe3d7 !important;
    height: 50px !important;
    color: ${(props) => props.theme.lightGray};
    font-family: outfit_regular;
    font-size: 16px;
    line-height: 16px;
    &:before {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }

    .MuiSelect-select {
      background: none !important;
    }

    .MuiSelect-nativeInput {
      padding: ${(props) => props.inputPadding};
      color: #292d32;
      opacity: 1;
      // border-bottom: 1px solid #efe3d7 !important;
      height: 46px !important;
      bottom: 1px;
      font-family: outfit_regular;
      line-height: 16px;
      background: none !important;

      &::placeholder {
        color: #959595;
        font-size: 16px;
      }
    }

    .MuiSvgIcon-root {
      position: ${(props) => (props.locale === 'en' ? 'unset' : 'relative')};
      margin-left: ${(props) => (props.locale === 'en' ? '' : '16px')};
      margin-top: ${(props) => (props.locale === 'en' ? '' : '-30px')};
      border: 1px solid ${(props) => props.theme.darkArrow};
      border-width: 0 2px 2px 0;
      display: inline-block;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      padding: 3px;
      font-size: 16px;
      right: 15px;
      color: #c56616;
      width: 10px;
      height: 10px;
    }

    .MuiSvgIcon-root.MuiSelect-iconOpen {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      top: calc(50% - 0em);
    }
  }

  .MuiInput-root.Mui-focused {
    border-bottom: 1px solid #efe3d7 !important; /* Ensure focused state also has border-bottom */
    color: red;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: #fff2f8 !important;
    color: #292d32 !important;
  }
`;

const WrapperErrorDiv = styled.div`
  font-size: 16px;
  color: red;
  line-height: 16px;
  font-family: outfit_regular;
  position: absolute;
  bottom: -23px;
`;

function SelectInput({ containerProps, ...props }) {
  return (
    <FormControl fullWidth>
      <WrapperTextField
        select
        id="standard-required"
        variant="standard"
        fullWidth={true}
        disabled={props?.disabled ? true : false}
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      >
        {props?.data?.length > 0 &&
          props?.data.map((k, i) => (
            <StyledMenuItem
              onClick={(e) => props?.getValue && props?.getValue(e, k)}
              value={k}
            >
              {k?.name ||
                k?.qualification ||
                k.practice_name ||
                (k?.date && moment(k?.date).format('Do MMMM, YYYY'))}
            </StyledMenuItem>
          ))}
      </WrapperTextField>

      {props.errorText && (
        <WrapperErrorDiv>
          {/* <ErrorOutlineIcon /> {props.errorText} */}
          {props.errorText}
        </WrapperErrorDiv>
      )}
    </FormControl>
  );
}

SelectInput.defaultProps = {
  height: 50,
  inputPadding: '14px',
};

export default SelectInput;
