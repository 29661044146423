import axios from 'axios';
import { postMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const CREATE_AVAVILABLITY = 'CREATE_AVAVILABLITY';
export const CREATE_AVAVILABLITY_SUCCESS = 'CREATE_AVAVILABLITY_SUCCESS';
export const CREATE_AVAVILABLITY_FAILURE = 'CREATE_AVAVILABLITY_FAILURE';

// Action Creators
export const createAvailRequest = () => ({
  type: CREATE_AVAVILABLITY,
});

export const createAvailSuccess = (users) => ({
  type: CREATE_AVAVILABLITY_SUCCESS,
  payload: users,
});

export const createAvailFailure = (error) => ({
  type: CREATE_AVAVILABLITY_FAILURE,
  payload: error,
});

// Thunk Function

export const createAvailPost = (body, external) => {
  return async (dispatch) => {
    dispatch(createAvailRequest());
    try {
      const response = await postMethodWithToken(url.postAvailability, body);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(createAvailSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(createAvailFailure(error));
    }
  };
};
