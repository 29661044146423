import axios from 'axios';
import { postMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_PENDING_DETAIL = 'GET_PENDING_DETAIL';
export const GET_PENDING_DETAIL_SUCCESS = 'GET_PENDING_DETAIL_SUCCESS';
export const GET_PENDING_DETAIL_FAILURE = 'GET_PENDING_DETAIL_FAILURE';

// Action Creators
export const getPendingRequest = () => ({
  type: GET_PENDING_DETAIL,
});

export const getPendingSuccess = (users) => ({
  type: GET_PENDING_DETAIL_SUCCESS,
  payload: users,
});

export const getPendingFailure = (error) => ({
  type: GET_PENDING_DETAIL_FAILURE,
  payload: error,
});

// Thunk Function
export const postPendig = (external, body) => {
  return async (dispatch) => {
    dispatch(getPendingRequest());
    try {
      const response = await postMethodWithToken(url?.postPending, body);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getPendingSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getPendingFailure(error?.message));
    }
  };
};
