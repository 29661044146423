import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import requestCheck from 'assets/images/Dashboard/pending-detail.svg';
import ArrowLeftIcon from 'assets/images/arrow-left.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import ClockIcon from 'assets/images/Dashboard/clock.svg';
import CalerderIcon from 'assets/images/Dashboard/calendar.svg';
import { getAppointmentDetail } from 'store/getAppointmentDetail/getAppointmentDetailAcition';
import { ClearTokenRedirectLogin } from 'utils/general';
import ToastNotification from 'components/commom/Toast';
import moment from 'moment';
import EditMedicine from 'assets/images/Dashboard/edit-pending.svg';
import DeleteMedicine from 'assets/images/Dashboard/delete-pending.svg';
import ReusableButton from 'components/commom/Button';
import AddIcon from 'assets/images/Dashboard/add-medicin-icon.svg';
import CommonModal from 'components/commom/Modal';
import LabelWithInput from 'components/commom/LabelWithInput';
import LabelWithTextArea from 'components/commom/LabelWithTextArea';
import PendingProdcut from 'assets/images/Dashboard/product-pending.svg';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from 'assets/images/Dashboard/search-pending.svg';
import CloseProduct from 'assets/images/Dashboard/cancel-pending.svg';
import { postPendig } from 'store/createPendingDetail/createPendingDetailAction';
import { doctorAppointments } from 'routes/AppUrls';

const PendingDetail = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const { locale } = useContext(LocaleContext);
  const [appointmentPendingDetail, setAppointmentPendingDetail] = useState('');
  const [conf, setConf] = ToastNotification();
  const [medicineModal, setMedicineModal] = useState(false);
  const [adviceModal, setAdviceModal] = useState(false);
  const [followUpDate, setFollowUpDate] = useState('');
  const [productModal, setProductModal] = useState(false);
  const [adviceText, setAdviceText] = useState('');
  const [adviceTextShow, setAdviceTextShow] = useState('');
  const [medicinDetail, setMedicinDetail] = useState([]);
  const [formData, setFormData] = useState({
    medication_name: '',
    medicine_form: '',
    dosage: '',
    duration: '',
  });

  useEffect(() => {
    getPendingDetail();
  }, []);

  const getPendingDetail = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentPendingDetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getAppointmentDetail(external, params?.id));
  };

  const [editIndex, setEditIndex] = useState(null);

  const handleAddMedicine = (medicine = null, index = null) => {
    setMedicineModal(true);
    setEditIndex(index);
    if (medicine) {
      setFormData({
        medication_name: medicine?.medication_name || '',
        medicine_form: medicine?.medicine_form || '',
        dosage: medicine?.dosage || '',
        duration: medicine?.duration || '',
      });
    } else {
      setFormData({
        medication_name: '',
        medicine_form: '',
        dosage: '',
        duration: '',
      });
    }
  };
  const handleDeleteMedicine = (index) => {
    const updatedMedicineList = medicinDetail.filter((_, i) => i !== index);
    setMedicinDetail(updatedMedicineList);
  };

  const handleCloseMedicine = () => {
    setMedicineModal(false);
  };

  const handleInputChange = (event, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: event?.target?.value,
    }));
  };

  const handleSaveMedicine = () => {
    let valueMedicine = [...medicinDetail];

    if (editIndex !== null) {
      valueMedicine[editIndex] = {
        medication_name: formData?.medication_name,
        medicine_form: formData?.medicine_form,
        dosage: formData?.dosage,
        duration: formData?.duration,
      };
    } else {
      valueMedicine.push({
        medication_name: formData?.medication_name,
        medicine_form: formData?.medicine_form,
        dosage: formData?.dosage,
        duration: formData?.duration,
      });
    }

    setMedicinDetail(valueMedicine);
    setMedicineModal(false);
    setEditIndex(null);
  };

  const handleAdvice = () => {
    setAdviceModal(true);
  };

  const handleCloseAdvice = () => {
    setAdviceModal(false);
  };

  const handleAdviceShow = () => {
    setAdviceTextShow(adviceText);
    setAdviceModal(false);
  };

  const handleAddProduct = () => {
    setProductModal(true);
  };

  const handleCloseProduct = () => {
    setProductModal(false);
  };

  const handleCreateDetail = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(doctorAppointments(locale));
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {
      consultation_id: params?.id,
      follow_up_date: followUpDate,
      advice: adviceText,
      medicines: medicinDetail,
    };
    dispatch(postPendig(external, body));
  };
  return (
    <div>
      <Row className="bc-FEF9F6 m-0 p-0">
        <Col
          className={
            locale === 'en'
              ? 'commonEnContainer lg:ml-left border-l bColor-EFE3D7'
              : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
          }
        >
          <div className="flex pb-16">
            <div className="fs-12 lh-24 fc-998A7B family_outfit_regular_400">
              Appointments
            </div>
            <div className="pl-04 flex items-center">
              <Image src={requestCheck} alt="icon" />
            </div>
            <div className="pl-04 fs-12 fc-242E39 lh-24 family_outfit_semiBold_600">
              Generate Diagnosis Report
            </div>
          </div>
          <Row className="bc-FFFFFF m-0 p-24 border-1 bColor-EFE3D7">
            <Col lg={12} className="p-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={ArrowLeftIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(-1)}
                />
                <div className="fs-20 lh-252 fc-292D32 family_outfit_semiBold_600">
                  <FormattedMessage id="Generate Diagnosis Report" />
                </div>
              </Stack>
            </Col>
            <Col lg={12}>
              <div className="fs-14 lh-16 pt-8 pl-18 pb-16 fc-998A7B family_outfit_regular_400">
                <FormattedMessage id="Appointment no." />
                {appointmentPendingDetail?.booking_id}
              </div>
            </Col>
            <Col md={5} className="p-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={
                    appointmentPendingDetail?.patient?.profile_pic !== ''
                      ? appointmentPendingDetail?.patient?.profile_pic
                      : DefaultImage
                  }
                  alt="icon"
                  style={{
                    height: '88px',
                    width: '88px',
                    borderRadius: '50%',
                  }}
                />
                <div>
                  <div className="fs-18 lh-2268 fc-202224 family_outfit_medium_500">
                    {appointmentPendingDetail?.patient?.full_name}
                  </div>
                  <div className="pt-8 fs-14 fc-998A7B family_outfit_regular_400">
                    ID:
                    <span className="fc-3C3C3C family_outfit_medium_500">
                      {appointmentPendingDetail?.patient?.patient_id}
                    </span>
                  </div>
                </div>
              </Stack>
            </Col>
            <Col md={7}>
              <Row>
                <Col md={6} className="p-0">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <Image src={CallIcon} alt="icon" />
                    <div className="fs-16 lh-201 family_outfit_regular_400 fc-202224">
                      {`${appointmentPendingDetail?.patient?.country_code} ${appointmentPendingDetail?.patient?.contact_number}`}
                    </div>
                  </Stack>
                </Col>
                <Col md={6}>
                  <Stack
                    direction="horizontal"
                    gap={1}
                    className="flex items-center"
                  >
                    <div className="fs-16 lh-201 fc-998A7B family_outfit_regular_400">
                      Modality:
                    </div>
                    <div>
                      <img
                        src={
                          appointmentPendingDetail?.consultation_type ===
                          'Hospital Visit'
                            ? HospitalIcon
                            : VideoCallCameraIcon
                        }
                        alt="icon"
                      />
                    </div>
                    <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                      {appointmentPendingDetail?.consultation_type}
                    </div>
                  </Stack>
                </Col>
                <Col md={6} className="p-0">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center pt-24"
                  >
                    <Image src={MailIcon} alt="icon" />
                    <div className="fs-16 lh-201 family_outfit_regular_400 fc-202224 break-words ...">
                      {appointmentPendingDetail?.patient?.email}
                    </div>
                  </Stack>
                </Col>
                <Col md={6}>
                  <Stack
                    direction="horizontal"
                    gap={5}
                    className="items-center pt-24"
                  >
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <Image src={CalerderIcon} alt="icon" />
                      <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                        {moment(
                          appointmentPendingDetail?.appointment_date_time
                        ).format('MMMM D, YYYY')}
                      </div>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <Image src={ClockIcon} alt="icon" />
                      <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                        {moment(
                          appointmentPendingDetail?.appointment_date_time
                        ).format('hh:mmA')}
                      </div>
                    </Stack>
                  </Stack>
                </Col>
              </Row>
            </Col>

            <Col md={12} className="p-0">
              <div className="fs-20 pt-24 lh-252 fc-292D32 family_outfit_semiBold_600">
                Report
              </div>
            </Col>
            <Col md={12} className="p-0">
              <div className="fs-17 lh-21 pt-24 fc-292D32 family_outfit_medium_500">
                Medicine (Rx)
              </div>
            </Col>
            {medicinDetail &&
              medicinDetail.length > 0 &&
              medicinDetail.map((item, index) => {
                console.log(item, '1212');
                return (
                  <Col md={8} className="p-0">
                    <div className="m-0 p-16 mt-3 border-1 bColor-EFE3D7">
                      <div className="flex justify-between">
                        <div className="fs-16 lh-16 fc-292D32 family_outfit_medium_500">
                          {item?.medication_name}
                        </div>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center cursor-pointer ..."
                        >
                          <div>
                            <Image
                              src={EditMedicine}
                              alt="icon"
                              onClick={() => handleAddMedicine(item, index)}
                            />
                          </div>
                          <div>
                            <Image
                              src={DeleteMedicine}
                              alt="icon"
                              onClick={() => handleDeleteMedicine(index)}
                            />
                          </div>
                        </Stack>
                      </div>
                      <Stack
                        direction="horizontal"
                        gap={5}
                        className="items-center pt-16"
                      >
                        <div className="fs-14 lh-16 fc-998A7B family_outfit_regular_400">
                          Form:
                          <span className="fs-16 pl-04 lh-16 fc-3C3C3C family_outfit_regular_400">
                            {item?.medicine_form}
                          </span>
                        </div>
                        <div className="fs-14 lh-16 fc-998A7B family_outfit_regular_400">
                          Dosage:
                          <span className="fs-16 pl-04 lh-16 fc-3C3C3C family_outfit_regular_400">
                            {item?.dosage}
                          </span>
                        </div>
                        <div className="fs-14 lh-16 fc-998A7B family_outfit_regular_400">
                          Duration:
                          <span className="fs-16 pl-04 lh-16 fc-3C3C3C family_outfit_regular_400">
                            {item?.duration}
                          </span>
                        </div>
                      </Stack>
                    </div>
                  </Col>
                );
              })}
            <Col md={8} className="p-0">
              <div className="m-0 pt-16">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#375241"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 25px"
                  height="40px"
                  bgColor="transparent"
                  brColor="#375241"
                  fontFamily="outfit_medium"
                  onClick={() => handleAddMedicine()}
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Add break" />
                </ReusableButton>
              </div>
            </Col>

            <Col md={8} className="p-0">
              <div className="flex justify-between">
                <div className="fs-17 lh-21 pt-24 fc-292D32 family_outfit_medium_500">
                  Advice
                </div>
                <div className="flex items-end">
                  <Image
                    src={EditMedicine}
                    alt="icon"
                    onClick={() => handleAdvice()}
                    className="cursor-pointer ..."
                  />
                </div>
              </div>
            </Col>
            <Col md={8} className="p-0">
              <div className="fs-16 pt-16 fc-998A7B lh-201 family_outfit_regular_400">
                {adviceTextShow}
              </div>
            </Col>
            <Col md={8} className="p-0">
              <div className="fs-17 lh-21 pt-24 fc-292D32 family_outfit_medium_500">
                Follow Up Date
              </div>
            </Col>
            <Row>
              <Col md={4} className="p-0">
                <div className="pt-12">
                  <LabelWithInput
                    type="date"
                    borderShow={'1px solid #EFE3D7'}
                    borderBottomShow={'1px solid #EFE3D7'}
                    value={followUpDate}
                    onChange={(e) => setFollowUpDate(e.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <Col md={8} className="p-0">
              <div className="fs-17 lh-21 pt-24 fc-292D32 family_outfit_medium_500">
                Recommended Products
              </div>
            </Col>
            <Col md={8} className="p-0">
              <Row className="mt-4">
                <Col md={6}>
                  <div className="border-1 bColor-EFE3D7 p-8">
                    <Row className="g-2">
                      <Col md={3}>
                        <div>
                          <Image
                            src={PendingProdcut}
                            alt="icon"
                            style={{ width: '100%' }}
                          />
                        </div>
                      </Col>
                      <Col md={9}>
                        <div className="fs-14 lh-18 pt-8 fc-292D32 family_outfit_regular_400">
                          Lorem Ipsum Dolor Sit Amet Consecteur
                        </div>
                        <div className="fs-14 lh-18 pt-04 fc-A4AAB2 family_outfit_regular_400">
                          Recommended Products
                        </div>
                        <div className="flex justify-between pt-12">
                          <div className="fs-16 lh-20  fc-292D32 family_outfit_semiBold_600">
                            Advice
                          </div>
                          <div>
                            <Image
                              src={DeleteMedicine}
                              alt="icon"
                              className="cursor-pointer ..."
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={8} className="p-0">
              <div className="m-0 pt-16">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#375241"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 25px"
                  height="40px"
                  bgColor="transparent"
                  brColor="#375241"
                  fontFamily="outfit_medium"
                  onClick={() => handleAddProduct()}
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Add Product" />
                </ReusableButton>
              </div>
            </Col>
            <Col md={12} className="p-0 flex justify-end">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex items-center"
              >
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#3C3C3C"
                  fontSize="14px"
                  fontWeight="400"
                  padding="14px 55px"
                  height="40px"
                  bgColor="transparent"
                  brColor="#375241"
                  fontFamily="outfit_regular"
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage id="Go Back" />
                </ReusableButton>
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="500"
                  padding="14px 55px"
                  height="40px"
                  bgColor="#375241"
                  brColor="#375241"
                  fontFamily="outfit_medium"
                  onClick={() => handleCreateDetail()}
                >
                  <FormattedMessage id="Generate & Notify Patient" />
                </ReusableButton>
              </Stack>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonModal
        padding={'24px'}
        width={'628px'}
        modalActive={medicineModal}
        innerContent={
          <div className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col md={12} className="p-0 m-0">
                <div className="fs-24 fc-242E39 lh-3024 family_outfit_semiBold_600">
                  Add Medicine
                </div>
              </Col>
              <Col md={12} className="p-0 m-0">
                <div className="pt-16">
                  <LabelWithInput
                    label="Medicine"
                    placeholder="Medicine Name"
                    name="medication_name"
                    type="text"
                    value={formData?.medication_name}
                    onChange={(e) => handleInputChange(e, 'medication_name')}
                  />
                </div>
              </Col>
              <div className="pt-16">
                <Row>
                  <Col md={4} className="p-0 m-0">
                    <LabelWithInput
                      label="Form"
                      placeholder="Form"
                      name="medicine_form"
                      type="text"
                      value={formData?.medicine_form}
                      onChange={(e) => handleInputChange(e, 'medicine_form')}
                    />
                  </Col>
                  <Col md={4} className="modal-size">
                    <LabelWithInput
                      label="Dosage"
                      placeholder="Dosage"
                      name="dosage"
                      type="text"
                      value={formData?.dosage}
                      onChange={(e) => handleInputChange(e, 'dosage')}
                    />
                  </Col>
                  <Col md={4} className="modal-size">
                    <LabelWithInput
                      label="Duration"
                      placeholder="Duration"
                      name="duration"
                      type="text"
                      value={formData?.duration}
                      onChange={(e) => handleInputChange(e, 'duration')}
                    />
                  </Col>
                </Row>
              </div>
              <Col md={12} className="p-0 flex justify-end">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center pt-16"
                >
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#3C3C3C"
                    fontSize="14px"
                    fontWeight="400"
                    padding="14px 55px"
                    height="40px"
                    bgColor="transparent"
                    brColor="#375241"
                    fontFamily="outfit_regular"
                    onClick={() => handleCloseMedicine()}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#F5F5F5"
                    fontSize="16px"
                    fontWeight="500"
                    padding="14px 55px"
                    height="40px"
                    bgColor="#375241"
                    brColor="#375241"
                    fontFamily="outfit_medium"
                    onClick={() => handleSaveMedicine()}
                  >
                    <FormattedMessage id="Add" />
                  </ReusableButton>
                </Stack>
              </Col>
            </Row>
          </div>
        }
      />
      <CommonModal
        padding={'24px'}
        width={'694px'}
        modalActive={adviceModal}
        innerContent={
          <div>
            <Row>
              <Col md={12} className="p-0 m-0">
                <div className="fs-24 fc-242E39 lh-3024 family_outfit_semiBold_600">
                  Add Medicine
                </div>
              </Col>
              <Col md={12} className="p-0">
                <div className="pt-16">
                  <LabelWithTextArea
                    placeholder={intl.formatMessage({ id: 'Write here...' })}
                    height="100"
                    multiline
                    name="bio"
                    Color={'#212426'}
                    padding={'8px'}
                    value={adviceText}
                    onChange={(e) => setAdviceText(e?.target?.value)}
                  />
                </div>
              </Col>
              <Col md={12} className="p-0 flex justify-end">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center pt-16"
                >
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#3C3C3C"
                    fontSize="14px"
                    fontWeight="400"
                    padding="14px 55px"
                    height="40px"
                    bgColor="transparent"
                    brColor="#375241"
                    fontFamily="outfit_regular"
                    onClick={() => handleCloseAdvice()}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#F5F5F5"
                    fontSize="16px"
                    fontWeight="500"
                    padding="14px 55px"
                    height="40px"
                    bgColor="#375241"
                    brColor="#375241"
                    fontFamily="outfit_medium"
                    onClick={() => handleAdviceShow()}
                  >
                    <FormattedMessage id="Done" />
                  </ReusableButton>
                </Stack>
              </Col>
            </Row>
          </div>
        }
      />

      <CommonModal
        padding={'24px'}
        width={'694px'}
        modalActive={productModal}
        innerContent={
          <div>
            <Row>
              <Col md={12} className="p-0 m-0">
                <div className="fs-24 fc-242E39 lh-3024 family_outfit_semiBold_600">
                  Recommend a product
                </div>
              </Col>
              <Col md={12} className="p-0">
                <div className="pt-16">
                  <LabelWithInput
                    label="Search for product"
                    placeholder="Search for product"
                    name="providerName"
                    type="text"
                    borderShow={'1px solid #EFE3D7'}
                    borderRadius={'8px'}
                    // value={formData?.providerName}
                    // onChange={(e) => handleInputChange(e, 'providerName')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Image
                            src={SearchIcon}
                            alt="icon"
                            style={{ cursor: 'pointer' }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="pt-16">
                  <div className="border-1 bColor-EFE3D7 p-8">
                    <Row className="g-2">
                      <Col md={2}>
                        <div>
                          <Image
                            src={PendingProdcut}
                            alt="icon"
                            style={{ width: '100%' }}
                          />
                        </div>
                      </Col>
                      <Col md={10}>
                        <div className="flex justify-between pt-8">
                          <div className="fs-14 lh-18 fc-292D32 family_outfit_regular_400">
                            Lorem Ipsum Dolor Sit Amet Consecteur
                          </div>
                          <div>
                            <div>
                              <Image
                                src={CloseProduct}
                                alt="icon"
                                className="cursor-pointer ..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="fs-14 lh-18 pt-04 fc-A4AAB2 family_outfit_regular_400">
                          Recommended Products
                        </div>
                        <div className="fs-16 lh-20 pt-12 fc-292D32 family_outfit_semiBold_600">
                          Advice
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={12} className="p-0 flex justify-end">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center pt-16"
                >
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#3C3C3C"
                    fontSize="14px"
                    fontWeight="400"
                    padding="14px 55px"
                    height="40px"
                    bgColor="transparent"
                    brColor="#375241"
                    fontFamily="outfit_regular"
                    onClick={() => handleCloseProduct()}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#F5F5F5"
                    fontSize="16px"
                    fontWeight="500"
                    padding="14px 55px"
                    height="40px"
                    bgColor="#375241"
                    brColor="#375241"
                    fontFamily="outfit_medium"
                  >
                    <FormattedMessage id="Done" />
                  </ReusableButton>
                </Stack>
              </Col>
            </Row>
          </div>
        }
      />
    </div>
  );
};

export default PendingDetail;
