import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import LabelWithInput from 'components/commom/LabelWithInput';
import LabelWithTextArea from 'components/commom/LabelWithTextArea';
import ReusableTabs from 'components/commom/Tab';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInput from 'components/commom/SelectInput';
import { useIntl } from 'react-intl';
import { LocaleContext } from 'routes/Router';
import Button from '@mui/material/Button';
import MailIcon from 'assets/images/mail.svg';
import PhoneIcon from 'assets/images/phone-icon.svg';
import ArrowBack from 'assets/images/arrow-left.svg';
import UploadIcon from 'assets/images/camera.svg';
import { useDispatch, useSelector } from 'react-redux';
import { registerUploadAction } from 'store/registerUpload/registerUploadAction';
import AbsoluteLogoMobile from 'assets/images/absolute-logo-phone.svg';
import { registerProviderType } from 'store/registerProviderType/registerProviderTypeAction';
import { registerTitleType } from 'store/registerTitle/registerTitleAction';
import TabSwitch from 'components/commom/SwitchLabelInput';
import { registerQualification } from 'store/registerQualification/registerQualificationAction';
import { registerOnePost } from 'store/registerStepOne/registerStepOneAction';
import { getCity } from 'store/getCity/getCityAction';
import { registerUsers } from 'store/register/registerAction';
import { getReviewDetail } from 'store/registerReview/registerReviewAction';
import CheckValidation from 'utils/validate';
import ToastNotification from 'components/commom/Toast';
import { registerVerifyPost } from 'store/registerVerify/registerVeifyAction';
import LocalStorageManager from 'managers/LocalStorageManger';
import { registerProfessionalDetail } from 'routes/AppUrls';

const RegistrationStepOne = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [conf, setConf] = ToastNotification();
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const providerData = useSelector(
    (state) => state?.registerProviderTypeReducer?.users?.data
  );
  const doctorTitle = useSelector(
    (state) => state?.registerTitleReducer?.users?.data
  );
  const Qualification = useSelector(
    (state) => state?.registerQualificationReducer?.users?.data
  );
  const stateUsers = useSelector((state) => state?.registerUserReducer?.users);
  const cityUsers = useSelector((state) => state?.getCityReducer?.users);
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const [profileUpload, setProfileUpload] = useState('');
  const [profileId, setProfileId] = useState('');
  const [formData, setFormData] = useState({
    providerName: '',
    email: '',
    country_code: '',
    mobileNumber: '',
    providerType: 7,
    highestQualification: '',
    highestQualificationId: '',
    state: '',
    city: '',
    stateId: '',
    cityId: '',
    latitude: '',
    longitude: '',
    streetAddress: '',
    zipCode: '',
    title: 9,
    titleSlug: '',
    bio: '',
  });
  const handleChange = (event) => {
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleInputChange = (event) => {
    const external = {
      run: true,
      handleFunction: (res) => {},
      error: true,
      handleErrorFunction: (res) => {},
    };
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value?.name,
      stateId: name === 'state' ? value?.id : prev.stateId,
      cityId: name === 'city' ? value?.id : name === 'state' ? '' : prev.cityId,
      latitude:
        name === 'city'
          ? value?.latitude
          : name === 'state'
            ? ''
            : prev.latitude,
      longitude:
        name === 'city'
          ? value?.longitude
          : name === 'state'
            ? ''
            : prev.longitude,
      ...(name === 'state' && { city: '' }),
    }));

    if (name === 'state') {
      dispatch(getCity(tab, value?.id, external));
    }
  };
  const handleQualificationChange = (event) => {
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      ['highestQualification']: value?.qualification,
      ['highestQualificationId']: value?.id,
    }));
  };
  const handleCheckboxChange = (e, name, id) => {
    const external = {
      run: true,
      handleFunction: (res) => {},
      error: true,
      handleErrorFunction: (res) => {},
    };
    setFormData((prevState) => ({
      ...prevState,
      [name]: id?.id,
      ['titleSlug']: id?.slug,
      ['highestQualification']:
        name === 'title' ? '' : prevState.highestQualification,
      ['highestQualificationId']:
        name === 'title' ? '' : prevState.highestQualificationId,
    }));
    if (name === 'title') {
      dispatch(registerQualification(tab, id?.slug, external));
    }
  };
  useEffect(() => {
    const external = {
      run: true,
      handleFunction: (res) => {
        LocalStorageManager.setItem('userId', res?.data?.user_id);
        dispatch(
          getReviewDetail(res?.data?.user_id, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
          })
        );
      },
      error: true,
      handleErrorFunction: (res) =>
        dispatch(
          getReviewDetail(LocalStorageManager.getItem('userId'), {
            run: true,
            handleFunction: (res) => handleSuccess(res),
          })
        ),
    };
    let body = {
      token: token,
    };
    dispatch(registerVerifyPost(body, external));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.jpg, .png, .jpeg',
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('filePath', file);
      });
      const external = {
        run: true,
        handleFunction: (res) => {
          setProfileUpload(res?.data?.[0]?.filePath);
          setProfileId(res?.data?.[0]?.id);
          setConf({ msg: res?.message, variant: 'success' });
        },
        error: true,
        handleErrorFunction: (res) =>
          setConf({ msg: res?.message, variant: 'error' }),
      };
      dispatch(registerUploadAction(formData, external));
    },
  });
  useEffect(() => {
    const external = {
      run: true,
      handleFunction: (res) => {},
      error: true,
      handleErrorFunction: (res) => {},
    };
    dispatch(registerProviderType('provider-type', external));
    dispatch(registerTitleType('doctor-title', external));
  }, []);

  const handleSuccess = (res) => {
    setFormData((prevState) => ({
      ...prevState,
      ['providerName']: res?.data?.full_name,
      ['email']: res?.data?.email,
      ['country_code']: res?.data?.country_code,
      ['mobileNumber']: res?.data?.contact_number,
      ['providerType']: res?.data?.provider_type?.id,
      ['title']: res?.data?.title?.id,
      ['titleSlug']: res?.data?.title?.slug,
      ['highestQualification']: res?.data?.highest_qualification?.qualification,
      ['highestQualificationId']: res?.data?.highest_qualification?.id,
      ['state']: res?.data?.address?.state?.name,
      ['city']: res?.data?.address?.city?.name,
      ['stateId']: res?.data?.address?.state?.id,
      ['cityId']: res?.data?.address?.city?.id,
      ['latitude']: res?.data?.address?.latitude,
      ['longitude']: res?.data?.address?.longitude,
      ['streetAddress']: res?.data?.address?.address_line1,
      ['zipCode']: res?.data?.address?.zip_code,
      ['bio']: res?.data?.bio,
    }));
    setProfileUpload(res?.data?.profile_image);
    setTab(res?.data?.address?.country?.id);
    dispatch(registerUsers(res?.data?.address?.country?.id));
    dispatch(
      registerQualification(
        tab,
        res?.data?.title !== null ? res?.data?.title?.slug : 'dermatologist'
      )
    );
  };

  const tabData = [
    { label: intl.formatMessage({ id: 'United States of America' }) },
    { label: intl.formatMessage({ id: 'Jamaica' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };
  const hanldeRegisterStepOne = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(registerProfessionalDetail(locale));
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    const error = CheckValidation(formData);
    setIsError(error);
    let address = {
      address_line1: formData?.streetAddress,
      zip_code: formData?.zipCode,
      country: tab,
      state: formData?.stateId,
      city: formData?.cityId,
    };
    let body = {
      bio: formData?.bio,
      provider_type_id: formData?.providerType,
      title_id: formData?.title,
      higher_qualification_id: formData?.highestQualificationId,
      address: address,
    };
    if (profileId === '') {
      delete body['profile_pic_id'];
    } else {
      body[' profile_pic_id'] = +profileId;
    }
    if (formData?.latitude !== null && formData?.latitude !== undefined) {
      address.latitude = formData.latitude;
    }
    if (formData?.longitude !== null && formData?.longitude !== undefined) {
      address.longitude = formData.longitude;
    }
    dispatch(
      registerOnePost(body, LocalStorageManager.getItem('userId'), external)
    );
  };
  return (
    <>
      <Row className="m-0 p-0 landing-image">
        <Row className="mb-4 pt-80 size-padding flex justify-center">
          <Col
            xs={12}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            className="bg-gray-100 sm:bg-light-100 m-0 p-0"
          >
            <div class="p-16 sm:p-32">
              <div className="d-flex">
                <div className="d-flex">
                  <Image src={ArrowBack} alt={'icon'} />
                </div>
                <div className="main-heading pl-8 family_outfit_semiBold_600">
                  Registration
                </div>
              </div>
              <Row className="m-0 p-0">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="m-0 p-0 d-none d-sm-flex"
                >
                  <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                    Step 1/3
                  </p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="m-0 p-0 d-none d-sm-flex"
                >
                  <p className="pt-04 m-0 fs-18 family_outfit_semiBold_600 lh-2068 fc-292D32">
                    Personal Details
                  </p>
                </Col>
                <div className=" m-0 p-0 d-flex d-sm-none justify-content-between">
                  <div>
                    <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                      Step 1/3
                    </p>
                    <p className="pt-04 m-0 fs-18 family_outfit_semiBold_600 lh-2068 fc-292D32">
                      Personal Details
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <Image src={AbsoluteLogoMobile} alt={'icon'} />
                  </div>
                </div>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="m-0 p-0"
                >
                  <div className="d-flex align-items-center pt-16">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>Drop the files here ...</p>
                      ) : (
                        <>
                          {profileUpload !== '' ? (
                            <div className="uploadPhoto">
                              <img
                                src={profileUpload}
                                alt="Uploaded profile"
                                className="uploadPhoto"
                              />
                            </div>
                          ) : (
                            <div className="uploadPhoto">
                              <div>
                                <img src={UploadIcon} />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="pl-12">
                      <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-20 fc-3C3C3C">
                        Add Profile Picture
                      </p>
                      <p className="pt-8 m-0 fs-14 family_outfit_regular_400 fc-A4AAB2">
                        A picture will help people recognize you
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-16">
                    <LabelWithInput
                      label="Name"
                      placeholder="Provider Name"
                      name="providerName"
                      type="text"
                      value={formData?.providerName}
                      onChange={(e) => handleChange(e, 'providerName')}
                      disabled={'true'}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="size-padding"
                >
                  <div className="pt-8">
                    <TabSwitch
                      label={'Provider Type'}
                      tabs={providerData?.[0]?.values}
                      selectedTabValue={formData?.providerType}
                      tranferData={(e, value) =>
                        handleCheckboxChange(e, 'providerType', value)
                      }
                    />
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-16">
                    <LabelWithInput
                      label="Email ID"
                      placeholder="Email ID"
                      name="email"
                      type="text"
                      value={formData?.email}
                      onChange={(e) => handleChange(e, 'email')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Image
                              src={MailIcon}
                              alt="icon"
                              style={{ cursor: 'pointer' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      disabled={'true'}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="size-padding"
                >
                  <div className="pt-16">
                    <LabelWithInput
                      label="Mobile Number"
                      placeholder="Mobile Number"
                      name="mobileNumber"
                      type="text"
                      value={`${formData?.country_code} ${formData?.mobileNumber}`}
                      onChange={(e) => handleChange(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Image
                              src={PhoneIcon}
                              alt="icon"
                              style={{ cursor: 'pointer' }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      disabled={'true'}
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-12">
                    <TabSwitch
                      label={'Title'}
                      tabs={doctorTitle?.[0]?.values}
                      selectedTabValue={formData?.title}
                      tranferData={(e, value) =>
                        handleCheckboxChange(e, 'title', value)
                      }
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6} className="size-padding">
                  <div className="pt-16">
                    <p className="p-0 m-0 fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                      Highest Qualification
                    </p>
                    <SelectInput
                      placeholder={intl.formatMessage({
                        id: 'Highest Qualification',
                      })}
                      name={'highestQualification'}
                      value={formData?.highestQualification}
                      data={Qualification}
                      onChange={(e) =>
                        handleQualificationChange(e, 'highestQualification')
                      }
                      errorText={
                        isError &&
                        isError?.highestQualification?.error &&
                        isError?.highestQualification?.errorMessage
                      }
                    />
                  </div>
                </Col>
                <Col xs={12} md={12} className="m-0 p-0">
                  <div className="pt-16">
                    <LabelWithTextArea
                      label="Bio"
                      placeholder={intl.formatMessage({ id: 'Write here...' })}
                      height="120"
                      multiline
                      name="bio"
                      value={formData?.bio}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </Col>
                <div className="main-heading pt-24 family_outfit_semiBold_600">
                  Residential Address
                </div>
                <p className="pt-16 m-0 fs-14 family_outfit_medium_500 lh-176 fc-292D32">
                  Country
                </p>
                <Col md={12} lg={12} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-04">
                    <ReusableTabs
                      tabs={tabData}
                      defaultTab={tab}
                      currentTab={(val) => handleTabChange(val)}
                      background={'#864629'}
                      color={'#FFFFFF'}
                      disabled={'true'}
                    />
                  </div>
                </Col>
              </Row>

              <Row className="m-0 p-0">
                <Col md={6} lg={6} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-16">
                    <p className="fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                      State
                    </p>
                    <SelectInput
                      placeholder={intl.formatMessage({
                        id: 'Texas',
                      })}
                      name={'state'}
                      value={formData?.state}
                      data={stateUsers?.data}
                      onChange={(e) => handleInputChange(e, 'state')}
                      errorText={
                        isError &&
                        isError?.state?.error &&
                        isError?.state?.errorMessage
                      }
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6} className="size-padding">
                  <div className="pt-16">
                    <p className="fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                      City
                    </p>
                    <SelectInput
                      placeholder={intl.formatMessage({
                        id: 'Dallas',
                      })}
                      name={'city'}
                      value={formData?.city}
                      data={cityUsers?.data}
                      onChange={(e) => handleInputChange(e, 'city')}
                      disabled={!formData?.state?.length}
                      errorText={
                        isError &&
                        isError?.city?.error &&
                        isError?.city?.errorMessage
                      }
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6} className="m-0 p-0">
                  <div className="pt-16">
                    <LabelWithInput
                      label="Street Address"
                      placeholder="Street Address"
                      name="streetAddress"
                      type="text"
                      value={formData?.streetAddress}
                      onChange={(e) => handleChange(e)}
                      errorText={
                        isError &&
                        isError?.streetAddress?.error &&
                        isError?.streetAddress?.errorMessage
                      }
                    />
                  </div>
                </Col>
                <Col md={6} lg={6} xl={6} xxl={6} className="size-padding">
                  <div className="pt-16">
                    <LabelWithInput
                      label="ZIP Code"
                      placeholder="ZIP Code"
                      name="zipCode"
                      type="text"
                      value={formData?.zipCode}
                      onChange={(e) => handleChange(e)}
                      errorText={
                        isError &&
                        isError?.zipCode?.error &&
                        isError?.zipCode?.errorMessage
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="flex justify-end pt-32 mt-190 sm:mt-0">
                <Col
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  xxl={2}
                  className="pt-16 sm-pt-0"
                >
                  <Button variant="contained" className="button-register">
                    Cancel
                  </Button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  xxl={2}
                  className="pt-16 sm-pt-0"
                >
                  <Button
                    variant="contained"
                    className="button"
                    onClick={() => hanldeRegisterStepOne()}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default RegistrationStepOne;
