import {
  GET_SCHEDULE_LIST,
  GET_SCHEDULE_LIST_SUCCESS,
  GET_SCHEDULE_LIST_FAILURE,
} from 'store/getSchedule/getScheduleAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const getScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_LIST:
      return { ...state, loading: true };
    case GET_SCHEDULE_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_SCHEDULE_LIST_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default getScheduleReducer;
