import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import ArrowBack from 'assets/images/arrow-left.svg';
import AbsoluteLogoMobile from 'assets/images/absolute-logo-phone.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { registerFinalPost } from 'store/registerFinal/registerFinalAction';
import LocalStorageManager from 'managers/LocalStorageManger';
import { LocaleContext } from 'routes/Router';
import { login } from 'routes/AppUrls';
import { getReviewDetail } from 'store/registerReview/registerReviewAction';

const RegisterFinalStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [reviewProfessionalList, setReviewProfessionalList] = useState([]);
  const reviewData = useSelector(
    (state) => state?.getReviewDetailReducer?.users?.data
  );
  useEffect(() => {
    dispatch(
      getReviewDetail(LocalStorageManager.getItem('userId'), {
        run: true,
        handleFunction: (res) => handleSuccess(res),
      })
    );
  }, []);
  const handleSuccess = (res) => {
    setReviewProfessionalList(
      res?.data?.doctor_practice_info?.[0]?.doctor_sub_practice_info
    );
  };
  console.log(reviewData, '1211');
  const handleReview = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(login(locale));
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {};
    dispatch(
      registerFinalPost(
        body,
        external,
        LocalStorageManager.getItem('userId'),
        reviewData?.request_info?.request_id
      )
    );
  };
  return (
    <Row className="m-0 p-0 landing-image">
      <Row className="mb-4 pt-80 size-padding flex justify-center">
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          xxl={10}
          className="bg-light-100 m-0 p-0"
        >
          <div class="p-16 sm:p-32">
            <div className="d-flex">
              <div className="d-flex" onClick={() => navigate(-1)}>
                <Image
                  src={ArrowBack}
                  alt={'icon'}
                  className="cursor-pointer"
                />
              </div>
              <div className="main-heading pl-8 family_outfit_semiBold_600">
                Registration
              </div>
            </div>
            <Row className="m-0 p-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                className="m-0 p-0 d-none d-sm-flex"
              >
                <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                  Step 3/3
                </p>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                className="m-0 p-0 d-none d-sm-flex"
              >
                <p className="pt-04 m-0 fs-18 family_outfit_semiBold_600 lh-2268 fc-292D32">
                  Review Details
                </p>
              </Col>
              <div className=" m-0 p-0 d-flex d-sm-none justify-content-between">
                <div>
                  <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                    Step 3/3
                  </p>
                  <p className="pt-04 m-0 fs-18 family_outfit_semiBold_600 lh-2268 fc-292D32">
                    Review Details
                  </p>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <Image src={AbsoluteLogoMobile} alt={'icon'} />
                </div>
              </div>
            </Row>
            <Row className="g-3">
              <Col xs={12} lg={6}>
                <p className="pt-24 pb-8 m-0 fs-16 family_outfit_semiBold_600 lh-201 fc-292D32">
                  Personal Details
                </p>
                <div className="p-16 review-box">
                  <div className="flex justify-between">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Name
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.full_name}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Highest Qualification
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.highest_qualification?.qualification}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Email ID
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.email}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24 pb-40">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Mobile Number
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.country_code}&nbsp;
                      {reviewData?.contact_number}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <p className="pt-24 pb-8 m-0 fs-16 family_outfit_semiBold_600 lh-201 fc-292D32">
                  Residential Address
                </p>
                <div className="p-16 review-box">
                  <div className="flex justify-between">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Country
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.address?.country?.name}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      State
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.address?.state?.name}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      City
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.address?.city?.name}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      Street Address
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.address?.address_line1}
                    </p>
                  </div>
                  <div className="flex justify-between pt-24">
                    <p className="p-0 m-0 fs-16 family_outfit_regular_400 lh-16 fc-998A7B">
                      ZIP Code
                    </p>
                    <p className="p-0 m-0 fs-16 family_outfit_medium_500 lh-16 fc-3C3C3C ">
                      {reviewData?.address?.zip_code}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <p className="pt-16 m-0 fs-16 family_outfit_semiBold_600 lh-201 fc-292D32">
              Professional Details
            </p>
            <div>
              <div className="pt-8 pb-8 fs-18 family_outfit_semiBold_600">
                <span className="fc-292D32">Practice name - </span>
                <span className="fc-864629">
                  {reviewData?.doctor_practice_info?.[0]?.practice_name}
                </span>
              </div>
              <Row className="m-0 p-0 fs-14 lh-16 family_outfit_semiBold_600 profession-detail">
                <Col sm={4} className="profession-heading">
                  Sub-practice name
                </Col>
                <Col sm={1} className="profession-heading">
                  State Code
                </Col>
                <Col sm={4} className="profession-heading">
                  State License
                </Col>
                <Col sm={3} className="profession-heading">
                  Fee per session
                </Col>
              </Row>
              {reviewData?.doctor_practice_info &&
                reviewData?.doctor_practice_info?.length > 0 && (
                  <div>
                    {reviewProfessionalList &&
                      reviewProfessionalList?.length > 0 &&
                      reviewProfessionalList.map((item, index) => {
                        return (
                          <>
                            <Row className="m-0 p-0 fs-14 lh-176 family_outfit_regular_400 profession-detail-list">
                              <Col
                                sm={4}
                                className="profession-heading-detail "
                              >
                                {item?.sub_practice}
                              </Col>
                              <Col
                                sm={1}
                                className="profession-heading-detail justify-center"
                              >
                                {item?.state_code?.name}
                              </Col>
                              <Col
                                sm={4}
                                className="profession-heading-detail fc-5B88E9 underline decoration-1 ..."
                              >
                                {item?.state_license
                                  ? item.state_license.split('/').pop()
                                  : ''}
                              </Col>
                              <Col sm={3} className="flex items-center">
                                <span className="fs-18 family_outfit_semiBold_600">
                                  $ &nbsp;
                                </span>
                                <span className="fs-16 family_outfit_regular_400">
                                  {item?.fee_per_session}
                                </span>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </div>
                )}
            </div>
            <Row className="flex justify-end pt-24 sm:pt-24">
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                xxl={2}
                className="pt-16 sm-pt-0"
              >
                <Button variant="contained" className="button-register">
                  Cancel
                </Button>
              </Col>
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                xxl={2}
                className="pt-16 sm-pt-0"
              >
                <Button
                  variant="contained"
                  className="button"
                  onClick={() => handleReview()}
                >
                  Confirm & Continue
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default RegisterFinalStep;
