import axios from 'axios';
import { postMethodWithUpload } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const REGISTER_UPLOAD = 'REGISTER_UPLOAD';
export const REGISTER_UPLOAD_SUCCESS = 'REGISTER_UPLOAD_SUCCESS';
export const REGISTER_UPLOAD_FAILURE = 'REGISTER_UPLOAD_FAILURE';

// Action Creators
export const getRegisterUploadRequest = () => ({
  type: REGISTER_UPLOAD,
});

export const getRegisterUploadSuccess = (users) => ({
  type: REGISTER_UPLOAD_SUCCESS,
  payload: users,
});

export const getRegisterUploadFailure = (error) => ({
  type: REGISTER_UPLOAD_FAILURE,
  payload: error,
});

// Thunk Function
export const registerUploadAction = (body, external) => {
  return async (dispatch) => {
    dispatch(getRegisterUploadRequest());
    try {
      const response = await postMethodWithUpload(url.fileRegisterUpload, body);
      dispatch(getRegisterUploadSuccess(response));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getRegisterUploadFailure(error?.message));
      if (external?.error === true) {
        external?.handleErrorFunction(error);
      }
    }
  };
};
