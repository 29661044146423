import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import ArrowBack from 'assets/images/arrow-left.svg';
import AbsoluteLogoMobile from 'assets/images/absolute-logo-phone.svg';
import AddIcon from 'assets/images/add.svg';
import CommonModal from 'components/commom/Modal';
import SelectInput from 'components/commom/SelectInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useIntl } from 'react-intl';
import { LocaleContext } from 'routes/Router';
import { useDispatch, useSelector } from 'react-redux';
import { getPracticeList } from 'store/registerPractice/registePracticeAction';
import SelectInputList from 'components/commom/SelectInputList';
import deteteIcon from 'assets/images/delete.svg';
import ToastNotification from 'components/commom/Toast';
import { useDropzone } from 'react-dropzone';
import { registerUploadAction } from 'store/registerUpload/registerUploadAction';
import UploadIconList from 'assets/images/upload-list.svg';
import LabelWithInput from 'components/commom/LabelWithInput';
import LabelWithoutInputList from 'components/commom/LabelWithoutInputList';
import { registerProfessionalPost } from 'store/registerProfessional/registerProfessionalAction';
import LocalStorageManager from 'managers/LocalStorageManger';
import { getReviewDetail } from 'store/registerReview/registerReviewAction';
import { useNavigate } from 'react-router-dom';
import { registerFinal } from 'routes/AppUrls';
import CheckValidation from 'utils/validate';

const RegisterProfessionalDetail = () => {
  const intl = useIntl();
  const brandImageFileInputRef = useRef([]);
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const [isProfessionalModal, setIsProfessionalModal] = useState(false);
  const providerData = useSelector(
    (state) => state?.getPracticeReducer?.users?.data
  );
  const registerUserReducer = useSelector(
    (state) => state?.registerUserReducer?.users?.data
  );
  const [isError, setIsError] = useState(false);
  const [subListId, setsubListId] = useState([]);
  const [subListPrev, setSubListPrev] = useState([]);
  const [subList, setsubList] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [uploadListId, setUploadListId] = useState([]);
  const [formData, setFormData] = useState(providerData?.[0]);
  const [formValue, setFormValue] = useState({
    stateCode: {},
    stateCodeId: [],
    amount: {},
  });
  useEffect(() => {
    const external = {
      run: true,
      handleFunction: (res) => {},
      error: true,
      handleErrorFunction: (res) => {},
    };
    dispatch(getPracticeList(external));
  }, []);
  useEffect(() => {
    dispatch(
      getReviewDetail(LocalStorageManager.getItem('userId'), {
        run: true,
        handleFunction: (res) => handleSuccess(res),
      })
    );
  }, []);

  const handleSuccess = (res) => {
    // setFormData(res?.data?.doctor_practice_info?.[0]);

    const doctorSubPracticeInfo =
      res?.data?.doctor_practice_info?.[0]?.doctor_sub_practice_info;

    if (doctorSubPracticeInfo) {
      setsubList((prevSubList) => {
        const combinedList = [...prevSubList, ...doctorSubPracticeInfo];
        const uniqueList = combinedList.filter(
          (item, index, self) =>
            self.findIndex((i) => i.id === item.id) === index
        );
        return uniqueList;
      });

      setFormValue((prev) => {
        const updatedStateCode = { ...prev.stateCode };
        const updatedStateCodeId = [...prev.stateCodeId];
        const updatedAmount = { ...prev.amount };
        const updatedUploadList = [...uploadList];
        const updatedUploadListId = [...uploadListId];

        doctorSubPracticeInfo.forEach((item, index) => {
          updatedAmount[index] = item.fee_per_session;

          if (item.state_code) {
            updatedStateCode[index] = item.state_code?.name;
            if (!updatedStateCodeId.includes(item.state_code.id)) {
              updatedStateCodeId.push(item.state_code.id);
            }
          }
          if (item.state_license) {
            const filePath = item.state_license;
            const fileName = filePath ? filePath.split('/').pop() : null;
            updatedUploadList[index] = fileName;
            if (!updatedUploadListId.includes(item.id)) {
              updatedUploadListId.push(item.id);
            }
          }
        });

        setUploadList(updatedUploadList);
        setUploadListId(updatedUploadListId);

        return {
          ...prev,
          stateCode: updatedStateCode,
          stateCodeId: updatedStateCodeId,
          amount: updatedAmount,
        };
      });
    }
  };

  const handleOpenModal = () => {
    setIsProfessionalModal(true);
  };
  const handleCloseModal = () => {
    setIsProfessionalModal(false);
  };
  const handleChange = (event) => {
    setFormData(event?.target?.value);
    setsubListId([]);
    setsubList([]);
  };

  const handleStateChange = (event, index) => {
    const { value } = event?.target;
    setFormValue((prev) => ({
      ...prev,
      stateCode: {
        ...prev.stateCode,
        [index]: value?.code,
      },
      stateCodeId: {
        ...prev.stateCodeId,
        [index]: value?.id,
      },
    }));
  };
  const handlePhoneNumberChange = (event, index) => {
    const { name, value } = event?.target;
    setFormValue((prev) => ({
      ...prev,
      amount: {
        ...prev?.amount,
        [index]: value,
      },
    }));
  };
  const handleSelectPractice = (value) => {
    let arr = [...subListId];
    let arrList = [...subListPrev];
    if (arr.includes(value.id)) {
      let index = arr.findIndex((item) => item == value.id);
      let indexList = arrList.findIndex((item) => item == value);
      arr.splice(index, 1);
      arrList.splice(indexList, 1);
      setsubListId(arr);
      setSubListPrev(arrList);
    } else {
      arr.push(value.id);
      arrList.push(value);
      setsubListId(arr);
      setSubListPrev(arrList);
    }
  };

  const handleSelectList = () => {
    setsubList(subListPrev);
    setIsProfessionalModal(false);
  };

  const handleRemoveList = (index, event) => {
    event.stopPropagation();
    const newUploadImageData = [...subList];
    newUploadImageData.splice(index, 1);
    subListId.splice(index, 1);
    setsubList(newUploadImageData);
    setSubListPrev(newUploadImageData);
  };

  const handleBrandImageUpload = (index) => {
    brandImageFileInputRef.current[index]?.click();
  };

  const handleBrandImageFileChange = (e, index) => {
    const file = e.target.files?.[0]?.name;
    let Data = new FormData();
    Data.append('filePath', file);

    const external = {
      run: true,
      handleFunction: (res) => {
        const filePath = res?.data?.[0]?.filePath;
        const fileName = filePath ? filePath.split('/').pop() : null;

        setUploadList((prev) => {
          const newList = [...prev];
          newList[index] = fileName;
          return newList;
        });

        setUploadListId((prev) => {
          const newList = [...prev];
          newList[index] = res?.data?.[0]?.id;
          return newList;
        });

        setConf({ msg: res?.message, variant: 'success' });
      },
      error: true,
      handleErrorFunction: (res) =>
        setConf({ msg: res?.message, variant: 'error' }),
    };

    dispatch(registerUploadAction(Data, external));
  };

  // const handleProfessional = () => {
  //   const external = {
  //     run: true,
  //     handleFunction: (res) => {
  //       setConf({ msg: res?.message, variant: 'success' });
  //       navigate(registerFinal(locale));
  //     },
  //     error: true,
  //     handleErrorFunction: (res) => {
  //       setConf({ msg: res?.message, variant: 'error' });
  //       // navigate(registerFinal(locale));
  //     },
  //   };
  //   // const error = CheckValidation(formValue, uploadListId);
  //   // setIsError(error);
  //   let body = [];
  //   subList?.length > 0 &&
  //     subList.map((item, index) => {
  //       let value = {
  //         sub_practice_id: subListId[index],
  //         state_code_id: formValue?.stateCodeId[index],
  //         state_license_id: uploadListId[index],
  //         doctor_practice_id: formData?.id,
  //         fee_per_session: +formValue?.amount[index],
  //       };
  //       body.push(value);
  //     });

  //   dispatch(
  //     registerProfessionalPost(
  //       body,
  //       LocalStorageManager.getItem('userId'),
  //       external
  //     )
  //   );
  // };
  const handleProfessional = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(registerFinal(locale));
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
        // navigate(registerFinal(locale));
      },
    };

    // Validation to check if stateCodeId, amount, or uploadListId are missing
    const isValid = subList.every((item, index) => {
      const stateCode = formValue.stateCode[index];
      const stateCodeId = formValue.stateCodeId[index];
      const amount = formValue.amount[index];
      const uploadId = uploadListId[index];

      // Return false if any of the required values are missing or invalid
      return stateCode && stateCodeId && amount && uploadId;
    });

    if (!isValid) {
      setConf({
        msg: 'Please ensure all fields are filled for each item.',
        variant: 'error',
      });
      return; // Prevent submission if validation fails
    }

    // Create the body to send
    let body = [];
    subList?.length > 0 &&
      subList.map((item, index) => {
        let value = {
          sub_practice_id: subListId[index],
          state_code_id: formValue?.stateCodeId[index],
          state_license_id: uploadListId[index],
          doctor_practice_id: formData?.id,
          fee_per_session: +formValue?.amount[index],
        };
        body.push(value);
      });

    // Dispatch the API call
    dispatch(
      registerProfessionalPost(
        body,
        LocalStorageManager.getItem('userId'),
        external
      )
    );
  };

  return (
    <>
      <Row className="m-0 p-0 landing-image">
        <Row className="mb-4 pt-80 size-padding flex justify-center">
          <Col
            xs={12}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            className={
              subList && subList?.length > 0
                ? 'bg-gray-100 sm:bg-light-100 m-0 p-0'
                : 'professional-card bg-gray-100 sm:bg-light-100 m-0 p-0'
            }
          >
            <div class="p-16 sm:p-32">
              <div className="d-flex">
                <div className="d-flex" onClick={() => navigate(-1)}>
                  <Image
                    src={ArrowBack}
                    alt={'icon'}
                    className="cursor-pointer"
                  />
                </div>
                <div className="main-heading pl-8 family_outfit_semiBold_600">
                  Registration
                </div>
              </div>
              <Row className="m-0 p-0">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="m-0 p-0 d-none d-sm-flex"
                >
                  <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                    Step 2/3
                  </p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="m-0 p-0 d-none d-sm-flex"
                >
                  <p className="pt-04 pb-16 m-0 fs-18 family_outfit_semiBold_600 lh-2068 fc-292D32">
                    Personal Details
                  </p>
                </Col>
                <div className=" m-0 p-0 d-flex d-sm-none justify-content-between">
                  <div>
                    <p className="pt-24 m-0 fs-14 family_outfit_regular_400 lh-176 fc-C56616">
                      Step 2/3
                    </p>
                    <p className="pt-04 m-0 fs-18 family_outfit_semiBold_600 lh-2068 fc-292D32">
                      Personal Details
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-4">
                    <Image src={AbsoluteLogoMobile} alt={'icon'} />
                  </div>
                </div>
              </Row>
              {subList && subList?.length > 0 ? (
                <div>
                  <div className="pt-16 pb-8 fs-18 family_outfit_semiBold_600 fc-864629">
                    Practice name - {formData?.practice_name}
                  </div>
                  <Row className="m-0 p-0 fs-14 lh-16 family_outfit_semiBold_600 profession-detail">
                    <Col sm={4} className="profession-heading">
                      Sub-practice name
                    </Col>
                    <Col sm={2} className="profession-heading">
                      State Code
                    </Col>
                    <Col sm={3} className="profession-heading">
                      State License
                    </Col>
                    <Col sm={2} className="profession-heading">
                      Fee per session
                    </Col>
                  </Row>
                  {subList && subList?.length > 0 && (
                    <div>
                      {subList.map((item, index) => {
                        return (
                          <>
                            <Row className="m-0 p-0 fs-14 lh-176 family_outfit_regular_400 profession-detail-list">
                              <Col sm={4} className="profession-heading-detail">
                                {item?.name || item?.sub_practice}
                              </Col>
                              <Col sm={2} className="profession-heading-detail">
                                <SelectInputList
                                  placeholder={intl.formatMessage({
                                    id: 'State Code',
                                  })}
                                  name={'stateCode'}
                                  value={formValue?.stateCode[index] || ''}
                                  data={registerUserReducer}
                                  onChange={(e) => handleStateChange(e, index)}
                                  errorText={
                                    isError &&
                                    isError?.stateCode?.error &&
                                    isError?.stateCode?.errorMessage
                                  }
                                />
                              </Col>
                              <Col sm={3} className="profession-heading-detail">
                                <div className="d-flex align-items-center">
                                  <input
                                    ref={(el) =>
                                      (brandImageFileInputRef.current[index] =
                                        el)
                                    }
                                    type="file"
                                    accept=".png, .jpg, .jpeg, .gif, .mp4, .pdf"
                                    disabled={false}
                                    style={{ display: 'none' }}
                                    onChange={(e) =>
                                      handleBrandImageFileChange(e, index)
                                    }
                                  />
                                  <div>
                                    {uploadList[index] ? (
                                      <div className="pl-8 pt-0.5 fs-14 lh-176 fc-5B88E9 family_outfit_medium_500 underline decoration-1 ...">
                                        {uploadList[index]}
                                      </div>
                                    ) : (
                                      <div
                                        className="flex"
                                        onClick={() =>
                                          handleBrandImageUpload(index)
                                        }
                                      >
                                        <div>
                                          <img src={UploadIconList} />
                                        </div>
                                        <div className="pl-8 pt-0.5 fs-14 lh-176 fc-C56616 family_outfit_medium_500 underline decoration-1 ...">
                                          Upload file
                                        </div>
                                      </div>
                                    )}
                                    <div className="error-message">
                                      {isError &&
                                        isError?.stateCode?.error &&
                                        isError?.stateCode?.errorMessage}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col sm={2} className="profession-heading-detail">
                                <LabelWithoutInputList
                                  placeholder="Enter amount"
                                  name="amount"
                                  type="number"
                                  value={formValue?.amount[index] || ''}
                                  onChange={(e) =>
                                    handlePhoneNumberChange(e, index)
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                  errorText={
                                    isError &&
                                    isError?.amount?.error &&
                                    isError?.amount?.errorMessage
                                  }
                                />
                              </Col>
                              <Col
                                sm={1}
                                className="flex items-center justify-center cursor-pointer ..."
                              >
                                <div
                                  className="flex items-center	justify-center"
                                  onClick={(event) =>
                                    handleRemoveList(index, event)
                                  }
                                >
                                  <Image src={deteteIcon} alt={'icon'} />
                                </div>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                      <div className="flex pt-16">
                        <div
                          className="add-detail"
                          onClick={() => handleOpenModal()}
                        >
                          <Image src={AddIcon} alt={'icon'} />
                          <div className="fs-16 family_outfit_medium_500 lh-16 c-C56616 pl-04">
                            Add a Practice
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Row className="m-0 p-0 fs-14 lh-16 family_outfit_semiBold_600 profession-detail d-none d-sm-flex">
                    <Col sm={3} className="profession-heading">
                      Practice name
                    </Col>
                    <Col sm={3} className="profession-heading">
                      Sub-practice name
                    </Col>
                    <Col sm={2} className="profession-heading">
                      State Code
                    </Col>
                    <Col sm={2} className="profession-heading">
                      State License
                    </Col>
                    <Col sm={2}>Fee per session</Col>
                  </Row>
                  <Row className="m-0 p-0">
                    <Col sm={12}>
                      <div className="fs-16 family_outfit_medium_500 lh-201 fc-7F7F7F pt-48 sm:pt-24 flex justify-center">
                        Select a practice to get started
                      </div>

                      <div className="flex justify-center	pt-24">
                        <div
                          className="add-detail"
                          onClick={() => handleOpenModal()}
                        >
                          <Image src={AddIcon} alt={'icon'} />
                          <div className="fs-16 family_outfit_medium_500 lh-16 c-C56616 pl-04">
                            Add a Practice
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {subList?.length > 0 ? (
                <>
                  <Row className="flex justify-between pt-48 sm:pt-16">
                    <Col xs={12} sm={8} className="flex items-center">
                      <div className="fs-14 family_outfit_regular_400 lh-196 fc-A4AAB2">
                        Supported formats: JPEG, PNG, GIF, MP4, PDF
                      </div>
                    </Col>
                    <Col xs={12} sm={4} className="">
                      <Row>
                        <Col sm={6}>
                          <Button
                            variant="contained"
                            className="button-register"
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col sm={6}>
                          <Button
                            variant="contained"
                            className="button"
                            onClick={() => handleProfessional()}
                          >
                            Done
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="flex justify-end pt-48 sm:pt-32">
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    className="pt-16 sm-pt-0"
                  >
                    <Button variant="contained" className="button-register">
                      Cancel
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    className="pt-16 sm-pt-0"
                  >
                    <Button
                      variant="contained"
                      className={
                        subListPrev && subListPrev?.length > 0
                          ? 'button'
                          : 'button-disabled'
                      }
                      disabled={
                        subListPrev && subListPrev?.length === 0 && 'true'
                      }
                      onClick={() =>
                        subListPrev &&
                        subListPrev?.length > 0 &&
                        handleProfessional()
                      }
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Row>

      <CommonModal
        padding={'32px'}
        width={'736px'}
        modalActive={isProfessionalModal}
        innerContent={
          <div>
            <div className="fs-20 family_outfit_semiBold_600 lh-252 fc-292D32">
              Select a Practice
            </div>
            <Row>
              <Col xs={12}>
                <div className="pt-24">
                  <p className="p-0 m-0 fs-14 lh-176 fc-292D32 family_outfit_medium_500">
                    Practice Name
                  </p>
                  <SelectInput
                    placeholder={intl.formatMessage({
                      id: 'Practice Name',
                    })}
                    name={'practiceName'}
                    value={formData?.practice_name}
                    data={providerData}
                    onChange={(e) => handleChange(e, 'practiceName')}
                  />
                </div>
              </Col>
            </Row>
            <div className="fs-16 family_outfit_medium_500 lh-201 fc-292D32 pt-24">
              Select a Practice
            </div>
            <div className="flex flex-wrap">
              {formData?.sub_practice_info &&
                formData?.sub_practice_info?.length > 0 &&
                formData?.sub_practice_info.map((item, index) => {
                  return (
                    <div
                      className="practice-box ml-2"
                      onClick={() => handleSelectPractice(item)}
                      style={{
                        border: subListId.includes(item?.id)
                          ? '1px solid #864629'
                          : '1px solid #EFE3D7',
                        background: subListId.includes(item?.id) && '#FEF9F6',
                      }}
                    >
                      <div
                        className="fs-14 family_outfit_regular_400 lh-176"
                        style={{
                          color: subListId.includes(item?.id)
                            ? '#864629 '
                            : '#3C3C3C',
                        }}
                      >
                        {item?.name}
                      </div>
                    </div>
                  );
                })}
            </div>
            <Row className="flex justify-end pt-24 mt-190 sm:mt-0">
              <Col md={3}>
                <Button
                  variant="contained"
                  className="button-register"
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3} className="pt-16 md:pt-0">
                <Button
                  variant="contained"
                  className={
                    subListPrev && subListPrev?.length > 0
                      ? 'button'
                      : 'button-disabled'
                  }
                  onClick={() => handleSelectList()}
                  disabled={subListPrev && subListPrev?.length === 0 && 'true'}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        }
      />
    </>
  );
};

export default RegisterProfessionalDetail;
