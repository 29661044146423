import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
export const GET_PATIENT_LIST_SUCCESS = 'GET_PATIENT_LIST_SUCCESS';
export const GET_PATIENT_LIST_FAILURE = 'GET_PATIENT_LIST_FAILURE';

// Action Creators
export const getPatientRequest = () => ({
  type: GET_PATIENT_LIST,
});

export const getPatientSuccess = (users) => ({
  type: GET_PATIENT_LIST_SUCCESS,
  payload: users,
});

export const getPatientFailure = (error) => ({
  type: GET_PATIENT_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getPatient = (external) => {
  return async (dispatch) => {
    dispatch(getPatientRequest());
    try {
      const response = await getMethodWithToken(url?.getPatientList);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getPatientSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getPatientFailure(error?.message));
    }
  };
};
