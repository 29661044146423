import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_SCHEDULE_DETAIL = 'GET_SCHEDULE_DETAIL';
export const GET_SCHEDULE_DETAIL_SUCCESS = 'GET_SCHEDULE_DETAIL_SUCCESS';
export const GET_SCHEDULE_DETAIL_FAILURE = 'GET_SCHEDULE_DETAIL_FAILURE';

// Action Creators
export const getScheduleDetailRequest = () => ({
  type: GET_SCHEDULE_DETAIL,
});

export const getScheduleDetailSuccess = (users) => ({
  type: GET_SCHEDULE_DETAIL_SUCCESS,
  payload: users,
});

export const getScheduleDetailFailure = (error) => ({
  type: GET_SCHEDULE_DETAIL_FAILURE,
  payload: error,
});

// Thunk Function
export const getScheduleDetail = (external, doctorId) => {
  return async (dispatch) => {
    dispatch(getScheduleDetailRequest());
    try {
      const response = await getMethodWithToken(
        url?.getScheduleDetail + '?doctor_id=' + doctorId + '&week=current'
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getScheduleDetailSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getScheduleDetailFailure(error?.message));
    }
  };
};
