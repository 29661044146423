import React, { useContext } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormControlWrapper = styled(FormControl)`
  ${(props) =>
    props.isBackground &&
    `
 	    background: ${props.theme[props.bgColor]};
		border-radius: 6px;
		padding: 8px 16px !important;
		margin-top: 20px !important;
    `}
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

const WrapperLabel = styled.p`
  font-size: ${(props) => props.size + 'px'};
  color: #292d32;
  margin: 0px;
  margin-left: 0px;
  font-weight: ${(props) => props.fontWeight};
  line-height: 22px;
`;

const WrapperSpan = styled.span`
  color: ${(props) => props.theme.dark};
  font-weight: 800;
  display: block;
  font-size: 13px;
`;

function CheckBoxInput({ containerProps, ...props }) {
  return (
    <FormControlWrapper {...props}>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            sx={{
              color: '#998A7B',
              borderRadius: '6px',
              transform: 'scale(1.3)',
              '&.Mui-checked': {
                color: '#C56616',
              },
            }}
            disableRipple
            checked={props.checked}
          />
        }
        label={
          <WrapperLabel {...props}>
            {props.label}

            {props.subLabel && <WrapperSpan>{props.subLabel}</WrapperSpan>}
          </WrapperLabel>
        }
      />
    </FormControlWrapper>
  );
}

CheckBoxInput.defaultProps = {
  isBackground: false,
  bgColor: 'white',
  checkColor: '#C56616',
};

export default CheckBoxInput;
