import {
  GET_TITLE_TYPE,
  GET_TITLE_TYPE_SUCCESS,
  GET_TITLE_TYPE_FAILURE,
} from 'store/registerTitle/registerTitleAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const registerTitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TITLE_TYPE:
      return { ...state, loading: true };
    case GET_TITLE_TYPE_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_TITLE_TYPE_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default registerTitleReducer;
