import {
  GET_SCHEDULE_DETAIL,
  GET_SCHEDULE_DETAIL_SUCCESS,
  GET_SCHEDULE_DETAIL_FAILURE,
} from 'store/getScheduleDetail/getScheduleDetailAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const getScheduleDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHEDULE_DETAIL:
      return { ...state, loading: true };
    case GET_SCHEDULE_DETAIL_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_SCHEDULE_DETAIL_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default getScheduleDetailReducer;
