import axios from 'axios';
import { postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const LOGIN_USERS_REQUEST = 'LOGIN_USERS_REQUEST';
export const LOGIN_USERS_SUCCESS = 'LOGIN_USERS_SUCCESS';
export const LOGIN_USERS_FAILURE = 'LOGIN_USERS_FAILURE';

// Action Creators
export const loginUsersRequest = () => ({
  type: LOGIN_USERS_REQUEST,
});

export const loginUsersSuccess = (users) => ({
  type: LOGIN_USERS_SUCCESS,
  payload: users,
});

export const loginUsersFailure = (error) => ({
  type: LOGIN_USERS_FAILURE,
  payload: error,
});

// Thunk Function

export const loginUsers = (body, external) => {
  return async (dispatch) => {
    dispatch(loginUsersRequest());
    try {
      const response = await postMethod(url.login, body);
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(loginUsersSuccess(response));
        } else {
          if (external?.error === true) {
            external?.handleFailerFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(loginUsersFailure(error));
      if (external?.error === true) {
        external?.handleFailerFunction(error);
      }
    }
  };
};
