import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-size: ${(props) => props.fontSize || '1rem'};
    font-weight: ${(props) => props.fontWeight || '500'};
    border-radius: ${(props) => props.borderRadius || '0px'};
    text-transform: ${(props) => props.textTransform || 'none'};
    padding: ${(props) => props.padding || '8px 16px'};
    height: ${(props) => props.height || '52px'};
    background-color: ${(props) => props.bgColor || 'rgba(0, 0, 0, 0.08)'};
    border-color: ${(props) => props.brColor || ''};
    color: ${(props) => props.color || 'rgba(0, 0, 0, 0.08)'};
    font-family: ${(props) => props.fontFamily};

    /* Add hover effects if needed */
    &:hover {
      background-color: ${(props) => props.hoverBgColor};
    }
  }
`;

const ReusableButton = ({
  children,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  onClick,
  disabled = false,
  fullWidth = false,
  fontSize,
  fontWeight,
  borderRadius,
  textTransform,
  padding,
  height,
  hoverBgColor,
  bgColor,
  brColor,
  startIcon,
  endIcon,
  ...props
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      fontSize={fontSize}
      fontWeight={fontWeight}
      borderRadius={borderRadius}
      textTransform={textTransform}
      padding={padding}
      height={height}
      hoverBgColor={hoverBgColor}
      bgColor={bgColor}
      brColor={brColor}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

ReusableButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  borderRadius: PropTypes.string,
  textTransform: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  hoverBgColor: PropTypes.string,
  bgColor: PropTypes.string,
  brColor: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default ReusableButton;
