import React, { useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Stack, Image } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/commom/MuiBreadcrumbs';
import ArrowLeftIcon from 'assets/images/arrow-left.svg';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import ClockIcon from 'assets/images/Dashboard/clock.svg';
import CalerderIcon from 'assets/images/Dashboard/calendar.svg';
import { doctorAppointments } from 'routes/AppUrls';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import ReusableButton from 'components/commom/Button';
import { useDispatch } from 'react-redux';
import { getAppointmentDetail } from 'store/getAppointmentDetail/getAppointmentDetailAcition';
import ToastNotification from 'components/commom/Toast';
import { ClearTokenRedirectLogin } from 'utils/general';
import moment from 'moment';
import { getVedioToken } from 'store/VedioCallToken/VedioCallTokenAction';
import AgoraRTC from 'agora-rtc-sdk-ng';
import MicroPhoneOn from 'assets/images/Dashboard/MicrophoneOn.svg';
import MicroPhoneOff from 'assets/images/Dashboard/MicrophoneOff.svg';
import VedioOn from 'assets/images/Dashboard/VideoCameraOn.svg';
import VedioOff from 'assets/images/Dashboard/VideoCameraOff.svg';
import DemoVedio from 'assets/images/Dashboard/demi-vedio-2.svg';

const UpcomingDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [appointmentUpcomingDetail, setAppointmentUpcomingDetail] =
    useState('');
  const [verifyData, setVerifyData] = useState('');
  const [client, setClient] = useState(null);
  const [localTracks, setLocalTracks] = useState({ audio: null, video: null });
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [showVedio, setShowVedio] = useState(false);

  const appId = '4093a693a9244dda827a362965e732e1';
  const token = verifyData?.token;
  const uid = verifyData?.uuid;
  const channel = verifyData?.channel_name;

  useEffect(() => {
    getUpcomingDetail();
    getVedioVerifyToken();

    // Initialize Agora Client
    const agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    setClient(agoraClient);

    // Handle remote users
    agoraClient.on('user-published', async (user, mediaType) => {
      await agoraClient.subscribe(user, mediaType);
      if (mediaType === 'video') {
        user.videoTrack.play(`remote-${user.uid}`);
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
      }
      setRemoteUsers((prev) => [...prev, user]);
    });

    agoraClient.on('user-unpublished', (user) => {
      setRemoteUsers((prev) => prev.filter((u) => u.uid !== user.uid));
    });

    return () => {
      agoraClient.leave();
      if (localTracks.audio) localTracks.audio.close();
      if (localTracks.video) localTracks.video.close();
    };
  }, []);

  const getUpcomingDetail = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentUpcomingDetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getAppointmentDetail(external, params?.id));
  };

  const getVedioVerifyToken = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setVerifyData(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {
      appointment_id: params?.id,
    };
    dispatch(getVedioToken(external, body));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Appointments" />,
      Link: doctorAppointments(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Appointment Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const startVideoCall = async () => {
    try {
      setShowVedio(true);
      await client.join(appId, channel, token, uid);
      const [audioTrack, videoTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks();
      setLocalTracks({ audio: audioTrack, video: videoTrack });
      videoTrack.play('local-stream');
      await client.publish([audioTrack, videoTrack]);
      console.log('Video call started');
    } catch (err) {
      console.error('Error starting video call:', err);
    }
  };

  const endVideoCall = async () => {
    try {
      setShowVedio(false);
      await client.leave();
      if (localTracks.audio) localTracks.audio.close();
      if (localTracks.video) localTracks.video.close();
      setLocalTracks({ audio: null, video: null });
      setRemoteUsers([]);
      console.log('Call ended');
    } catch (err) {
      console.error('Error ending video call:', err);
    }
  };

  const toggleAudio = () => {
    if (localTracks.audio) {
      if (isAudioEnabled) {
        localTracks.audio.setEnabled(false);
      } else {
        localTracks.audio.setEnabled(true);
      }
      setIsAudioEnabled(!isAudioEnabled);
    }
  };

  const toggleVideo = () => {
    if (localTracks.video) {
      if (isVideoEnabled) {
        localTracks.video.setEnabled(false);
      } else {
        localTracks.video.setEnabled(true);
      }
      setIsVideoEnabled(!isVideoEnabled);
    }
  };

  console.log(remoteUsers, '1212');

  return (
    <Row className="bc-FEF9F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        {!showVedio ? (
          <>
            <MuiBreadcrumbs size="14" list={breadcrumbsList} />

            <Row className="bc-FFFFFF m-0 p-24 mt-3 border-1 bColor-EFE3D7">
              <Col xs={12} md={12} className="p-0">
                <Stack direction="horizontal" gap={2} className="items-center">
                  <img
                    src={ArrowLeftIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="cursor-pointer"
                    onClick={() => navigate(-1)}
                  />
                  <div className="fs-16 lh-16 fc-292D32 family_outfit_medium_500">
                    <FormattedMessage id="Appointment no." />
                    {appointmentUpcomingDetail?.booking_id}
                  </div>
                </Stack>
              </Col>

              <Col
                xs={12}
                md={12}
                className="mt-4 border-b pb-4 p-0 bColor-D6DDE6"
              >
                <Row>
                  <Col xs={12} md={5} className="">
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="flex items-start"
                    >
                      <Image
                        src={
                          appointmentUpcomingDetail?.patient?.profile_pic !== ''
                            ? appointmentUpcomingDetail?.patient?.profile_pic
                            : DefaultImage
                        }
                        alt={'icon'}
                        style={{
                          height: '88px',
                          width: '88px',
                          borderRadius: '50%',
                        }}
                      />
                      <div>
                        <div className="fs-18 lh-2268 fc-202224 family_outfit_medium_500">
                          {appointmentUpcomingDetail?.patient?.full_name}
                        </div>
                        <div className="pt-8 fs-14 fc-998A7B family_outfit_regular_400">
                          ID:
                          <span className="fc-3C3C3C family_outfit_medium_500">
                            {appointmentUpcomingDetail?.patient?.patient_id}
                          </span>
                        </div>
                      </div>
                    </Stack>
                  </Col>
                  <Col md={7}>
                    <Row>
                      <Col sm={12} className="p-0">
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <Image src={CallIcon} alt="icon" />
                          <div className="fs-16 lh-201 family_outfit_regular_400 fc-202224">
                            {`${appointmentUpcomingDetail?.patient?.country_code} ${appointmentUpcomingDetail?.patient?.contact_number}`}
                          </div>
                        </Stack>
                      </Col>
                      {/* <Col sm={6} className="p-0">
                    <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                      <FormattedMessage id="Address" />:
                      <span className="fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                        {'089 Kutch Green Apt. 448'}
                      </span>
                    </div>
                  </Col> */}
                      <Col sm={12} className="p-0">
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center pt-24"
                        >
                          <Image src={MailIcon} alt="icon" />
                          <div className="fs-16 lh-201 family_outfit_regular_400 fc-202224">
                            {appointmentUpcomingDetail?.patient?.email}
                          </div>
                        </Stack>
                      </Col>
                      {/* <Col sm={6} className="p-0">
                    <div className="fs-14 pt-24 lh-176 fc-998A7B family_outfit_regular_400">
                      <FormattedMessage id="Active since" />:
                      <span className="fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                        {'089 Kutch Green Apt. 448'}
                      </span>
                    </div>
                  </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={12} className="p-0">
                <div className="pt-15">
                  <Row className="p-0">
                    <Col
                      xs={12}
                      md={12}
                      lg={9}
                      xl={9}
                      className="flex items-center"
                    >
                      <Stack
                        direction="horizontal"
                        gap={5}
                        className="items-center"
                      >
                        <Stack
                          direction="horizontal"
                          gap={1}
                          className="flex items-center"
                        >
                          <div className="fs-16 lh-201 fc-998A7B family_outfit_regular_400">
                            Modality:
                          </div>
                          <div>
                            <img
                              src={
                                appointmentUpcomingDetail?.consultation_type ===
                                'Hospital Visit'
                                  ? HospitalIcon
                                  : VideoCallCameraIcon
                              }
                              alt="icon"
                            />
                          </div>
                          <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                            {appointmentUpcomingDetail?.consultation_type}
                          </div>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <Image src={CalerderIcon} alt="icon" />
                          <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                            {moment(
                              appointmentUpcomingDetail?.appointment_date_time
                            ).format('MMMM D, YYYY')}
                          </div>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <Image src={ClockIcon} alt="icon" />
                          <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                            {moment(
                              appointmentUpcomingDetail?.appointment_date_time
                            ).format('hh:mmA')}
                          </div>
                        </Stack>
                      </Stack>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      lg={3}
                      xl={3}
                      className="flex justify-end"
                    >
                      <ReusableButton
                        fullWidth={false}
                        variant="contained"
                        color="#F5F5F5"
                        fontSize="14px"
                        fontWeight="500"
                        padding="12px 20px"
                        height="40px"
                        bgColor="#375241"
                        brColor="transparent"
                        fontFamily="outfit_medium"
                        onClick={startVideoCall}
                      >
                        <FormattedMessage id="Start Consultation" />
                      </ReusableButton>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <div className="mt-16">
              <Row className="bc-FFFFFF m-0 p-24 border-1 bColor-EFE3D7">
                <Col xs={12} md={6} className="p-0">
                  <div className="fs-16 lh-252  fc-292D32 family_outfit_medium_500">
                    <FormattedMessage id="Patient’s form" />
                  </div>
                  <div className="pt-24 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                    <FormattedMessage id="Symptoms" />
                  </div>
                  <div className="fs-16 lh-201 fc-3C3C3C pt-04 family_outfit_regular_400">
                    {appointmentUpcomingDetail?.symptoms &&
                      appointmentUpcomingDetail?.symptoms.length > 0 &&
                      appointmentUpcomingDetail.symptoms
                        .map((item) => item?.name)
                        .filter((name) => name)
                        .join(', ')}
                  </div>

                  {appointmentUpcomingDetail?.attachments &&
                    appointmentUpcomingDetail?.attachments.length > 0 && (
                      <>
                        <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                          <FormattedMessage id="Attachments" />
                        </div>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center pt-04"
                        >
                          {appointmentUpcomingDetail?.attachments.map(
                            (item, i) => {
                              return (
                                <Image
                                  src={item?.image}
                                  alt="icon"
                                  style={{
                                    height: '130px',
                                    width: '130px',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 5px 0px',
                                  }}
                                />
                              );
                            }
                          )}
                        </Stack>
                      </>
                    )}

                  <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                    <FormattedMessage id="Description" />
                  </div>
                  <div className="pt-04 fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                    {appointmentUpcomingDetail?.description}
                  </div>
                </Col>

                <Col xs={12} md={6} className="p-0">
                  <div className="fs-16 lh-252 fc-292D32 family_outfit_medium_500">
                    <FormattedMessage id="Medical History" />
                  </div>
                  {appointmentUpcomingDetail?.medical_history &&
                    appointmentUpcomingDetail?.medical_history.length > 0 &&
                    appointmentUpcomingDetail?.medical_history.map(
                      (item, index) => {
                        return (
                          <>
                            <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400 pt-24">
                              {item?.question}
                            </div>
                            <div className="pt-04 fs-16 lh-201 fc-3C3C3C  family_outfit_regular_400">
                              {item?.answer}
                            </div>
                          </>
                        );
                      }
                    )}
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <Row>
              <Col md={6}>
                <div
                  id="local-stream"
                  style={{
                    width: '100%',
                    height: '50vh',
                    // backgroundColor: !isVideoEnabled ? '#000' : 'transparent', // Show black if video is paused
                  }}
                ></div>
              </Col>
              <Col md={6} className="modal-size">
                {remoteUsers.length === 0 ? (
                  <Image
                    src={DemoVedio}
                    alt="Demo Video"
                    style={{
                      width: '100%',
                      height: '50vh',
                    }}
                  />
                ) : (
                  remoteUsers.map((user, index) => (
                    <div
                      key={user.uid}
                      id={`remote-${user.uid}`}
                      style={{
                        width: '100%',
                        height: '50vh',
                      }}
                    ></div>
                  ))
                )}
              </Col>
            </Row>

            <div
              className={
                remoteUsers.length > 0
                  ? 'button-remote-length'
                  : ' button-remote'
              }
            >
              <Stack
                direction="horizontal"
                gap={4}
                className="flex items-center mt-4 justify-center h-20 bg-[#C4C4C4]"
              >
                <button onClick={toggleAudio}>
                  {isAudioEnabled ? (
                    <Image src={MicroPhoneOn} alt="icon" />
                  ) : (
                    <Image src={MicroPhoneOff} alt="icon" />
                  )}
                </button>
                <button onClick={toggleVideo}>
                  {isVideoEnabled ? (
                    <Image src={VedioOn} alt="icon" />
                  ) : (
                    <Image src={VedioOff} alt="icon" />
                  )}
                </button>
                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 20px"
                  height="48px"
                  bgColor="#EF233C"
                  brColor="transparent"
                  fontFamily="outfit_medium"
                  onClick={endVideoCall}
                >
                  <FormattedMessage id="End Consultation" />
                </ReusableButton>
              </Stack>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default UpcomingDetails;
