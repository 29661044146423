import {
  REGISTER_PROFESSIONAL,
  REGISTER_PROFESSIONAL_SUCCESS,
  REGISTER_PROFESSIONAL_FAILURE,
} from 'store/registerProfessional/registerProfessionalAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const registerProfessionalReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_PROFESSIONAL:
      return { ...state, loading: true };
    case REGISTER_PROFESSIONAL_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case REGISTER_PROFESSIONAL_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default registerProfessionalReducer;
