import DoctorAppointments from 'Pages/Doctor/DoctorAppointments';
import PendingDetail from 'Pages/Doctor/DoctorAppointments/PendingDetail';
import RequestDetails from 'Pages/Doctor/DoctorAppointments/RequestDetails';
import UpcomingDetails from 'Pages/Doctor/DoctorAppointments/UpcomingDetails';
import DoctorDashboard from 'Pages/Doctor/DoctorDashboard';
import PatientList from 'Pages/Doctor/DoctorPatient';
import PatientDetail from 'Pages/Doctor/DoctorPatient/DoctorPatientDetail';
import DoctorSchedule from 'Pages/Doctor/DoctorSchedule';
import Login from 'Pages/Login';
import OtpVerificationScreen from 'Pages/OtpVerificationScreen';
import RegisterMetghod from 'Pages/Register';
import RegisterFinalStep from 'Pages/registerFinalStep';
import RegisterProfessionalDetail from 'Pages/RegisterProfessionalDetail';
import RegisterSuccess from 'Pages/RegisterSuccess';
import RegistrationStepOne from 'Pages/RegistrationStepOne';
import SubscriptionPlan from 'Pages/SubscriptionPlan';
import {
  login,
  otpMethod,
  register,
  registrationOne,
  doctorDashboard,
  registerProfessionalDetail,
  registerSuccess,
  doctorSchedule,
  doctorAppointments,
  doctorAppointmentsRequestDetails,
  doctorAppointmentsUpcomingDetails,
  registerFinal,
  subscription,
  doctorPatient,
} from 'routes/AppUrls';

const publicRoutes = [
  {
    path: (locale) => login(locale),
    element: <Login />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => otpMethod(locale),
    element: <OtpVerificationScreen />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => register(locale),
    element: <RegisterMetghod />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => registerSuccess(locale),
    element: <RegisterSuccess />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => registrationOne(locale),
    element: <RegistrationStepOne />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => registerProfessionalDetail(locale),
    element: <RegisterProfessionalDetail />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => registerFinal(locale),
    element: <RegisterFinalStep />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => subscription(locale),
    element: <SubscriptionPlan />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
];

const privateRoutes = [
  {
    path: (locale) => doctorDashboard(locale),
    element: <DoctorDashboard />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => doctorSchedule(locale),
    element: <DoctorSchedule />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => doctorAppointments(locale),
    element: <DoctorAppointments />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale, id) => '/:locale/doctor-appointment-request-details/:id',
    element: <RequestDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale, id) => '/:locale/doctor-appointment-upcoming-details/:id',
    element: <UpcomingDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale, id) => '/:locale/doctor-appointment-pending-details/:id',
    element: <PendingDetail />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => doctorPatient(locale),
    element: <PatientList />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale, id) => '/:locale/doctor-patient-details/:id',
    element: <PatientDetail />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
];

const commonRoutes = [
  {
    path: (locale) => doctorDashboard(locale),
    element: <DoctorDashboard />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];
