import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_QUALIFICATION = 'GET_QUALIFICATION';
export const GET_QUALIFICATION_SUCCESS = 'GET_QUALIFICATION_SUCCESS';
export const GET_QUALIFICATION_FAILURE = 'GET_QUALIFICATION_FAILURE';

// Action Creators
export const getQualification = () => ({
  type: GET_QUALIFICATION,
});

export const getQualificationSuccess = (users) => ({
  type: GET_QUALIFICATION_SUCCESS,
  payload: users,
});

export const getQualificationFailure = (error) => ({
  type: GET_QUALIFICATION_FAILURE,
  payload: error,
});

// Thunk Function
export const registerQualification = (country, slug, external) => {
  return async (dispatch) => {
    dispatch(getQualification());
    try {
      const response = await getMethod(
        url?.getQualifications +
          '?country_id=' +
          country +
          '&provider_type=' +
          slug
      );
      //   dispatch(getQualificationSuccess(response?.data));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(getQualificationSuccess(response?.data));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getQualificationFailure(error?.message));
    }
  };
};
