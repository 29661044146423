import React from 'react';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Styled Tabs
const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: space-around; // Distribute tabs evenly
  & .MuiTabs-indicator {
    background-color: ${(props) => props.indicatorColor || 'blue'};
  }
`;

// Styled Tab
const StyledTab = styled(Tab)`
  font-size: ${(props) => props.fontSize || '1rem'};
  color: ${(props) => props.color || 'black'};
  font-family: 'outfit_medium';
  flex-grow: 1; // Make tabs expand equally
  text-align: center;
  text-transform: capitalize !important;

  &.Mui-selected {
    color: ${(props) => props.selectedColor || 'blue'};
    font-family: 'outfit_medium';
  }
`;

const ReusableTabs = ({
  tabs,
  value,
  onChange,
  currentTab,
  tabStyles,
  indicatorColor,
}) => {
  // const handleChange = (event, newValue) => {
  //   if (onChange) {
  //     onChange(newValue);
  //   }
  // };
  const handleChange = (val) => {
    currentTab(val);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTabs
        value={value}
        onChange={(e, newValue) => handleChange(newValue)}
        aria-label="wrapped label tabs example"
        indicatorColor={indicatorColor}
        variant="fullWidth"
      >
        {tabs.map((tab) => (
          <StyledTab
            key={tab?.value}
            value={tab?.name}
            label={tab?.label}
            {...tabStyles}
            wrapped
          />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default ReusableTabs;
