import React from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/Spinner.css';

const Loader = () => {
  return (
    <div className="spinnerStyle">
      <Spinner color="#864629" size={40} speed={1} animating={true} />
    </div>
  );
};

export default Loader;
