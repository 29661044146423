import React, { useContext, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import checkIcon from 'assets/images/Checkbox.svg';
import ReusableButton from 'components/commom/Button';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { login } from 'routes/AppUrls';

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [choosePlan, setChoosePlan] = useState(0);
  const subscriptionList = [
    {
      id: 1,
      name: 'Silver',
      price: '$15',
      cardHeading: 'Lorem Ipsum dolor',
      checkPara: 'Lorem ipsum',
      checkPara2: 'Mollis ut eget ultrices et orna',
      checkPara3: 'Fringilla libero eges',
    },
    {
      id: 2,
      name: 'Gold',
      price: '$25',
      cardHeading: 'Lorem Ipsum dolor',
      checkPara: 'Lorem ipsum',
      checkPara2: 'Mollis ut eget ultrices et orna',
      checkPara3: 'Fringilla libero eges',
    },
    {
      id: 3,
      name: 'Platinum',
      price: '$40',
      cardHeading: 'Lorem Ipsum dolor',
      checkPara: 'Lorem ipsum',
      checkPara2: 'Mollis ut eget ultrices et orna',
      checkPara3: 'Fringilla libero eges',
    },
  ];
  const handleChoosePlan = (index) => {
    setChoosePlan(index);
  };
  return (
    <Row className="m-0 p-0 landing-image">
      <Row className="m-0 p-0 flex justify-content-center">
        <Col
          xs={12}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          xxl={10}
          className="bg-gray-100 sm:bg-light-100 m-sm-auto m-lg-auto p-0 subscription-list"
        >
          <div class="p-16 sm:p-32">
            <div className="pb-16 fs-24 family_outfit_semiBold_600 lh-312 fc-292D32 flex justify-content-center">
              Subscription Plans
            </div>
            <Row className="g-4">
              {subscriptionList &&
                subscriptionList?.length > 0 &&
                subscriptionList.map((item, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      xxl={4}
                      className=""
                    >
                      <Card className="p-20 subscription-card">
                        <Card.Body className="p-0">
                          <Card.Title>
                            <div className="fs-20 fc-1E1E1E family_outfit_semiBold_600 lh-30">
                              {item?.name}
                            </div>
                            <div className="flex">
                              <div className="fs-24 fc-1E1E1E family_outfit_medium_500 lh-288 pt-8">
                                {item?.price}
                              </div>
                              <div className="pl-8 fs-14 fc-A2A2A2 lh-196 family_outfit_regular_400 flex justify-content-center items-end">
                                {'per month'}
                              </div>
                            </div>
                          </Card.Title>
                          <Card.Text>
                            <div className="fs-16 family_outfit_medium_500 lh-24">
                              {item?.cardHeading}
                            </div>
                            <div className="flex pt-12">
                              <div>
                                <Image src={checkIcon} alt="checkIcon" />
                              </div>
                              <div className="fs-14 fc-1E1E1E lh-21 family_outfit_regular_400 pl-10">
                                {item?.checkPara}
                              </div>
                            </div>
                            <div className="flex pt-12">
                              <div>
                                <Image src={checkIcon} alt="checkIcon" />
                              </div>
                              <div className="fs-14 fc-1E1E1E lh-21 family_outfit_regular_400 pl-10">
                                {item?.checkPara2}
                              </div>
                            </div>
                            <div className="flex pt-12">
                              <div>
                                <Image src={checkIcon} alt="checkIcon" />
                              </div>
                              <div className="fs-14 fc-1E1E1E lh-21 family_outfit_regular_400 pl-10">
                                {item?.checkPara3}
                              </div>
                            </div>
                            <div className="pt-20">
                              <ReusableButton
                                fullWidth={true}
                                variant="outlined"
                                color={`${choosePlan === index ? '#ffffff' : '#864629'}`}
                                fontSize="16px"
                                padding="12px"
                                height="48px"
                                bgColor={`${choosePlan === index ? '#864629' : 'transparent'}`}
                                brColor={`${choosePlan === index ? '' : '#EFE3D7'}`}
                                fontFamily="outfit_semiBold"
                                onClick={() => handleChoosePlan(index)}
                              >
                                Get Started
                              </ReusableButton>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            <div
              className="fs-16 family_outfit_medium_500 fc-7F7F7F lh-16 flex pt-32 justify-content-center underline decoration-1 ... cursor-pointer"
              onClick={() => navigate(login(locale))}
            >
              I'll do this later
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
};

export default SubscriptionPlan;
