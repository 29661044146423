import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_UPCOMING_APPOINTMENT_LIST = 'GET_UPCOMING_APPOINTMENT_LIST';
export const GET_UPCOMING_APPOINTMENT_LIST_SUCCESS =
  'GET_UPCOMING_APPOINTMENT_LIST_SUCCESS';
export const GET_UPCOMING_APPOINTMENT_LIST_FAILURE =
  'GET_UPCOMING_APPOINTMENT_LIST_FAILURE';

// Action Creators
export const getAppointmentUpcomingRequest = () => ({
  type: GET_UPCOMING_APPOINTMENT_LIST,
});

export const getAppointmentUpcomingSuccess = (users) => ({
  type: GET_UPCOMING_APPOINTMENT_LIST_SUCCESS,
  payload: users,
});

export const getAppointmentUpcomingFailure = (error) => ({
  type: GET_UPCOMING_APPOINTMENT_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getAppointmentUpcoming = (external, value) => {
  return async (dispatch) => {
    dispatch(getAppointmentUpcomingRequest());
    try {
      const response = await getMethodWithToken(
        url?.getSchedule +
          '?appointment_type=upcoming&appointment_date=' +
          value
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getAppointmentUpcomingSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getAppointmentUpcomingFailure(error?.message));
    }
  };
};
