import {
  UPADATE_AVAVILABLITY,
  UPADATE_AVAVILABLITY_SUCCESS,
  UPADATE_AVAVILABLITY_FAILURE,
} from 'store/updateAvailablity/updateAvailablityAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const updateAvailReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPADATE_AVAVILABLITY:
      return { ...state, loading: true };
    case UPADATE_AVAVILABLITY_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case UPADATE_AVAVILABLITY_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default updateAvailReducer;
