import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_TITLE_TYPE = 'GET_TITLE_TYPE';
export const GET_TITLE_TYPE_SUCCESS = 'GET_TITLE_TYPE_SUCCESS';
export const GET_TITLE_TYPE_FAILURE = 'GET_TITLE_TYPE_FAILURE';

// Action Creators
export const getTitleRequest = () => ({
  type: GET_TITLE_TYPE,
});

export const getTitleSuccess = (users) => ({
  type: GET_TITLE_TYPE_SUCCESS,
  payload: users,
});

export const getTitleFailure = (error) => ({
  type: GET_TITLE_TYPE_FAILURE,
  payload: error,
});

// Thunk Function
export const registerTitleType = (value, external) => {
  return async (dispatch) => {
    dispatch(getTitleRequest());
    try {
      const response = await getMethod(url?.getSwitch + '?slug=' + value);
      dispatch(getTitleSuccess(response?.data));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getTitleFailure(error?.message));
    }
  };
};
