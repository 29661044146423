import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Stack, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import 'Pages/Doctor/DoctorDashboard/doctorDashboard.scss';
import { LocaleContext } from 'routes/Router';
import AddIcon from 'assets/images/Dashboard/add.png';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableButton from 'components/commom/Button';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import AvtarFrameImage from 'assets/images/Dashboard/AvtarFrame.png';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import ArrowRightIcon from 'assets/images/Dashboard/arrow-right-detail.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import CallIcon from 'assets/images/Dashboard/call.svg';
import ClockIcon from 'assets/images/Dashboard/clock.svg';
import CalendarIcon from 'assets/images/Dashboard/calendar.svg';
import NextIcon from 'assets/images/Dashboard/nextIcon.png';
import PrevIcon from 'assets/images/Dashboard/prevIcon.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ClearTokenRedirectLogin } from 'utils/general';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { getDashboard } from 'store/dashboard/dashboardAction';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import moment from 'moment';
import { doctorAppointmentsUpcomingDetails } from 'routes/AppUrls';
import EmptyScreen from 'assets/images/Dashboard/empty.svg';
import Loader from 'components/commom/Loader';

const DoctorDashboard = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const [dashboardDetail, setDashboardetail] = useState('');

  const loading = useSelector((state) => state?.getDashboardReducer?.loading);

  useEffect(() => {
    getDoctorDashboard();
  }, []);

  const getDoctorDashboard = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setDashboardetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getDashboard(external));
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        onClick={onClick}
      >
        <img src={NextIcon} alt="icon" />
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        onClick={onClick}
      >
        <img src={PrevIcon} alt="icon" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Row className="bc-FEF9F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer  lg:ml-left border-l bColor-EFE3D7'
            : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
        }
      >
        <h2 className="fc-292D32 fs-32 family_outfit_semiBold_600  m-0">
          <FormattedMessage id="Dashboard" />
        </h2>

        <Row className="mt-3 ">
          <Col lg={4} xl={3} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <div className="fs-16 fc-998A7B family_outfit_regular_400">
                    <FormattedMessage id="New Appointment Requests" />
                  </div>
                </Stack>
                <div className="fs-28 fc-3C3C3C family_outfit_regular_500 mt-2">
                  {dashboardDetail?.new_requests_count}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xl={3} md={6} sm={12}>
            <Card className={locale === 'en' ? 'text-start' : 'text-end'}>
              <Card.Body>
                <Stack direction="horizontal" gap={2} className="items-center">
                  <div className="fs-16 fc-998A7B family_outfit_regular_400">
                    <FormattedMessage id="All Patients" />
                  </div>
                </Stack>
                <div className="fs-28 fc-3C3C3C family_outfit_regular_500 mt-2">
                  {dashboardDetail?.all_patients_count}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 pt-1">
          <Col sm={12} md={12}>
            <div className="pt-28 fc-292D32 fs-20 family_outfit_semiBold_600">
              <FormattedMessage id="Upcoming Appointments" />
            </div>
          </Col>
        </Row>

        <Row className="mt-2 p-0 m-0 ">
          {dashboardDetail?.upcoming_appointments &&
          dashboardDetail?.upcoming_appointments.length > 0 ? (
            <Col sm={12} md={12} className="mt-2 p-0 m-0">
              <Slider
                {...settings}
                className={
                  locale === 'en'
                    ? 'sliderDetailsEn single-item-slider'
                    : 'sliderDetailsAr single-item-slider'
                }
              >
                {dashboardDetail?.upcoming_appointments.map((item, index) => {
                  return (
                    <div className="pr-3">
                      <Card className="p-12 subscription-card">
                        <Card.Body className="p-0">
                          <Card.Title>
                            <div className="flex m-0 p-0 justify-between">
                              <Stack
                                direction="horizontal"
                                gap={2}
                                className="items-center "
                              >
                                <Image
                                  src={
                                    item?.patient?.profile_pic !== ''
                                      ? item?.patient?.profile_pic
                                      : DefaultImage
                                  }
                                  alt={'image'}
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                  }}
                                />
                                <div className="fs-18 fc-292D32 lh-2268 family_outfit_semiBold_600 p-0 m-0">
                                  {item?.patient?.full_name}
                                </div>
                              </Stack>
                              <Image
                                src={
                                  item?.consultation_type === 'Hospital Visit'
                                    ? HospitalIcon
                                    : VideoCallCameraIcon
                                }
                                alt={'image'}
                              />
                            </div>
                          </Card.Title>
                          <Card.Text>
                            <Stack
                              direction="horizontal"
                              gap={5}
                              className="items-center pt-04"
                            >
                              <Stack
                                direction="horizontal"
                                gap={2}
                                className="items-center"
                              >
                                <img src={CalendarIcon} alt="icon" />
                                <div className="fs-16 fc-292D32 lh-201  family_outfit_medium_500">
                                  {moment(item?.appointment_date_time).format(
                                    'DD MMM YYYY'
                                  )}
                                </div>
                              </Stack>
                              <Stack
                                direction="horizontal"
                                gap={2}
                                className="items-center"
                              >
                                <img src={ClockIcon} alt="icon" />
                                <div className="fs-16 fc-292D32 lh-201  family_outfit_medium_500">
                                  {moment(item?.appointment_date_time).format(
                                    'hh:mmA'
                                  )}
                                </div>
                              </Stack>
                            </Stack>

                            <Row className="pt-16">
                              <Col md={7}>
                                <div className="fs-14 lh-21 fc-998A7B family_outfit_regular_400">
                                  Booking ID:
                                  <span className="fc-3C3C3C">
                                    {item?.booking_id}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                md={5}
                                className="py-0 pl-0 flex justify-end"
                              >
                                <Stack
                                  direction="horizontal"
                                  gap={1}
                                  className="flex items-center"
                                >
                                  <div
                                    className="fs-16 fc-C56616 family_outfit_medium_500 cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        doctorAppointmentsUpcomingDetails(
                                          locale,
                                          item?.id
                                        )
                                      )
                                    }
                                  >
                                    <FormattedMessage id="View Details" />
                                  </div>
                                  <img
                                    src={ArrowRightIcon}
                                    alt="icon"
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        doctorAppointmentsUpcomingDetails(
                                          locale,
                                          item?.id
                                        )
                                      )
                                    }
                                  />
                                </Stack>
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </Slider>
            </Col>
          ) : (
            <Col
              sm={12}
              md={12}
              className="border-1 bColor-EFE3D7 h-132 flex items-center justify-center"
            >
              <div className="text-center">
                <div className="fs-14 lh-201 fc-202224 family_outfit_regular_400 ">
                  <FormattedMessage id="You haven’t created your schedule yet" />
                </div>
                {/* <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#C56616"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 25px"
                  height="40px"
                  bgColor="transparent"
                  brColor="#EFE3D7"
                  fontFamily="outfit_medium"
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Create Schedule" />
                </ReusableButton> */}
              </div>
            </Col>
          )}
        </Row>

        <Row className="mt-4 pt-1">
          <Col sm={12} md={12} className="flex justify-between">
            <div className="fs-20 fc-292D32 family_outfit_bold_700">
              <FormattedMessage id="Recent Patients" />
            </div>
            <Stack
              direction="horizontal"
              gap={2}
              className="items-center cursor-pointer"
            >
              <p className="p-0 m-0 fs-14 fc-C56616 family_outfit_medium_500 ">
                <FormattedMessage id="View All" />
              </p>
              <img src={ArrowRightIcon} alt="icon" />
            </Stack>
          </Col>
        </Row>

        {loading ? (
          <Loader />
        ) : dashboardDetail?.patient_list &&
          dashboardDetail?.patient_list.length > 0 ? (
          <>
            <Row className="mt-3 listCustomHeader m-0 p-0 flex items-center">
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Patient & ID" />
                </p>
              </Col>
              {/* 
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Address" />
                </p>
              </Col> */}

              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Last Appointment" />
                </p>
              </Col>

              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {dashboardDetail?.patient_list.map((item, index) => {
                return (
                  <Row className="m-0 p-0 flex items-center listAppointment">
                    <Col lg={3} md={6} sm={12}>
                      <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_semiBold_600">
                        {item?.patient_name}
                      </div>
                      <div className="fs-12 fc-202224 family_outfit_regular_400 pt-04 lh-1512">
                        {item?.patient_id}
                      </div>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img src={MailIcon} alt="icon" />
                        <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_regular_400 break-all ...">
                          {item?.patient_email}
                        </div>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img src={CallIcon} alt="icon" />
                        <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                          {`${item?.patient_phone}`}
                        </div>
                      </Stack>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                        {moment(item?.consulted_on).format('DD MMM YYYY')}
                      </div>
                    </Col>

                    <Col lg={3} md={6} sm={12}>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                      >
                        <div className="fs-16 fc-C56616 family_outfit_medium_500 cursor-pointer">
                          <FormattedMessage id="View Details" />
                        </div>
                        <img src={ArrowRightIcon} alt="icon" />
                      </Stack>
                    </Col>
                  </Row>
                );
              })}
            </Row>
          </>
        ) : (
          <div className="flex justify-center pt-80">
            <div>
              <Image src={EmptyScreen} alt="icon" />
              <div className="fs-20 fc-3C3C3C lh-2 family_outfit_medium_500 text-center	pt-30">
                Nothing here yet
              </div>
              <div className="fs-16 fc-998A7B lh-16 family_outfit_regular_400  text-center pt-8">
                No appointments have been booked
              </div>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default DoctorDashboard;
