import axios from 'axios';
import LocalStorageManager from 'managers/LocalStorageManger';
import url from 'utils/environemnt/env.json';
import hmacKey from 'utils/environemnt/env.json';
import {
  getDeviceId,
  getDeviceName,
  getDeviceVersion,
  getTimeZone,
} from 'utils/general';

//////BaseUrl////////////////////////////////////
// var baseUrl = "";
// if (process.env.NODE_ENV === 'production') {
//    baseUrl = url.BASE_URL_PRODUCTION;
// } else if (process.env.NODE_ENV === 'staging') {
//    baseUrl = url.BASE_URL_STAGING;
// } else {
//    baseUrl = url.BASE_URL_DEVELOPMENT;
// }
var baseUrl = url.BASE_URL_DEVELOPMENT;

const getToken = () => {
  const token = LocalStorageManager.getItem('token');
  return token;
};

///////////Language/////////////////////////////////
const getLocale = () => {
  const locale = LocalStorageManager.getItem('locale');
  return locale;
};
///////////Language/////////////////////////////////

//////////////Hmac Key///////////////////////////
var CryptoJS = require('crypto-js');
const hmacConvert = (method, data) => {
  var string = `${url.hmacKey}-${method}-${data !== '' ? JSON.stringify(data) : ''}`;
  var hash = CryptoJS.HmacSHA256(string, url.hmacKey);
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
  return hashInBase64;
};
//////////////Hmac Key///////////////////////////

// var CryptoJS = require('crypto-js');

// const hmacConvert = (method, data) => {
//   var string = `${hmacKey}-${method}-${data !== '' ? JSON.stringify(data) : ''}`;
//   var hash = CryptoJS.HmacSHA256(string, hmacKey);
//   var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
//   return hashInBase64;
// };

export const getMethod = async (url) => {
  let hKey = await hmacConvert('GET', '');
  try {
    let data = await axios({
      method: 'GET',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const getMethodWithToken = async (url) => {
  let hKey = await hmacConvert('GET', '');
  try {
    let data = await axios({
      method: 'GET',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        Authorization: 'token' + ' ' + getToken(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethod = async (url, body) => {
  let hKey = await hmacConvert('POST', body);
  try {
    let data = await axios({
      method: 'POST',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
      data: body,
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethodWithUpload = async (url, body) => {
  try {
    let data = await axios({
      method: 'POST',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'multipart/form-data',
        language: getLocale(),
        timezone: getTimeZone(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
      },
      data: body,
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethodWithToken = async (url, body) => {
  let hKey = await hmacConvert('POST', body);
  try {
    let data = await axios({
      method: 'POST',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        Authorization: 'token' + ' ' + getToken(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
      data: body,
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const putMethod = async (url, body) => {
  let hKey = await hmacConvert('PUT', body);
  try {
    let data = await axios({
      method: 'PUT',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
      data: body,
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

export const putMethodWithToken = async (url, body) => {
  let hKey = await hmacConvert('PUT', body);
  try {
    let data = await axios({
      method: 'PUT',
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        language: getLocale(),
        timezone: getTimeZone(),
        Authorization: 'token' + ' ' + getToken(),
        'device-version': getDeviceVersion(),
        'device-id': getDeviceId(),
        'device-name': getDeviceName(),
        'device-type': getDeviceName(),
        'hmac-secret': hKey,
      },
      data: body,
    });

    return data;
  } catch (error) {
    return error.response;
  }
};

// export const getMethodWithToken = async (url) => {
//    try {
//       let data = await axios({
//          method: "GET",
//          url: baseUrl + url,
//          headers: {
//             "Content-Type": "application/json",
//             "language": getLocale(),
//             Authorization: "Bearer" + " " + getToken(),
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };

// export const postMethodWithToken = async (url, body) => {
//    try {
//       let data = await axios({
//          method: "POST",
//          url: baseUrl + url,
//          headers: {
//             "Content-Type": "application/json",
//             "language": getLocale(),
//             Authorization: "Bearer" + " " + getToken(),
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//          data: body,
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };

// export const postProfileMethodWithToken = async (url, body) => {
//    try {
//       let data = await axios({
//          method: "POST",
//          url: baseUrl + url,
//          headers: {
//             'Content-Type': 'multipart/form-data',
//             "language": getLocale(),
//             Authorization: "Bearer" + " " + getToken(),
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//          data: body,
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };

// export const postMethodWithRegisterToken = async (url, body) => {
//    const token = LocalStorageManager.getItem('registerToken');
//    try {
//       let data = await axios({
//          method: "POST",
//          url: baseUrl + url,
//          headers: {
//             "Content-Type": "application/json",
//             "language": getLocale(),
//             Authorization: "Bearer" + " " + token,
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };

// export const putMethodWithToken = async (url, body) => {
//    try {
//       let data = await axios({
//          method: "PUT",
//          url: baseUrl + url,
//          headers: {
//             "Content-Type": "application/json",
//             "language": getLocale(),
//             'Authorization': "Bearer" + " " + getToken(),
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//          data: body,
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };

// export const deleteMethodWithToken = async (url, body) => {
//    try {
//       let data = await axios({
//          method: "DELETE",
//          url: baseUrl + url,
//          headers: {
//             "Content-Type": "application/json",
//             "language": getLocale(),
//             'Authorization': "Bearer" + " " + getToken(),
//             "device-id": getDeviceId(),
//             "device-type": getDeviceName()
//          },
//          data: body,
//       });

//       return data;
//    } catch (error) {
//       return error.response;
//    }
// };
