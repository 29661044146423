import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { getPatient } from 'store/getPatient/getPatientAction';
import { ClearTokenRedirectLogin } from 'utils/general';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import moment from 'moment';
import requestCheck from 'assets/images/Dashboard/pending-detail.svg';
import ArrowLeftIcon from 'assets/images/arrow-left.svg';
import ToastNotification from 'components/commom/Toast';
import { doctorPatient } from 'routes/AppUrls';
import { getPatientDetail } from 'store/getPatientDetail/getPatientDetailAction';
import EmptyScreen from 'assets/images/Dashboard/empty.svg';
import Loader from 'components/commom/Loader';

const PatientDetail = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [patientDetail, setPatientDetail] = useState([]);

  const loading = useSelector(
    (state) => state?.getPatientDetailReducer?.loading
  );

  useEffect(() => {
    getPatientDetailValue();
  }, []);

  const getPatientDetailValue = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setPatientDetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getPatientDetail(external, params?.id));
  };

  return (
    <div>
      <Row className="bc-FEF9F6 m-0 p-0">
        <Col
          className={
            locale === 'en'
              ? 'commonEnContainer lg:ml-left border-l bColor-EFE3D7'
              : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
          }
        >
          <div className="flex pb-16">
            <div className="fs-12 lh-24 fc-998A7B family_outfit_regular_400">
              My patients
            </div>
            <div className="pl-04 flex items-center">
              <Image src={requestCheck} alt="icon" />
            </div>
            <div className="pl-04 fs-12 fc-242E39 lh-24 family_outfit_semiBold_600">
              Patient Details
            </div>
          </div>
          <Row className="bc-FFFFFF p-24 border-1 bColor-EFE3D7">
            <Col xs={12} md={12} className="p-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={ArrowLeftIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(doctorPatient(locale))}
                />
                <div className="fs-16 lh-16 fc-292D32 family_outfit_medium_500">
                  <FormattedMessage id="Patient" />
                  <span className="pl-2">
                    {patientDetail?.patient_details?.patient_id}
                  </span>
                </div>
              </Stack>
            </Col>

            <Col xs={12} md={12} className="mt-4 p-0 bColor-D6DDE6">
              <Row>
                <Col md={6}>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="flex items-start"
                  >
                    <Image
                      src={
                        patientDetail?.patient_details?.profile_image !== ''
                          ? patientDetail?.patient_details?.profile_image
                          : DefaultImage
                      }
                      alt={'icon'}
                      style={{
                        height: '88px',
                        width: '88px',
                        borderRadius: '50%',
                      }}
                    />
                    <div>
                      <div className="fs-18 lh-2268 fc-202224 family_outfit_medium_500">
                        {patientDetail?.patient_details?.patient_name}
                      </div>
                      <div>
                        <Stack
                          direction="horizontal"
                          gap={5}
                          className="items-center pt-12"
                        >
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center"
                          >
                            <Image src={CallIcon} alt="icon" />
                            <div className="fs-16 lh-201 fc-202224 family_outfit_regular_400">
                              {patientDetail?.patient_details?.patient_phone}
                            </div>
                          </Stack>
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="items-center"
                          >
                            <img src={MailIcon} alt="icon" />
                            <div className="fs-16 lh-201 fc-202224 family_outfit_regular_400">
                              {patientDetail?.patient_details?.patient_email}
                            </div>
                          </Stack>
                        </Stack>
                      </div>
                    </div>
                  </Stack>
                </Col>
                <Col md={6} className="flex justify-end items-center">
                  <div>
                    <div className="fs-14 lh-176 family_outfit_regular_400 fc-998A7B">
                      Joining Date
                    </div>
                    <div className="fs-16 pt-8 lh-201 family_outfit_regular_400 fc-202224">
                      {moment(
                        patientDetail?.patient_details?.consulted_on
                      ).format('DD MMM YYYY')}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="p-0">
              <div className="pt-24 pb-12 fs-16 lh-16 family_outfit_medium_500 fc-292D32">
                Appointment History
              </div>
            </Col>
            <Col md={12} className="p-0">
              {loading ? (
                <Loader />
              ) : patientDetail?.appointment_history &&
                patientDetail?.appointment_history.length > 0 ? (
                <>
                  <Row className="listCustomHeader m-0 p-0 flex items-center">
                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                        <FormattedMessage id="Booking ID" />
                      </p>
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                        <FormattedMessage id="Modality" />
                      </p>
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                        <FormattedMessage id="Date & Time" />
                      </p>
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                        <FormattedMessage id="Actions" />
                      </p>
                    </Col>
                  </Row>
                  <Row className="bc-FFFFFF m-0 p-0">
                    {patientDetail?.appointment_history.map((item, index) => {
                      return (
                        <Row className="m-0 p-0 flex items-center listAppointment">
                          <Col lg={2} md={6} sm={12}>
                            <div className="fs-12 fc-202224 lh-1512 family_outfit_regular_400">
                              {item?.booking_id}
                            </div>
                          </Col>
                          <Col lg={2} md={6} sm={12}>
                            <div className="flex text-center">
                              <img
                                src={
                                  item?.consultation_type === 'Hospital Visit'
                                    ? HospitalIcon
                                    : VideoCallCameraIcon
                                }
                                alt={'image'}
                              />
                            </div>
                          </Col>
                          <Col lg={2} md={6} sm={12}>
                            <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                              {`${moment(item?.appointment_date_time).format('DD MMM YYYY')}, ${moment(
                                item?.appointment_date_time
                              ).format('hh:mmA')}`}
                            </div>
                          </Col>
                          <Col lg={2} md={6} sm={12}>
                            <Stack
                              direction="horizontal"
                              gap={2}
                              className="items-center cursor-pointer"
                            >
                              <div className="fs-14 lh-14 fc-292D32 family_outfit_medium_500">
                                Summary
                              </div>
                              <div className="flex" key={index}>
                                <a
                                  href={item?.diagnosis_report || '#'}
                                  download={
                                    item?.diagnosis_report
                                      ? item?.diagnosis_report.split('/').pop()
                                      : 'license'
                                  }
                                  target="_blank"
                                  className="fs-14 lh-14 fc-292D32 family_outfit_medium_500 cursor-pointer underline underline-offset-4"
                                >
                                  {item?.diagnosis_report &&
                                    item?.diagnosis_report.split('/').pop()}
                                </a>
                              </div>
                            </Stack>
                          </Col>
                        </Row>
                      );
                    })}
                  </Row>
                </>
              ) : (
                <div className="flex justify-center pt-120">
                  <div>
                    <Image src={EmptyScreen} alt="icon" />
                    <div className="fs-20 fc-3C3C3C lh-2 family_outfit_medium_500 text-center	pt-30">
                      Nothing here yet
                    </div>
                    <div className="fs-16 fc-998A7B lh-16 family_outfit_regular_400  text-center pt-8">
                      No appointments have been booked
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PatientDetail;
