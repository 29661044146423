import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

const WrapperTextField = styled(TextField)`
  width: 100%;

  .MuiInputLabel-root {
    font-size: 15px;
    line-height: 17.64px;
    font-family: outfit_regular;
    transform: none;
    color: #292d32;
    width: 100%;
  }

  .MuiInputLabel-root.Mui-focused {
    color: #292d32;
  }

  .MuiFormControl-root {
    position: relative;
  }

  .MuiInput-root {
    border: 1px solid #efe3d7;
    border-radius: 0px;
    ${(props) =>
      !props.multiline &&
      `
			height: ${props.height ? `${props.height}px` : '50px'};
			// padding: 15px;
      padding:${props.padding ? `${props.padding}` : '15px'};
		`}
    font-family: outfit_regular;
    font-size: 16px;
    line-height: 16px;
    color: #a4aab2;
    // margin-top: 20px;

    ${(props) =>
      props.bgColor &&
      `
	 	    background-color:  ${props.theme[props.bgColor]};
	    `}

    ${(props) =>
      props.borderNone &&
      `
	 	    border: none;
	    `}

		&:before, &:after {
      border: none !important;
    }

    .MuiInputBase-input {
      // color: #a4aab2;
      ${(props) =>
        props.Color &&
        `
           color:  ${props.Color ? props.Color : '#a4aab2'};
        `}

      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      padding: ${(props) => (props.multiline ? '12px' : '0')};

      &::placeholder {
        color: #a4aab2; /* Your placeholder color here */
        opacity: 1; /* Ensures full visibility */
      }

      ${(props) =>
        props.multiline &&
        `
				min-height: ${props.height ? `${props.height}px` : '80px'}; /* For multiline, define a min height */
				line-height: 1.5; /* Better line spacing for multiline content */
				overflow-y:auto !important;
			    max-height: ${props.height ? `${props.height}px !important` : '80px'};
			`}
    }
  }
`;

const WrapperErrorDiv = styled.div`
  font-size: 16px;
  color: #292d32;
  line-height: 24px;
  font-weight: 400;
  font-family: Inter_Regular;
  margin-top: 5px;
  position: absolute;
  bottom: -30px;
`;

const WrapperHelperDiv = styled.div`
  color: #292d32;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter_Regular;
`;

function LabelWithTextArea({ containerProps, padding, ...props }) {
  return (
    <FormControl fullWidth>
      <WrapperTextField
        id="standard-required"
        variant="standard"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        multiline={props.multiline}
        {...props}
      />

      {props.errorText && <WrapperErrorDiv>{props.errorText}</WrapperErrorDiv>}

      {props.helperInfoPassword && (
        <WrapperHelperDiv>
          <div>
            <FormattedMessage id="register.helperText" />
          </div>
        </WrapperHelperDiv>
      )}
    </FormControl>
  );
}
LabelWithTextArea.defaultProps = {
  fontSize: 16,
  textColor: '#A4AAB2',
};

export default LabelWithTextArea;
