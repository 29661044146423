export const login = (locale) => `/${locale}`;
// export const login = (locale) => `/${locale}/login`;
export const otpMethod = (locale) => `/${locale}/otp`;
export const register = (locale) => `/${locale}/register`;
export const registerSuccess = (locale) => `/${locale}/register/success`;
export const registrationOne = (locale) => `/${locale}/registrationOne`;
export const registerProfessionalDetail = (locale) =>
  `/${locale}/registerProfessionalDetail`;
export const registerFinal = (locale) => `/${locale}/register-final`;
export const subscription = (locale) => `/${locale}/register-subscription`;
export const doctorDashboard = (locale) => `/${locale}/doctor-dashboard`;
export const doctorSchedule = (locale) => `/${locale}/doctor-schedule`;
export const doctorAppointments = (locale) => `/${locale}/doctor-appointment`;
export const doctorAppointmentsRequestDetails = (locale, id) =>
  `/${locale}/doctor-appointment-request-details/${id}`;
export const doctorAppointmentsUpcomingDetails = (locale, id) =>
  `/${locale}/doctor-appointment-upcoming-details/${id}`;
export const doctorAppointmentsPendingDetails = (locale, id) =>
  `/${locale}/doctor-appointment-pending-details/${id}`;
export const doctorPatient = (locale) => `/${locale}/doctor-patient`;
export const doctorPatientDetails = (locale, id) =>
  `/${locale}/doctor-patient-details/${id}`;
