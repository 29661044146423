import {
  CREATE_AVAVILABLITY,
  CREATE_AVAVILABLITY_SUCCESS,
  CREATE_AVAVILABLITY_FAILURE,
} from 'store/createAvailablity/createAvailablityAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const createAvailReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_AVAVILABLITY:
      return { ...state, loading: true };
    case CREATE_AVAVILABLITY_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case CREATE_AVAVILABLITY_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default createAvailReducer;
