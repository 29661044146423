import { ACTIVE_INDEX } from 'store/BasicDetail/BasicDetailAction';

const initialState = {
  activeIndex: 0,
};

const basicDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload,
      };
    default:
      return state;
  }
};

export default basicDetailsReducer;
