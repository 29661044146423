import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'assets/styles/common.scss';
import 'react-calendar/dist/Calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'Pages/Doctor/DoctorSchedule/doctorSchedule.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Stack } from 'react-bootstrap';
import { LocaleContext } from 'routes/Router';
import EditIcon from 'assets/images/schedule/edit.png';
import ArrowLeftIcon from 'assets/images/schedule/arrow-left.png';
import CheckBoxInput from 'components/commom/CheckBoxInput';
import LabelWithInput from 'components/commom/LabelWithInput';
import SelectInput from 'components/commom/SelectInput';
import ReusableButton from 'components/commom/Button';
import AddIcon from 'assets/images/Dashboard/add.png';
import CustomTabs from 'components/commom/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { getSchedule } from 'store/getSchedule/getScheduleAction';
import { ClearTokenRedirectLogin } from 'utils/general';
import { useNavigate } from 'react-router-dom';
import LocalStorageManager from 'managers/LocalStorageManger';
import { getScheduleDetail } from 'store/getScheduleDetail/getScheduleDetailAction';
import { updateAvailPost } from 'store/updateAvailablity/updateAvailablityAction';
import { createAvailPost } from 'store/createAvailablity/createAvailablityAction';
import { getMasterData } from 'store/masterData/masterDataAction';
import { doctorSchedule } from 'routes/AppUrls';

const localizer = momentLocalizer(moment);

const DoctorSchedule = () => {
  const { locale } = useContext(LocaleContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [conf, setConf] = ToastNotification();
  const [scheduleList, setScheduleList] = useState([]);
  const [weekList, setWeekList] = useState([]);
  const [scheduleDetail, setScheduleDetail] = useState([]);
  const [tab, setTab] = useState(0);
  const [selectedEventId, setSelectedEventId] = React.useState(null);
  const [editAvailability, setEditAvailability] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getAppointmentsData = useSelector(
    (state) => state?.getScheduleDetailReducer?.users?.data
  );

  useEffect(() => {
    getMasterResult();
  }, []);

  const getMasterResult = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        // setWeekList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getMasterData(external));
  };

  useEffect(() => {
    getScheduleList();
  }, [selectedDate]);

  const getScheduleList = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setScheduleList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getSchedule(external, moment(selectedDate).format('YYYY-MM-DD')));
  };

  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  const formattedEvents = scheduleList.map((event) => ({
    id: event.id,
    title: `Appointment with ${event?.patient?.full_name} - ${event?.consultation_type}`,
    start: new Date(event?.appointment_date_time),
    end: new Date(new Date(event.appointment_date_time)),
  }));

  const handleSelectEvent = (event) => {
    setSelectedEventId(event.id);
  };

  const dayPropGetter = (date) => {
    if (moment(date).isSame(moment(), 'day')) {
      return {
        style: {
          backgroundColor: 'transparent',
        },
      };
    }
    return {};
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const highlightWeekends = ({ date }) => {
    const today = new Date().toDateString();
    const day = date.getDay();
    const isToday = date.toDateString() === today;
    const isSelected = date.toDateString() === selectedDate?.toDateString();

    if (isSelected) return 'selected';
    if (isToday) return 'today';
    if (day === 0) return 'sunday-highlight';
    if (day === 6) return 'saturday-highlight';
    return null;
  };

  const handleNavigationClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const disablePastDates = ({ date }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to start of day
    return date < today; // Disable dates before today
  };

  const tabData = [
    {
      label: intl.formatMessage({ id: 'Mon' }),
      slug: intl.formatMessage({ id: 'Monday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 17,
    },
    {
      label: intl.formatMessage({ id: 'Tue' }),
      slug: intl.formatMessage({ id: 'Tuesday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 18,
    },
    {
      label: intl.formatMessage({ id: 'Wed' }),
      slug: intl.formatMessage({ id: 'Wednesday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 19,
    },
    {
      label: intl.formatMessage({ id: 'Thu' }),
      slug: intl.formatMessage({ id: 'Thursday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 20,
    },
    {
      label: intl.formatMessage({ id: 'Fri' }),
      slug: intl.formatMessage({ id: 'Friday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 21,
    },
    {
      label: intl.formatMessage({ id: 'Sat' }),
      slug: intl.formatMessage({ id: 'Saturday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 22,
    },
    {
      label: intl.formatMessage({ id: 'Sun' }),
      slug: intl.formatMessage({ id: 'Sunday' }),
      isHoliday: false,
      shift: { start: '', end: '' },
      day_of_week: { day: '', day_of_week_id: '' },
      appointment_length: '',
      buffer_length: '',
      breaks: [{ start_time: '', end_time: '' }],
      isShow: false,
      weekId: 16,
    },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleAvailablity = () => {
    setEditAvailability(true);
    handleAvailablityData();
  };

  const handleAvailablityData = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        // setScheduleDetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(
      getScheduleDetail(external, LocalStorageManager.getItem('doctorId'), tab)
    );
  };

  function convertTo24HourFormat(time12h) {
    return moment(time12h, ['h:mm A']).format('HH:mm');
  }

  const [tabDetails, setTabDetails] = useState(() => {
    if (getAppointmentsData?.length > 0) {
      return tabData.map((tab) => {
        const matchingAppointment = getAppointmentsData.find(
          (appointment) => appointment?.day_of_week?.day === tab.slug
        );
        const matchingAppointmentIndex = getAppointmentsData.findIndex(
          (appointment) => appointment?.day_of_week?.day === tab.slug
        );
        if (matchingAppointment) {
          return {
            ...tab,
            isHoliday:
              getAppointmentsData?.[matchingAppointmentIndex]?.is_week_off,
            id: getAppointmentsData?.[matchingAppointmentIndex]?.id,
            shift: {
              start: convertTo24HourFormat(
                getAppointmentsData?.[matchingAppointmentIndex]?.start_time
              ),
              end: convertTo24HourFormat(
                getAppointmentsData?.[matchingAppointmentIndex]?.end_time
              ),
            },
            day_of_week: {
              day: getAppointmentsData?.[matchingAppointmentIndex]?.day_of_week
                ?.day,
              day_of_week_id:
                getAppointmentsData?.[matchingAppointmentIndex]?.day_of_week
                  ?.id,
            },
            appointment_length:
              getAppointmentsData?.[matchingAppointmentIndex]
                ?.appointment_length,
            buffer_length:
              getAppointmentsData?.[matchingAppointmentIndex]?.buffer_length,
            breaks: getAppointmentsData?.[
              matchingAppointmentIndex
            ]?.breaks?.map((bItem) => ({
              start_time: convertTo24HourFormat(bItem?.start_time),
              end_time: convertTo24HourFormat(bItem?.end_time),
            })) || [{ start_time: '', end_time: '' }],
            isShow: true,
          };
        }
        return tab;
      });
    }
    return tabData;
  });

  const handleShiftChange = (tabIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].shift[field] = value;
    setTabDetails(updatedDetails);
  };

  const handleSelect = (tabIndex, name, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex][name] = value?.value;
    setTabDetails(updatedDetails);
  };

  const handleBreakChange = (tabIndex, breakIndex, field, value) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks[breakIndex][field] = value;
    setTabDetails(updatedDetails);
  };

  const handleAddBreak = (tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].breaks.push({
      start_time: '',
      end_time: '',
    });
    setTabDetails(updatedDetails);
  };

  const handleCreateAppointments = (tabValue, tabIndex) => {
    const updatedDetails = [...tabDetails];
    updatedDetails[tabIndex].isShow = true;
    setTabDetails(updatedDetails);
  };

  const handleSaveDetails = (tabValue) => {
    const external = {
      run: true,
      handleFunction: (res) => {
        handleSuccess(res);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    const indicesToSave = tabDetails.findIndex(
      (tab) => tab.label === tabValue.label
    );
    console.log(indicesToSave, tabDetails[indicesToSave], '1212');

    if (tabDetails?.[indicesToSave]?.id) {
      let body = {
        is_week_off: tabDetails[indicesToSave]?.isHoliday,
        day_of_week_id: tabDetails[indicesToSave]?.day_of_week?.day_of_week_id,
      };
      if (!tabDetails[indicesToSave]?.isHoliday) {
        body.start_time = tabDetails?.[indicesToSave]?.shift?.start;
        body.end_time = tabDetails?.[indicesToSave]?.shift?.end;
        body.appointment_length =
          tabDetails?.[indicesToSave]?.appointment_length;
        body.buffer_length = tabDetails?.[indicesToSave]?.buffer_length;
        body.breaks = tabDetails?.[indicesToSave]?.breaks;
      }

      dispatch(
        updateAvailPost(body, external, tabDetails?.[indicesToSave]?.id)
      );
    } else {
      let body = {
        is_week_off: tabDetails[indicesToSave]?.isHoliday,
        day_of_week_id: tabDetails[indicesToSave]?.weekId,
      };
      if (!tabDetails[indicesToSave]?.isHoliday) {
        body.start_time = tabDetails?.[indicesToSave]?.shift?.start;
        body.end_time = tabDetails?.[indicesToSave]?.shift?.end;
        body.appointment_length =
          tabDetails?.[indicesToSave]?.appointment_length;
        body.buffer_length = tabDetails?.[indicesToSave]?.buffer_length;
        body.breaks = tabDetails?.[indicesToSave]?.breaks;
      }
      dispatch(createAvailPost(body, external));
    }
  };

  const handleSuccess = (res) => {
    setConf({
      msg: res?.message,
      variant: 'success',
    });
    setTimeout(() => {
      // navigate(providerAdminAppointments(locale));
      setEditAvailability(false);
      handleAvailablityData();
    }, 1000);
  };

  const tabShiftDetails = (tabValue, tabIndex) => {
    console.log(tabValue, tabIndex, '1212');
    const currentTabDetails = tabDetails[tabIndex];
    const isHolidayDisabled = currentTabDetails.isHoliday ? 'opacity-50' : '';

    return (
      <>
        {currentTabDetails?.isShow ? (
          <>
            <div className="mt-3">
              <CheckBoxInput
                label={<FormattedMessage id="Mark as Holiday" />}
                size="14"
                fontWeight="500"
                onChange={(e) => {
                  const updatedDetails = [...tabDetails];
                  updatedDetails[tabIndex].isHoliday = e.target.checked;
                  setTabDetails(updatedDetails);
                }}
                checked={currentTabDetails.isHoliday}
              />
            </div>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 family_outfit_semiBold_600 ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Shift Details" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              <Col md={12} lg={6} className="mt-3 p-0">
                <LabelWithInput
                  label="Start Time"
                  type="time"
                  value={currentTabDetails.shift.start}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'start', e.target.value)
                  }
                />
              </Col>

              <Col md={12} lg={6} className="mt-3 size-padding-schedule">
                <LabelWithInput
                  label="End Time"
                  type="time"
                  value={currentTabDetails.shift.end}
                  disabled={currentTabDetails.isHoliday}
                  onChange={(e) =>
                    handleShiftChange(tabIndex, 'end', e.target.value)
                  }
                />
              </Col>
              <Col md={6} lg={6} className="mt-3 p-0">
                <p className=" p-0 m-0 fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                  Appointment length
                </p>
                <SelectInput
                  placeholder={intl.formatMessage({
                    id: 'Appointment length',
                  })}
                  name={'appointment_length'}
                  value={
                    currentTabDetails?.appointment_length !== ''
                      ? `${currentTabDetails?.appointment_length} minutes`
                      : currentTabDetails?.appointment_length
                  }
                  data={[
                    { name: '15 minutes', value: '15' },
                    { name: '30 minutes', value: '30' },
                    { name: '45 minutes', value: '45' },
                  ]}
                  onChange={(e) =>
                    handleSelect(tabIndex, 'appointment_length', e.target.value)
                  }
                  disabled={currentTabDetails?.isHoliday}
                />
              </Col>
              <Col md={6} lg={6} className="mt-3 size-padding-schedule">
                <p className=" p-0 m-0 fs-14 lh-176 fc-292D32 family_outfit_regular_400">
                  Buffer length
                </p>
                <SelectInput
                  placeholder={intl.formatMessage({
                    id: 'Buffer length',
                  })}
                  name={'buffer_length'}
                  value={
                    currentTabDetails?.buffer_length !== ''
                      ? ` ${currentTabDetails?.buffer_length} minutes`
                      : currentTabDetails?.buffer_length
                  }
                  data={[
                    { name: '15 minutes', value: '15' },
                    { name: '30 minutes', value: '30' },
                    { name: '45 minutes', value: '45' },
                  ]}
                  onChange={(e) =>
                    handleSelect(tabIndex, 'buffer_length', e.target.value)
                  }
                  disabled={currentTabDetails?.isHoliday}
                />
              </Col>
            </Row>

            <h3
              className={`p-0 m-0 mt-4 fs-16 fc-292D32 family_outfit_semiBold_600 ${isHolidayDisabled}`}
            >
              <FormattedMessage id="Break(s)" />
            </h3>

            <Row className={`m-0 p-0 ${isHolidayDisabled}`}>
              {currentTabDetails.breaks.map((breakTime, index) => (
                <>
                  <h3 className="p-0 m-0 mt-4 fs-14 fc-292D32 ff-Akkurat ">
                    <FormattedMessage id={`Break ${index + 1}`} />
                  </h3>

                  <Col md={12} lg={6} className="mt-3 p-0">
                    <LabelWithInput
                      label={`Start Time`}
                      type="time"
                      format="h:mm a"
                      value={breakTime.start_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'start_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>

                  <Col md={12} lg={6} className="mt-3 size-padding-schedule">
                    <LabelWithInput
                      label={`End Time`}
                      type="time"
                      format="h:mm a"
                      value={breakTime.end_time}
                      disabled={currentTabDetails.isHoliday}
                      onChange={(e) =>
                        handleBreakChange(
                          tabIndex,
                          index,
                          'end_time',
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </>
              ))}
            </Row>

            <Row className={`m-0 p-0 mt-3 ${isHolidayDisabled}`}>
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#C56616"
                fontSize="14px"
                fontWeight="500"
                padding="12px 25px"
                height="40px"
                borderRadius={'8px'}
                bgColor="transparent"
                brColor="#EFE3D7"
                fontFamily="outfit_medium"
                disabled={currentTabDetails?.isHoliday}
                onClick={() => handleAddBreak(tabIndex)}
              >
                <img
                  src={AddIcon}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add break" />
              </ReusableButton>
            </Row>

            <Row className="m-0 p-0 mt-4 flex text-end items-center justify-end">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center justify-end p-0"
              >
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#3C3C3C"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 50px"
                  height="44px"
                  bgColor="transparent"
                  brColor="#EFE3D7"
                  fontFamily="outfit_medium"
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 50px"
                  height="44px"
                  bgColor="#375241"
                  brColor="#375241"
                  fontFamily="outfit_medium"
                  onClick={() => handleSaveDetails(tabValue)}
                >
                  <FormattedMessage id="Save" />
                </ReusableButton>
              </Stack>
            </Row>
          </>
        ) : (
          <>
            <div className="flex-col items-center justify-center text-center mt-5 pt-5 mb-5 pb-5">
              <div className="mt-5 pt-5">
                <p className="p-0 m-0 fs-16 fc-292D32 family_outfit_medium_500">
                  <FormattedMessage id="Show your availability to the customer" />
                </p>
              </div>
              <div className="mt-4 mb-5 pb-5">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#C56616"
                  fontSize="14px"
                  fontWeight="500"
                  padding="12px 25px"
                  height="40px"
                  bgColor="transparent"
                  brColor="#EFE3D7"
                  fontFamily="outfit_medium"
                  onClick={() => handleCreateAppointments(tabValue, tabIndex)}
                >
                  <img
                    src={AddIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Create New" />
                </ReusableButton>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  ////////////TabShiftDetails/////////////////////////

  return (
    <Row className="bc-FEF9F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left border-l bColor-EFE3D7'
            : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
        }
      >
        <Row className="">
          <Col className="p-0">
            <h2 className="fc-292D32 fs-32 family_outfit_semiBold_600 pb-16 m-0">
              <FormattedMessage id="Schedule Today" />
            </h2>
          </Col>
        </Row>

        <Row className="g-4">
          <Col md={6} lg={4} xl={3} className="p-0">
            <div className="calendar-container">
              <Calendar
                next2Label={null}
                prev2Label={null}
                tileClassName={highlightWeekends}
                formatShortWeekday={(locale, date) => date.toString().charAt(0)}
                onChange={handleDateChange}
                value={selectedDate}
                tileDisabled={disablePastDates}
                navigationLabel={({ date, label }) => (
                  <button
                    onClick={handleNavigationClick}
                    style={{
                      minWidth: '44px',
                      background: 'none',
                      cursor: 'not-allowed',
                    }}
                    disabled
                  >
                    {label}
                  </button>
                )}
              />
            </div>
          </Col>
          <Col xl={8} className="size-padding-large">
            {!editAvailability ? (
              <div className="w-100">
                <div className="flex justify-between">
                  <h2 className="p-0 m-0 fs-24 fw-400 fc-292D32 ff-Akkurat">
                    {moment(selectedDate).format('MMM DD, YYYY')}
                  </h2>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={() => handleAvailablity()}
                  >
                    <img
                      src={EditIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-C56616 family_outfit_medium_500 ">
                      <FormattedMessage id="Add/Edit Availability" />
                    </p>
                  </Stack>
                </div>
                <div className="big-calendar-container mt-3">
                  <BigCalendar
                    localizer={localizer}
                    events={formattedEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 'auto' }}
                    views={['day']}
                    defaultView="day"
                    date={selectedDate}
                    toolbar={false}
                    dayLayoutAlgorithm="no-overlap"
                    dayPropGetter={dayPropGetter}
                    eventPropGetter={(event) => {
                      if (selectedEventId && event.id === selectedEventId) {
                        return {
                          style: {
                            border: '1px solid #FEF9F6',
                            backgroundColor: '#FEF9F6',
                            outline: 'none',
                          },
                        };
                      }
                      return {
                        style: {
                          border: '1px solid #FEF9F6',
                          outline: 'none',
                        },
                      };
                    }}
                    onSelectEvent={handleSelectEvent}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="w-100 border-1 bColor-EFE3D7 bc-FFFFFF p-4">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={ArrowLeftIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      onClick={() => setEditAvailability(false)}
                      className="cursor-pointer"
                    />
                    <p className="p-0 m-0 fs-20 fc-292D32 family_outfit_medium_500 ">
                      <FormattedMessage id="Set Availability" />
                    </p>
                  </Stack>

                  <div className="mt-3">
                    <CustomTabs
                      tabs={tabData}
                      value={tab}
                      currentTab={(val) => handleTabChange(val)}
                      tabStyles={{
                        fontSize: '18px',
                        color: '#998A7B',
                        selectedColor: '#C56616',
                      }}
                      indicatorColor="#C56616"
                    />
                  </div>

                  {tabData.map((k, i) => tab === i && tabShiftDetails(k, i))}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DoctorSchedule;
