import {
  GET_PRACTICE,
  GET_PRACTICE_SUCCESS,
  GET_PRACTICE_FAILURE,
} from 'store/registerPractice/registePracticeAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const getPracticeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRACTICE:
      return { ...state, loading: true };
    case GET_PRACTICE_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_PRACTICE_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default getPracticeReducer;
