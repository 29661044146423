import axios from 'axios';
import { putMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const UPADATE_AVAVILABLITY = 'UPADATE_AVAVILABLITY';
export const UPADATE_AVAVILABLITY_SUCCESS = 'UPADATE_AVAVILABLITY_SUCCESS';
export const UPADATE_AVAVILABLITY_FAILURE = 'UPADATE_AVAVILABLITY_FAILURE';

// Action Creators
export const updateAvailRequest = () => ({
  type: UPADATE_AVAVILABLITY,
});

export const updateAvailSuccess = (users) => ({
  type: UPADATE_AVAVILABLITY_SUCCESS,
  payload: users,
});

export const updateAvailFailure = (error) => ({
  type: UPADATE_AVAVILABLITY_FAILURE,
  payload: error,
});

// Thunk Function

export const updateAvailPost = (body, external, id) => {
  return async (dispatch) => {
    dispatch(updateAvailRequest());
    try {
      const response = await putMethodWithToken(
        url.updatetAvailability + id + '/',
        body
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(updateAvailSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(updateAvailFailure(error));
    }
  };
};
