import CryptoJS from 'crypto-js';
const secretKey =
  'Empowering Small and Medium Enterprises with customized financing solutions';

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const EmailValidation = (value) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(value);
};

export const PasswordValidation = (value) => {
  const regex =
    /^(?=.*[0-9])(?=.*[!@#$%^&*£])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*£]{8,16}$/;
  return regex.test(value);
};

export const PhoneNumberValidation = (value) => {
  const regex = /^(\d{8}(\,\d{8}){0,2})$/;
  return regex.test(value);
};
