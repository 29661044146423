import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_CITY = 'GET_CITY';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';

// Action Creators
export const getCityRequest = () => ({
  type: GET_CITY,
});

export const getCitySuccess = (users) => ({
  type: GET_CITY_SUCCESS,
  payload: users,
});

export const getCityFailure = (error) => ({
  type: GET_CITY_FAILURE,
  payload: error,
});

// Thunk Function
export const getCity = (countryId = '', stateId = '', external) => {
  return async (dispatch) => {
    dispatch(getCityRequest());
    try {
      const response = await getMethod(
        url?.helperData + '?country_id=' + countryId + '&state_id=' + stateId
      );
      // dispatch(getCitySuccess(response?.data));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(getCitySuccess(response?.data));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getCityFailure(error?.message));
    }
  };
};
