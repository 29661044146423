import {
  REGISTER_ONE_REQUEST,
  REGISTER_ONE_SUCCESS,
  REGISTER_ONE_FAILURE,
} from 'store/registerStepOne/registerStepOneAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const registerOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_ONE_REQUEST:
      return { ...state, loading: true };
    case REGISTER_ONE_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case REGISTER_ONE_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default registerOneReducer;
