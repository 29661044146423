import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Stack, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import 'Pages/Doctor/DoctorDashboard/doctorDashboard.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AvtarFrameImage from 'assets/images/Dashboard/AvtarFrame.png';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import ArrowRightIcon from 'assets/images/Dashboard/arrow-right-detail.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import NextIcon from 'assets/images/Dashboard/nextIcon.png';
import PrevIcon from 'assets/images/Dashboard/prevIcon.png';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import ClockIcon from 'assets/images/Dashboard/clock.svg';
import CalerderIcon from 'assets/images/Dashboard/calendar.svg';
import { doctorAppointmentsUpcomingDetails } from 'routes/AppUrls';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { getAppointment } from 'store/getAppointment/getAppointmentAction';
import Loader from 'components/commom/Loader';
import moment from 'moment';
import EmptyScreen from 'assets/images/Dashboard/empty.svg';
import { getAppointmentUpcoming } from 'store/getAppointment/getUpcomingAppointment/getUpcomingAppointmentAction';
import ReusableButton from 'components/commom/Button';
import { ClearTokenRedirectLogin } from 'utils/general';

const UpcomingList = ({ tab }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const { locale } = useContext(LocaleContext);
  const todayDay = new Date();
  const [appointmentUpcomeList, setAppointmentUpcomeList] = useState([]);
  const [appointmentTodayList, setAppointmentTodayList] = useState([]);

  const loading = useSelector((state) => state?.getAppointmentReducer?.loading);

  useEffect(() => {
    getAppointmentList();
    getAppointmentUpcomingList();
  }, []);

  const getAppointmentUpcomingList = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentTodayList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(
      getAppointmentUpcoming(external, moment(todayDay).format('YYYY-MM-DD'))
    );
  };

  const getAppointmentList = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentUpcomeList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        // ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getAppointment(external, tab));
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        onClick={onClick}
      >
        <img src={NextIcon} alt="icon" />
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
        }}
        onClick={onClick}
      >
        <img src={PrevIcon} alt="icon" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      {appointmentTodayList && appointmentTodayList.length > 0 && (
        <>
          <div className="pt-32 fc-292D32 fs-20 family_outfit_semiBold_600">
            <FormattedMessage id="Appointments Today" />
          </div>
          <Row className="m-0 pt-8 flex items-start">
            <Col sm={12} md={12} className="p-0 m-0">
              <Slider
                {...settings}
                className={`${
                  locale === 'en'
                    ? 'sliderDetailsEn single-item-slider'
                    : 'sliderDetailsAr single-item-slider'
                }`}
              >
                {appointmentTodayList.map((item, index) => (
                  <div key={index} className="pr-3">
                    <Card className="p-12 subscription-card">
                      <Card.Body className="p-0">
                        <Card.Title>
                          <div className="flex m-0 p-0 justify-between">
                            <div className="fs-18 fc-292D32 lh-2268 family_outfit_semiBold_600 p-0 m-0">
                              {item?.patient?.full_name}
                            </div>
                            <Image
                              src={
                                item?.consultation_type === 'Hospital Visit'
                                  ? HospitalIcon
                                  : VideoCallCameraIcon
                              }
                              alt={'image'}
                            />
                          </div>
                        </Card.Title>
                        <Card.Text>
                          <Stack
                            direction="horizontal"
                            gap={5}
                            className="items-center pt-04"
                          >
                            <Stack
                              direction="horizontal"
                              gap={2}
                              className="items-center"
                            >
                              <img src={CalerderIcon} alt="icon" />
                              <div className="fs-16 fc-292D32 lh-201  family_outfit_medium_500">
                                {moment(item?.appointment_date_time).format(
                                  'MMMM D, YYYY'
                                )}
                              </div>
                            </Stack>
                            <Stack
                              direction="horizontal"
                              gap={2}
                              className="items-center"
                            >
                              <img src={ClockIcon} alt="icon" />
                              <div className="fs-16 fc-292D32 lh-201  family_outfit_medium_500">
                                {moment(item?.appointment_date_time).format(
                                  'hh:mmA'
                                )}
                              </div>
                            </Stack>
                          </Stack>
                          <div className="pt-20 fs-14 lh-21 fc-998A7B family_outfit_regular_400">
                            Booking ID:
                            <span className="fc-3C3C3C">
                              {item?.booking_id}
                            </span>
                          </div>

                          <Row className="pt-16">
                            <Col lg={7}>
                              <ReusableButton
                                fullWidth={true}
                                variant="outlined"
                                color="#FFFFFF"
                                fontSize="14px"
                                fontWeight="500"
                                padding="12px 35px"
                                height="44px"
                                bgColor=" #864629"
                                brColor="transparent"
                                fontFamily="outfit_medium"
                              >
                                <FormattedMessage id="Start Consultation" />
                              </ReusableButton>
                            </Col>
                            <Col lg={5} className="py-0 pl-0 flex justify-end">
                              <Stack
                                direction="horizontal"
                                gap={2}
                                className="flex items-center"
                              >
                                <div
                                  className="fs-16 fc-C56616 family_outfit_medium_500 cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      doctorAppointmentsUpcomingDetails(
                                        locale,
                                        item?.id
                                      )
                                    )
                                  }
                                >
                                  <FormattedMessage id="View Details" />
                                </div>
                                <img
                                  src={ArrowRightIcon}
                                  alt="icon"
                                  style={{ height: '16px', width: '16px' }}
                                  onClick={() =>
                                    navigate(
                                      doctorAppointmentsUpcomingDetails(
                                        locale,
                                        item?.id
                                      )
                                    )
                                  }
                                  className="cursor-pointer"
                                />
                              </Stack>
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </>
      )}

      {loading ? (
        <Loader />
      ) : appointmentUpcomeList && appointmentUpcomeList?.length > 0 ? (
        <>
          <div className="pt-28 fc-292D32 fs-20 family_outfit_semiBold_600">
            <FormattedMessage id="Upcoming Appointments" />
          </div>
          <Row className="listCustomHeader m-0 pt-12 flex items-center">
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Patient & ID" />
              </p>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Contact Info" />
              </p>
            </Col>

            <Col lg={1} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Modality" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Booking ID" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Date & Time" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Actions" />
              </p>
            </Col>
          </Row>

          <Row className="bc-FFFFFF m-0 p-0">
            {appointmentUpcomeList.map((item, index) => {
              return (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="flex items-center"
                    >
                      <img
                        src={
                          item?.patient?.profile_pic !== ''
                            ? item?.patient?.profile_pic
                            : DefaultImage
                        }
                        alt={'icon'}
                        style={{
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                        }}
                      />
                      <div>
                        <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_semiBold_600">
                          {item?.patient?.full_name}
                        </div>
                        <div className="fs-12 fc-202224 family_outfit_regular_400 pt-04 lh-1512">
                          {item?.patient?.patient_id}
                        </div>
                      </div>
                    </Stack>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center "
                    >
                      <img src={MailIcon} alt="icon" />
                      <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_regular_400 break-all ...">
                        {item?.patient?.email}
                      </div>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center pt-8"
                    >
                      <img src={CallIcon} alt="icon" />
                      <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                        {`${item?.patient?.country_code} ${item?.patient?.contact_number}`}
                      </div>
                    </Stack>
                  </Col>

                  <Col lg={1} md={6} sm={12} className="text-center">
                    <div className="flex text-center">
                      <img
                        src={
                          item?.consultation_type === 'Hospital Visit'
                            ? HospitalIcon
                            : VideoCallCameraIcon
                        }
                        alt={'image'}
                      />
                    </div>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                      {item?.booking_id}
                    </div>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                      {`${moment(item?.appointment_date_time).format('DD MMM YYYY')}, ${moment(
                        item?.appointment_date_time
                      ).format('hh:mmA')}`}
                    </div>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    {/* <Stack
                      direction="horizontal"
                      gap={5}
                      className="items-center cursor-pointer"
                    >
                      <div className="p-0 m-0 fs-14 lh-14 fc-C56616 family_outfit_medium_500 ">
                        <FormattedMessage id="Reschedule" />
                      </div>

                      <div className="p-0 m-0 fs-14 lh-14 fc-998A7B family_outfit_medium_500 ">
                        <FormattedMessage id="Cancel" />
                      </div>
                    </Stack> */}
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="flex items-center"
                    >
                      <div
                        className="fs-16 fc-C56616 family_outfit_medium_500 cursor-pointer"
                        onClick={() =>
                          navigate(
                            doctorAppointmentsUpcomingDetails(locale, item?.id)
                          )
                        }
                      >
                        <FormattedMessage id="View Details" />
                      </div>
                      <img
                        src={ArrowRightIcon}
                        alt="icon"
                        style={{ height: '16px', width: '16px' }}
                        onClick={() =>
                          navigate(
                            doctorAppointmentsUpcomingDetails(locale, item?.id)
                          )
                        }
                        className="cursor-pointer"
                      />
                    </Stack>
                  </Col>
                </Row>
              );
            })}
          </Row>
        </>
      ) : (
        <div className="flex justify-center pt-80">
          <div>
            <Image src={EmptyScreen} alt="icon" />
            <div className="fs-20 fc-3C3C3C lh-2 family_outfit_medium_500 text-center	pt-30">
              Nothing here yet
            </div>
            <div className="fs-16 fc-998A7B lh-16 family_outfit_regular_400  text-center pt-8">
              No appointments have been booked
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingList;
