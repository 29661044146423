import axios from 'axios';
import { postMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_VEDIO_TOKEN = 'GET_VEDIO_TOKEN';
export const GET_VEDIO_TOKEN_SUCCESS = 'GET_VEDIO_TOKEN_SUCCESS';
export const GET_VEDIO_TOKEN_FAILURE = 'GET_VEDIO_TOKEN_FAILURE';

// Action Creators
export const geVedioRequest = () => ({
  type: GET_VEDIO_TOKEN,
});

export const getVedioSuccess = (users) => ({
  type: GET_VEDIO_TOKEN_SUCCESS,
  payload: users,
});

export const getVedioFailure = (error) => ({
  type: GET_VEDIO_TOKEN_FAILURE,
  payload: error,
});

// Thunk Function
export const getVedioToken = (external, body) => {
  return async (dispatch) => {
    dispatch(geVedioRequest());
    try {
      const response = await postMethodWithToken(url?.vedioCallToken, body);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getVedioSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getVedioFailure(error?.message));
    }
  };
};
