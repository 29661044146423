import {
  GET_QUALIFICATION,
  GET_QUALIFICATION_SUCCESS,
  GET_QUALIFICATION_FAILURE,
} from 'store/registerQualification/registerQualificationAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const registerQualificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUALIFICATION:
      return { ...state, loading: true };
    case GET_QUALIFICATION_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_QUALIFICATION_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default registerQualificationReducer;
