import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const REGISTER_USERS_REQUEST = 'REGISTER_USERS_REQUEST';
export const REGISTER_USERS_SUCCESS = 'REGISTER_USERS_SUCCESS';
export const REGISTER_USERS_FAILURE = 'REGISTER_USERS_FAILURE';

// Action Creators
export const registerUsersRequest = () => ({
  type: REGISTER_USERS_REQUEST,
});

export const registerUsersSuccess = (users) => ({
  type: REGISTER_USERS_SUCCESS,
  payload: users,
});

export const registerUsersFailure = (error) => ({
  type: REGISTER_USERS_FAILURE,
  payload: error,
});

// Thunk Function
export const registerUsers = (countryId = '', stateId = '') => {
  return async (dispatch) => {
    dispatch(registerUsersRequest());
    try {
      const response = await getMethod(
        url?.helperData + '?country_id=' + countryId + '&state_id=' + stateId
      );
      dispatch(registerUsersSuccess(response?.data));
    } catch (error) {
      dispatch(registerUsersFailure(error?.message));
    }
  };
};

export const registerPost = (body, external) => {
  return async (dispatch) => {
    dispatch(registerUsersRequest());
    try {
      const response = await postMethod(url.postRegisterStart, body);
      dispatch(registerUsersSuccess(response));
      // handleFunction();
    } catch (error) {
      dispatch(registerUsersFailure(error));
    }
  };
};
