import ToastNotification from 'components/commom/Toast';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LocaleContext } from 'routes/Router';
import { getPatient } from 'store/getPatient/getPatientAction';
import { ClearTokenRedirectLogin } from 'utils/general';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import ArrowRightIcon from 'assets/images/Dashboard/arrow-right-detail.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import Loader from 'components/commom/Loader';
import moment from 'moment';
import EmptyScreen from 'assets/images/Dashboard/empty.svg';
import { doctorPatientDetails } from 'routes/AppUrls';

const PatientList = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { locale } = useContext(LocaleContext);
  const [conf, setConf] = ToastNotification();
  const [patientList, setPatientList] = useState([]);

  const loading = useSelector((state) => state?.getPatientReducer?.loading);

  useEffect(() => {
    getPatientList();
  }, []);

  const getPatientList = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setPatientList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getPatient(external));
  };
  return (
    <div>
      <Row className="bc-FEF9F6 m-0 p-0">
        <Col
          className={
            locale === 'en'
              ? 'commonEnContainer lg:ml-left border-l bColor-EFE3D7'
              : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
          }
        >
          <div className="fc-292D32 fs-32 family_outfit_semiBold_600  m-0">
            <FormattedMessage id="Patients" />
          </div>
          <div>
            {loading ? (
              <Loader />
            ) : patientList && patientList?.length > 0 ? (
              <>
                <Row className="mt-4 listCustomHeader m-0 p-0 flex items-center">
                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Patient & ID" />
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Contact Info" />
                    </p>
                  </Col>

                  {/* <Col lg={1} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Modality" />
                    </p>
                  </Col> */}

                  {/* <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Booking ID" />
                    </p>
                  </Col> */}

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Joined On" />
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                      <FormattedMessage id="Actions" />
                    </p>
                  </Col>
                </Row>

                <Row className="bc-FFFFFF m-0 p-0">
                  {patientList.map((item, index) => (
                    <Row className="m-0 p-0 flex items-center listAppointment">
                      <Col lg={3} md={6} sm={12}>
                        <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_semiBold_600">
                          {item?.patient_name}
                        </div>
                        <div className="fs-12 fc-202224 family_outfit_regular_400 pt-04 lh-1512">
                          {item?.patient_id}
                        </div>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center "
                        >
                          <img src={MailIcon} alt="icon" />
                          <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_regular_400 break-all ...">
                            {item?.patient_email}
                          </div>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center pt-8"
                        >
                          <img src={CallIcon} alt="icon" />
                          <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                            {item?.patient_phone}
                          </div>
                        </Stack>
                      </Col>

                      {/* <Col lg={1} md={6} sm={12} className="text-center">
                        <div className="flex text-center">
                          <img
                            src={
                              item?.consultation_type === 'Hospital Visit'
                                ? HospitalIcon
                                : VideoCallCameraIcon
                            }
                            alt={'image'}
                          />
                        </div>
                      </Col> */}

                      {/* <Col lg={2} md={6} sm={12}>
                        <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                          {item?.booking_id}
                        </div>
                      </Col> */}

                      <Col lg={3} md={6} sm={12}>
                        <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                          {item?.consulted_on &&
                            moment(item?.consulted_on).format('DD MMM YYYY')}
                        </div>
                      </Col>

                      <Col lg={3} md={6} sm={12}>
                        <Stack
                          direction="horizontal"
                          gap={1}
                          className="items-center"
                        >
                          <div
                            className="p-0 m-0 fs-14 lh-14 fc-C56616 family_outfit_medium_500 underline underline-offset-4 cursor-pointer"
                            onClick={() =>
                              navigate(doctorPatientDetails(locale, item?.id))
                            }
                          >
                            <FormattedMessage id="View Details" />
                          </div>
                          <img
                            src={ArrowRightIcon}
                            alt="icon"
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(doctorPatientDetails(locale, item?.id))
                            }
                          />
                        </Stack>
                      </Col>
                    </Row>
                  ))}
                </Row>
              </>
            ) : (
              <div className="flex justify-center pt-120">
                <div>
                  <Image src={EmptyScreen} alt="icon" />
                  <div className="fs-20 fc-3C3C3C lh-2 family_outfit_medium_500 text-center	pt-30">
                    Nothing here yet
                  </div>
                  <div className="fs-16 fc-998A7B lh-16 family_outfit_regular_400  text-center pt-8">
                    No patient list
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatientList;
