import { Tab, Tabs } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';

// const WrapperDiv = styled.div`
//   .MuiTabs-flexContainer {
//     background: transparent !important;
//     width: 100%;
//     border: 1px solid #efe3d7 !important;
//     height: 48px !important; /* Height for the tab container */
//   }
//   .Mui-selected {
//     border: none;
//     color: #ffffff !important;
//     background: #864629;
//     margin: 4px !important;
//     padding: 4px !important;
//     height: 30px !important; /* Height for the selected tab */
//     display: flex; /* Ensure alignment */
//     align-items: center; /* Center align text vertically */
//   }
//   .MuiButtonBase-root {
//     width: 50%;
//     text-transform: capitalize;
//     font-size: 16px !important;
//     font-family: outfit_medium !important;
//     height: 30px !important; /* Height for individual tabs */
//   }
//   .MuiTabs-indicator {
//     display: none;
//   }
// `;

const WrapperDivSwitch = styled.div`
  .MuiTabs-flexContainer {
    background: transparent !important;
    border: 1px solid #efe3d7 !important;
    width: 100%;
    height: 48px; /* Set the height for the complete tab */
  }
  .MuiButtonBase-root.Mui-selected {
    border: none;
    color: #ffffff !important;
    background: #864629;
    margin: 3px;
    height: 40px !important; /* Force the height for the selected tab */
    min-height: 40px !important;
    display: flex;
    align-items: center; /* Ensure content is vertically centered */
    justify-content: center; /* Ensure content is horizontally centered */
  }
  .MuiButtonBase-root {
    width: 50%;
    text-transform: capitalize;
    font-size: 16px !important;
    font-family: outfit_medium !important;
    height: 48px; /* Match the height of the complete tab */
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

const WrapperLabel = styled.div`
  font-size: 14px;
  line-height: 17.64px;
  font-family: outfit_medium;
  transform: none;
  color: #292d32;
  width: 100%;
  margin-top: 6px;
`;
export default function TabSwitch(props) {
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    if (props?.selectedTabValue && props?.tabs?.length > 0) {
      const selectedIndex = props.tabs.findIndex(
        (tab) => tab?.id === props.selectedTabValue
      );
      if (selectedIndex !== -1) {
        setValue(selectedIndex);
      }
    }
  }, [props.selectedTabValue, props.tabs]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = props?.tabs?.[newValue];
    props.tranferData(newValue, selectedTab);
  };

  return (
    <>
      <WrapperLabel>{props?.label}</WrapperLabel>
      <WrapperDivSwitch {...props}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons={false}
        >
          {props?.tabs?.length > 0 &&
            props?.tabs.map((item, index) => (
              <Tab key={item?.id} label={item?.name} />
            ))}
        </Tabs>
      </WrapperDivSwitch>
    </>
  );
}
