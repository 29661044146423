import axios from 'axios';
import { putMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_REQUEST_SUCCESS = 'UPDATE_STATUS_REQUEST_SUCCESS';
export const UPDATE_STATUS_REQUEST_FAILURE = 'UPDATE_STATUS_REQUEST_FAILURE';

// Action Creators
export const updateStatusRequest = () => ({
  type: UPDATE_STATUS_REQUEST,
});

export const updateStatusSuccess = (users) => ({
  type: UPDATE_STATUS_REQUEST_SUCCESS,
  payload: users,
});

export const updateStatusFailure = (error) => ({
  type: UPDATE_STATUS_REQUEST_FAILURE,
  payload: error,
});

// Thunk Function
export const updateRequestDetail = (external, body) => {
  return async (dispatch) => {
    dispatch(updateStatusRequest());
    try {
      const response = await putMethodWithToken(
        url?.appointmentUpdateStatus,
        body
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(updateStatusSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(updateStatusFailure(error?.message));
    }
  };
};
