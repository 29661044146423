import axios from 'axios';
import { postMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const DOCTOR_LOGOUT = 'DOCTOR_LOGOUT';
export const DOCTOR_LOGOUT_SUCCESS = 'DOCTOR_LOGOUT_SUCCESS';
export const DOCTOR_LOGOUT_FAILURE = 'DOCTOR_LOGOUT_FAILURE';

// Action Creators
export const getDoctorLogout = () => ({
  type: DOCTOR_LOGOUT,
});

export const getDoctorLogoutSuccess = (users) => ({
  type: DOCTOR_LOGOUT_SUCCESS,
  payload: users,
});

export const getDoctorLogoutFailure = (error) => ({
  type: DOCTOR_LOGOUT_FAILURE,
  payload: error,
});

// Thunk Function
export const doctorLogout = (body, external) => {
  return async (dispatch) => {
    dispatch(getDoctorLogout());
    try {
      const response = await postMethodWithToken(url.doctorLogout, body);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getDoctorLogoutSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getDoctorLogoutFailure(error?.message));
    }
  };
};
