import {
  DOCTOR_LOGOUT,
  DOCTOR_LOGOUT_SUCCESS,
  DOCTOR_LOGOUT_FAILURE,
} from 'store/doctorLogout/doctorLogoutAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const doctorLogoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCTOR_LOGOUT:
      return { ...state, loading: true };
    case DOCTOR_LOGOUT_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case DOCTOR_LOGOUT_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default doctorLogoutReducer;
