import {
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
} from 'store/login/LoginAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USERS_REQUEST:
      return { ...state, loading: true };
    case LOGIN_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case LOGIN_USERS_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default loginReducer;
