import {
  GET_PATIENT_LIST,
  GET_PATIENT_LIST_SUCCESS,
  GET_PATIENT_LIST_FAILURE,
} from 'store/getPatient/getPatientAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const getPatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_LIST:
      return { ...state, loading: true };
    case GET_PATIENT_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_PATIENT_LIST_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default getPatientReducer;
