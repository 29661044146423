import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Stack, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import 'Pages/Doctor/DoctorDashboard/doctorDashboard.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import ArrowRightIcon from 'assets/images/Dashboard/arrow-right-detail.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import { doctorAppointmentsRequestDetails } from 'routes/AppUrls';
import { getAppointment } from 'store/getAppointment/getAppointmentAction';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import Loader from 'components/commom/Loader';
import moment from 'moment';
import EmptyScreen from 'assets/images/Dashboard/empty.svg';
import { ClearTokenRedirectLogin } from 'utils/general';

const RequestList = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const [tab, setTab] = useState(1);
  const { locale } = useContext(LocaleContext);
  const [appointmentRequestList, setAppointmentRequestList] = useState([]);

  const loading = useSelector((state) => state?.getAppointmentReducer?.loading);

  useEffect(() => {
    getAppointmentList();
  }, []);

  const getAppointmentList = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentRequestList(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getAppointment(external, tab));
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : appointmentRequestList && appointmentRequestList.length > 0 ? (
        <>
          <Row className="mt-4 listCustomHeader m-0 p-0 flex items-center">
            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Patient & ID" />
              </p>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Contact Info" />
              </p>
            </Col>

            <Col lg={1} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Modality" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Booking ID" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Date & Time" />
              </p>
            </Col>

            <Col lg={2} md={6} sm={12}>
              <p className="p-0 m-0 fs-14 fc-998A7B family_outfit_semiBold_600">
                <FormattedMessage id="Actions" />
              </p>
            </Col>
          </Row>

          <Row className="bc-FFFFFF m-0 p-0">
            {appointmentRequestList.map((item, index) => (
              <Row className="m-0 p-0 flex items-center listAppointment">
                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex items-center"
                  >
                    <img
                      src={
                        item?.patient?.profile_pic !== ''
                          ? item?.patient?.profile_pic
                          : DefaultImage
                      }
                      alt={'icon'}
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%',
                      }}
                    />
                    <div>
                      <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_semiBold_600">
                        {item?.patient?.full_name}
                      </div>
                      <div className="fs-12 fc-202224 family_outfit_regular_400 pt-04 lh-1512">
                        {item?.patient?.patient_id}
                      </div>
                    </div>
                  </Stack>
                </Col>

                <Col lg={3} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center "
                  >
                    <img src={MailIcon} alt="icon" />
                    <div className="p-0 m-0 fs-14 fc-202224 lh-176 family_outfit_regular_400 break-all ...">
                      {item?.patient?.email}
                    </div>
                  </Stack>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center pt-8"
                  >
                    <img src={CallIcon} alt="icon" />
                    <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                      {`${item?.patient?.country_code} ${item?.patient?.contact_number}`}
                    </div>
                  </Stack>
                </Col>

                <Col lg={1} md={6} sm={12} className="text-center">
                  <div className="flex text-center">
                    <img
                      src={
                        item?.consultation_type === 'Hospital Visit'
                          ? HospitalIcon
                          : VideoCallCameraIcon
                      }
                      alt={'image'}
                    />
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                    {item?.booking_id}
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <div className="p-0 m-0 fs-14 lh-176 fc-202224 family_outfit_regular_400">
                    {`${moment(item?.appointment_date_time).format('DD MMM YYYY')}, ${moment(
                      item?.appointment_date_time
                    ).format('hh:mmA')}`}
                  </div>
                </Col>

                <Col lg={2} md={6} sm={12}>
                  <Stack
                    direction="horizontal"
                    gap={1}
                    className="items-center"
                  >
                    <div
                      className="p-0 m-0 fs-14 lh-14 fc-C56616 family_outfit_medium_500 underline underline-offset-4 cursor-pointer"
                      onClick={() =>
                        navigate(
                          doctorAppointmentsRequestDetails(locale, item?.id)
                        )
                      }
                    >
                      <FormattedMessage id="View Details" />
                    </div>
                    <img
                      src={ArrowRightIcon}
                      alt="icon"
                      onClick={() =>
                        navigate(
                          doctorAppointmentsRequestDetails(locale, item?.id)
                        )
                      }
                      className="cursor-pointer"
                    />
                  </Stack>
                </Col>
              </Row>
            ))}
          </Row>
        </>
      ) : (
        <div className="flex justify-center pt-120">
          <div>
            <Image src={EmptyScreen} alt="icon" />
            <div className="fs-20 fc-3C3C3C lh-2 family_outfit_medium_500 text-center	pt-30">
              Nothing here yet
            </div>
            <div className="fs-16 fc-998A7B lh-16 family_outfit_regular_400  text-center pt-8">
              No appointments have been booked
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestList;
