import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import 'Pages/Doctor/DoctorDashboard/doctorDashboard.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableTabs from 'components/commom/Tab';
import UpcomingList from './UpcomingList';
import PendingList from './PendingList';
import RequestList from './RequestList';

const DoctorAppointments = () => {
  const intl = useIntl();
  const [tab, setTab] = useState(1);
  const { locale } = useContext(LocaleContext);

  const tabData = [
    { label: intl.formatMessage({ id: 'Requests' }) },
    { label: intl.formatMessage({ id: 'Upcoming' }) },
    { label: intl.formatMessage({ id: 'Pending Diagnosis' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  return (
    <Row className="bc-FEF9F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left border-l bColor-EFE3D7'
            : 'commonArContainer lg:mr-right border-l bColor-EFE3D7'
        }
      >
        <h2 className="fc-292D32 fs-32 family_outfit_semiBold_600  m-0">
          <FormattedMessage id="Appointments" />
        </h2>

        <Row className="m-0 p-0 mt-3">
          <Col md={12} lg={8} className="p-0">
            <ReusableTabs
              tabs={tabData}
              defaultTab={tab}
              currentTab={(val) => handleTabChange(val)}
              background={'#864629'}
              color={'#FFFFFF'}
              tabColor={'#998A7B'}
              completeColor={'#FFFFFF'}
            />
          </Col>
        </Row>

        {tab === 1 && <RequestList tab={tab} />}
        {tab === 2 && <UpcomingList tab={tab} />}
        {tab === 3 && <PendingList tab={tab} />}

        {/* {tab === 3 && (
          <>
            <Row className="mt-4 listCustomHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Patient & ID" />
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Modality" />
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Booking ID" />
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Date & Time" />
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fc-202224 family_outfit_semiBold_600">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <div className="flex gap-2 items-center">
                      <img
                        src={AvtarFrameImage}
                        alt={'icon'}
                        style={{
                          height: '40px',
                          width: '40px',
                          borderRadius: '50%',
                        }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-14 fc-202224 family_outfit_medium_500">
                          {'Christine Brooks'}
                        </p>
                        <p className="p-0 m-0 fs-12 fc-202224 family_outfit_regular_400">
                          {'00001'}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center "
                    >
                      <img
                        src={mail}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_regular_400">
                        {'random123@mail'}
                      </p>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center mt-2"
                    >
                      <img
                        src={call}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-14 fc-202224 family_outfit_regular_400">
                        {'9874456321'}
                      </p>
                    </Stack>
                  </Col>

                  <Col lg={2} md={6} sm={12} className="text-center">
                    <div className="flex text-center">
                      <img
                        src={VideoCallCameraIcon}
                        alt={'image'}
                        style={{ height: '24px', width: '24px' }}
                      />
                    </div>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_regular_400">
                      {'1232654551'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-202224 family_outfit_regular_400">
                      {'23 Nov 2019'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center cursor-pointer"
                    >
                      <img
                        src={DocumentTextIcon}
                        alt="icon"
                        style={{ height: '20px', width: '20px' }}
                      />
                      <p className="p-0 m-0 fs-14 fc-C56616 family_outfit_medium_500 underline underline-offset-4">
                        <FormattedMessage id="Generate Report" />
                      </p>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )} */}
      </Col>
    </Row>
  );
};

export default DoctorAppointments;
