import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import 'components/sidebar/sidebar.scss';
import 'assets/styles/common.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { LocaleContext } from 'routes/Router';
import {
  login,
  doctorDashboard,
  doctorSchedule,
  doctorAppointments,
  doctorPatient,
} from 'routes/AppUrls';
import { FormattedMessage, useIntl } from 'react-intl';
import Dashboard from 'assets/images/sideBarIcons/dashboard.svg';
import DashboardSelect from 'assets/images/sideBarIcons/dashboard-select.svg';
import Schedule from 'assets/images/sideBarIcons/schedule.svg';
import ScheduleSelect from 'assets/images/sideBarIcons/schedule-select.svg';
import Appointments from 'assets/images/sideBarIcons/appointment.svg';
import AppointmentsSelect from 'assets/images/sideBarIcons/appointment-select.svg';
import Patient from 'assets/images/sideBarIcons/patient.svg';
import PatientSelect from 'assets/images/sideBarIcons/patient-select.svg';
import Profile from 'assets/images/sideBarIcons/profile.svg';
import ProfileSelect from 'assets/images/sideBarIcons/profile-select.svg';
import Subscription from 'assets/images/sideBarIcons/manage-subscription.svg';
import SubscriptionSelect from 'assets/images/sideBarIcons/manage-subscription-select.svg';
import Logout from 'assets/images/sideBarIcons/logout.svg';
import { ClearTokenRedirectLogin, getUserRole } from 'utils/general';
import MenuIcon from '@mui/icons-material/Menu';
import sidebarLogo from 'assets/images/sideBarIcons/side-logo.svg';
import { basicDetailsCallApi } from 'store/BasicDetail/BasicDetailAction';
import ReusableButton from 'components/commom/Button';
import CommonModal from 'components/commom/Modal';
import { doctorLogout } from 'store/doctorLogout/doctorLogoutAction';

const Sidebar = (props) => {
  const { window } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [conf, setConf] = ToastNotification();
  const userRole = getUserRole(); // Get the user's role
  const [isModal, setIsModal] = useState(false);

  const aIndex = useSelector((state) => state?.basicDetailsReducer);

  const [activeIndex, setActiveIndex] = useState(
    aIndex?.activeIndex >= 0 ? aIndex?.activeIndex : 0
  );
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
  const profileData = useSelector(
    (state) => state?.getProfileReducer?.payload?.data
  );
  const profileAdminData = useSelector(
    (state) => state?.adminProfileReducer?.payload?.data
  );

  const sidebarData = [
    {
      name: intl.formatMessage({ id: 'Dashboard' }),
      icon: Dashboard,
      selectedIcon: DashboardSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Schedule' }),
      icon: Schedule,
      selectedIcon: ScheduleSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Appointments' }),
      icon: Appointments,
      selectedIcon: AppointmentsSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'My Patients' }),
      icon: Patient,
      selectedIcon: PatientSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Profile' }),
      icon: Profile,
      selectedIcon: ProfileSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Manage Subscription' }),
      icon: Subscription,
      selectedIcon: SubscriptionSelect,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Logout' }),
      icon: Logout,
      selectedIcon: Logout,
      roles: ['DoctorAdmin', 'SuperAdmin'],
    },
  ];

  const handleSubmenuToggle = (text, index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
    setActiveIndex(null);
    // dispatch(
    //   basicDetailsCallApi(
    //     ACTIVE_INDEX,
    //     { activeIndex: index },
    //     { navigate: navigate }
    //   )
    // );
    dispatch(basicDetailsCallApi(index));
  };
  const handleLogOutModal = () => {
    setIsModal(true);
  };

  const handleLogOut = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        // LocalStorageManager.removeItem('token');
        ClearTokenRedirectLogin(navigate);
        // navigate(login('en'));
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {};
    dispatch(doctorLogout(body, external));
  };

  const handleSubmenuRedirect = (text, index) => {
    setActiveIndex(index);

    switch (text?.name) {
      case intl.formatMessage({ id: '' }):
        // navigate(parentStudentUserManagement(locale));
        break;
      case intl.formatMessage({ id: '' }):
        // navigate(teacherUserManagement(locale));
        break;
      case intl.formatMessage({ id: '' }):
        // navigate(internalTeacherUserManagement(locale));
        break;
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenuItemClick = (text, index) => {
    setOpenSubmenuIndex(null);
    if (text?.name === intl.formatMessage({ id: 'Logout' })) {
      handleLogOutModal();
      return; // Exit the function early to avoid unnecessary state updates
    }

    switch (text?.name) {
      case intl.formatMessage({ id: 'Dashboard' }):
        navigate(doctorDashboard(locale));
        break;
      case intl.formatMessage({ id: 'Schedule' }):
        navigate(doctorSchedule(locale));
        break;
      case intl.formatMessage({ id: 'Appointments' }):
        navigate(doctorAppointments(locale));
        break;
      case intl.formatMessage({ id: 'My Patients' }):
        navigate(doctorPatient(locale));
        break;
      case intl.formatMessage({ id: 'Profile' }):
        // navigate(superAdminCategory(locale));
        navigate(doctorPatient(locale));
        break;
      case intl.formatMessage({ id: 'Manage Subscription' }):
        // navigate(superAdminAppointments(locale));
        navigate(doctorPatient(locale));
        break;
      // case intl.formatMessage({ id: 'Logout' }):
      //   navigate(login(locale));
      //   break;
    }
    setActiveIndex(index);

    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
    // dispatch(
    //   basicDetailsCallApi(
    //     ACTIVE_INDEX,
    //     { activeIndex: index },
    //     { navigate: navigate }
    //   )
    // );
    dispatch(basicDetailsCallApi(index));
  };

  const filteredSidebarData = sidebarData.filter((item) =>
    item.roles.includes('SuperAdmin')
  ); // Filter menu items by userRole

  const drawer = (
    <div>
      <Toolbar className={`flex-col justify-center pl-5 pt-5 pr-5 mt-1`}>
        <img src={sidebarLogo} alt={'logo'} />
      </Toolbar>

      <List className="sidebarList ">
        {filteredSidebarData.map((text, index) => (
          <div key={index}>
            <ListItem
              disablePadding
              className={
                text?.name === intl.formatMessage({ id: 'Logout' })
                  ? 'sidebarBottom'
                  : index + 1 === filteredSidebarData?.length - 1
                    ? `mt-3 mb-5 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
                    : `mt-3 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
              }
            >
              <ListItemButton
                onClick={() => {
                  if (text.children) {
                    handleSubmenuToggle(text, index); // Toggle submenu
                  } else {
                    handleMenuItemClick(text, index);
                  }
                }}
                className={
                  activeIndex === index ||
                  (text.children && openSubmenuIndex === index)
                    ? 'active-menu-item '
                    : ''
                }
              >
                <ListItemIcon>
                  <Image
                    src={
                      activeIndex === index ||
                      (text.children && openSubmenuIndex === index)
                        ? text?.selectedIcon
                        : text?.icon
                    }
                    alt={'icon'}
                    style={{ height: '24px', width: '24px' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={text?.name}
                  className={
                    text?.name === intl.formatMessage({ id: 'Logout' })
                      ? 'Item-logout'
                      : 'Item-text'
                  }
                />
              </ListItemButton>
            </ListItem>

            {/* Submenu */}
            {text.children && openSubmenuIndex === index && (
              <List className="submenuList pb-0">
                {text.children.map((subItem, subIndex) => (
                  <ListItem
                    key={`${index}-${subIndex}`}
                    className="ml-0"
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        handleSubmenuRedirect(subItem, `${index}-${subIndex}`);
                      }}
                      className={
                        activeIndex === `${index}-${subIndex}`
                          ? 'active-submenu-item'
                          : ''
                      }
                    >
                      <ListItemText
                        className="Item-text"
                        primary={subItem.name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Row>
        <Col md={0} lg={2} className="sidebar">
          <Box sx={{ display: 'flex' }}>
            <AppBar>
              <Toolbar className="d-xl-none menu-container">
                <Stack direction="horizontal" gap={4} className="d-flex">
                  <MenuIcon
                    style={{
                      fontSize: '2rem',
                      color: '#292D32',
                      cursor: 'pointer',
                    }}
                    onClick={handleDrawerToggle}
                  />
                  <p className="mb-0 menu-text">
                    <FormattedMessage id="Absolute-Skin" />
                  </p>
                </Stack>
              </Toolbar>
            </AppBar>

            <Box component="nav" aria-label="mailbox folders">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                anchor={locale === 'en' ? 'left' : 'right'}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  display: { md: 'block', lg: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '240px',
                  },
                }}
              >
                {drawer}
              </Drawer>

              <Drawer
                variant="permanent"
                anchor={locale === 'en' ? 'left' : 'right'}
                className="drawer-menu d-none d-xl-block"
                sx={{
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    borderRight: 'none',
                  },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
          </Box>
        </Col>
      </Row>
      <CommonModal
        padding={'24px'}
        width={'500px'}
        modalActive={isModal}
        innerContent={
          <div>
            <Row>
              <Col xs={12}>
                <div className="">
                  <p className="p-0 m-0 fs-24 fc-292D32 family_outfit_semiBold_600">
                    Are you sure you want to log out?
                  </p>
                </div>
              </Col>
              <Col xs={12} md={12} className="mt-3 flex justify-end">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="items-center justify-end p-0"
                >
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#3C3C3C"
                    fontSize="14px"
                    fontWeight="500"
                    padding="12px 50px"
                    height="44px"
                    bgColor="transparent"
                    brColor="#EFE3D7"
                    fontFamily="outfit_medium"
                    onClick={() => setIsModal(false)}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>

                  <ReusableButton
                    fullWidth={false}
                    variant="contained"
                    color="#F5F5F5"
                    fontSize="14px"
                    fontWeight="500"
                    padding="12px 50px"
                    height="44px"
                    bgColor="#375241"
                    brColor="#375241"
                    fontFamily="outfit_medium"
                    onClick={() => handleLogOut()}
                  >
                    <FormattedMessage id="Logout" />
                  </ReusableButton>
                </Stack>
              </Col>
            </Row>
          </div>
        }
      />
    </>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
