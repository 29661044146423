import { combineReducers } from 'redux';
import userReducer from 'store/user/userReducer';
import registerUserReducer from './register/registerReducer';
import getCityReducer from './getCity/getCityReducer';
import registerStartReducer from './registerGetStart/registerGetStartReducer';
import registerUploadReducer from './registerUpload/registerUploadReducer';
import registerProviderTypeReducer from './registerProviderType/registerProviderTypeReducer';
import registerTitleReducer from './registerTitle/registerTitleReducer';
import registerQualificationReducer from './registerQualification/registerQualificationReducer';
import registerOneReducer from './registerStepOne/registerStepOneReducer';
import getReviewDetailReducer from './registerReview/registerReviewReducer';
import registerVerifyReducer from './registerVerify/registerVerifyReducer';
import getPracticeReducer from './registerPractice/registerPracticeReducer';
import registerProfessionalReducer from './registerProfessional/registerProfessionalReducer';
import registerFinalReducer from './registerFinal/registerFinalReducer';
import loginReducer from './login/LoginReduecer';
import getScheduleReducer from './getSchedule/getScheduleReducer';
import loginVerifyReducer from './verifyNumber/verifyNumberReducer';
import basicDetailsReducer from './BasicDetail/BasicDetailReducer';
import doctorLogoutReducer from './doctorLogout/doctorLogoutReducer';
import profileDataReducer from './doctorProfile/doctorProfileReducer';
import getScheduleDetailReducer from './getScheduleDetail/getScheduleDetailReducer';
import getMasterDataReducer from './masterData/masterDataReducer';
import createAvailReducer from './createAvailablity/createAvailablityReducer';
import updateAvailReducer from './updateAvailablity/updateAvailablityReducer';
import getAppointmentReducer from './getAppointment/getAppointmentReducer';
import getAppointmentUpcomigReducer from './getAppointment/getUpcomingAppointment/getUpcomingAppointmentReducer';
import getAppointmentDetailReducer from './getAppointmentDetail/getAppointmentDetailReducer';
import updateRequestDetailReducer from './updateRequestStatus/updateRequestStatusReducer';
import getVedioCallReducer from './VedioCallToken/vedioCallTokenReducer';
import getDashboardReducer from './dashboard/dashboardReducer';
import postPendingReducer from './createPendingDetail/createPendingDetailReducer';
import getPatientReducer from './getPatient/getPatientReducer';
import getPatientDetailReducer from './getPatientDetail/getPatientDetailReducer';

export default combineReducers({
  userReducer,
  loginReducer,
  loginVerifyReducer,
  registerUserReducer,
  getCityReducer,
  registerStartReducer,
  registerUploadReducer,
  registerProviderTypeReducer,
  registerTitleReducer,
  registerQualificationReducer,
  registerOneReducer,
  getReviewDetailReducer,
  registerVerifyReducer,
  getPracticeReducer,
  registerProfessionalReducer,
  registerFinalReducer,
  basicDetailsReducer,
  profileDataReducer,
  getMasterDataReducer,
  getDashboardReducer,
  getScheduleReducer,
  getScheduleDetailReducer,
  createAvailReducer,
  updateAvailReducer,
  getAppointmentReducer,
  getAppointmentUpcomigReducer,
  getAppointmentDetailReducer,
  updateRequestDetailReducer,
  getVedioCallReducer,
  postPendingReducer,
  getPatientReducer,
  getPatientDetailReducer,
  doctorLogoutReducer,
});
