import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Tabs,
  TabsList as BaseTabsList,
  TabPanel as BaseTabPanel,
  Tab as BaseTab,
  tabClasses,
} from '@mui/base';
import { buttonClasses } from '@mui/base/Button';

const Tab = styled(BaseTab)`
  font-size: 16px;
  font-family: outfit_regular;
  color: ${(props) => (props.tabColor ? props.tabColor : '#a2a2a2')};
  cursor: pointer;
  background-color: ${(props) =>
    props.tabBackgroundColor ? props.tabBackgroundColor : 'transparent'};
  width: 100%;
  line-height: 16px;
  padding: 12px 12px;
  margin: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.${tabClasses.selected} {
    background-color: ${(props) =>
      props.background ? props.background : '#fef9f6'};
    font-family: outfit_medium;
    color: ${(props) => (props.color ? props.color : '#864629')};
    align-items: center;
  }

  &.${buttonClasses.disabled} {
    opacity: 1;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: Inter_Regular;
  font-size: 0.875rem;
`;

// const TabsList = styled(BaseTabsList)(
//   ({ theme }) => `
//     min-width: 100%;
//     min-height:48px;
//     background-color: ${(props) =>
//       props.completeColor || 'transparent'} !important;
//     border: 1px solid #efe3d7;
//     margin-bottom: 0px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     align-content: space-between;

//     @media (max-width: 614px) {
//       flex-direction: column; /* Stack tabs vertically on small screens */
//       padding: 10px;
//     }

//     @media (min-width: 614px) {
//       flex-direction: row; /* Horizontal on large screens */
//     }
//   `
// );

const TabsList = styled(BaseTabsList)`
  min-width: 100%;
  min-height: 48px !important;
  background-color: ${(props) =>
    props.completeColor || 'transparent'} !important;
  // box-shadow: 0px 3px 16px 0px #00000014;
  box-shadow: ${(props) => props.boxShadow || ''} !important;
  border: 1px solid #efe3d7;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;

  @media (max-width: 614px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (min-width: 614px) {
    flex-direction: row;
  }
`;

const ReusableTabs = ({
  tabs,
  defaultTab,
  currentTab,
  image = false,
  background,
  color,
  completeColor,
  ...props
}) => {
  const handleChange = (val) => {
    currentTab(val);
  };

  return (
    <Tabs
      value={defaultTab}
      onChange={(e, newValue) => handleChange(newValue)}
      className="p-0"
      {...props}
    >
      <TabsList completeColor={completeColor} {...props}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            value={index + 1}
            background={background}
            color={color}
            {...props}
          >
            {/* {image && (
              <img
                src={selectedTab === index + 1 ? tab.colorIcon : tab.icon}
                alt="icon"
                className="me-2"
              />
            )} */}
            {tab.label}
          </Tab>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default ReusableTabs;
