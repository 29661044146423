import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAuldkvnmbGjXQqAb3wtuFM1rVJwQYxkqs',
  authDomain: 'absolut-skin.firebaseapp.com',
  projectId: 'absolut-skin',
  storageBucket: 'absolut-skin.firebasestorage.app',
  messagingSenderId: '898767385758',
  appId: '1:898767385758:web:b8dc31a0232c5ccbaf378f',
  measurementId: 'G-7KCBHHFE5Z',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export default firebase;
// const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
