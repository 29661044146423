import {
  GET_PATIENT_DETAIL,
  GET_PATIENT_DETAIL_SUCCESS,
  GET_PATIENT_DETAIL_FAILURE,
} from 'store/getPatientDetail/getPatientDetailAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const getPatientDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_DETAIL:
      return { ...state, loading: true };
    case GET_PATIENT_DETAIL_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case GET_PATIENT_DETAIL_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default getPatientDetailReducer;
