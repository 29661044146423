import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_SCHEDULE_LIST = 'GET_SCHEDULE_LIST';
export const GET_SCHEDULE_LIST_SUCCESS = 'GET_SCHEDULE_LIST_SUCCESS';
export const GET_SCHEDULE_LIST_FAILURE = 'GET_SCHEDULE_LIST_FAILURE';

// Action Creators
export const getScheduleRequest = () => ({
  type: GET_SCHEDULE_LIST,
});

export const getScheduleSuccess = (users) => ({
  type: GET_SCHEDULE_LIST_SUCCESS,
  payload: users,
});

export const getScheduleFailure = (error) => ({
  type: GET_SCHEDULE_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getSchedule = (external, value) => {
  return async (dispatch) => {
    dispatch(getScheduleRequest());
    try {
      const response = await getMethodWithToken(
        url?.getSchedule +
          '?appointment_type=upcoming' +
          `&appointment_date=${value}`
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getScheduleSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getScheduleFailure(error?.message));
    }
  };
};
