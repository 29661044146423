import {
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_REQUEST_SUCCESS,
  UPDATE_STATUS_REQUEST_FAILURE,
} from 'store/updateRequestStatus/updateRequestStatusAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const updateRequestDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STATUS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_STATUS_REQUEST_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case UPDATE_STATUS_REQUEST_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default updateRequestDetailReducer;
