import React, { useContext } from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { LocaleContext } from 'routes/Router';

const WrapperLabel = styled.div`
  font-size: 14px;
  line-height: 17.64px;
  font-family: outfit_regular;
  transform: none;
  color: #292d32;
  width: 100%;
`;
const WrapperDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
  width: 100% !important;
  border: 1px solid #efe3d7 !important;
  margin-top: ${(props) => props.marginTop} !important;
  direction: ${(props) =>
    props.locale === 'ar' ? 'rtl' : 'ltr'}; // Set the direction here
  .Mui-checked {
    transform: ${(props) =>
      props.locale === 'en'
        ? 'translateX(49%) !important'
        : 'translateX(-177px) !important'};
    input {
      left: ${(props) => props.locale === 'en' && '-48% !important'};
      right: ${(props) => props.locale === 'ar' && '-48% !important'};
    }
  }
  .MuiSwitch-root {
    width: ${(props) => props.width} !important;
    height: ${(props) => props.height} !important;
    background: ${(props) => props.backgroundColor} !important;
  }
  .MuiSwitch-thumb {
    height: ${(props) => props.thumbHeight} !important;
    margin: ${(props) => props.thumbMargin} !important;
    width: ${(props) => props.thumbWidth} !important;
    border-radius: ${(props) => props.thumbBorderRadius} !important;
  }
`;

const WrapperTypography = styled(Typography)`
  font-size: 16px !important;
  position: absolute;
  z-index: 1;
  color: rgba(51, 51, 51, 1);
  user-select: none;
  pointer-events: none;
  text-align: center !important;
  width: 178px;
  font-family: outfit_medium !important; /* Add this */
`;

const useStyles = makeStyles({
  root: {
    width: '360px !important',
    height: `48px !important`,
    padding: '0px !important',
    // borderRadius: '16px',
  },

  switchBase: {
    padding: '4px !important',
    width: '100%',
    justifyContent: 'start !important',
    '&:hover': {
      background: 'none !important',
    },
    '& input': {
      left: '0px !important',
      width: '100% !important',
    },
    '&$checked': {
      color: 'rgba(63, 25, 86, 1) !important',
      '& + $track': {
        backgroundColor: '#000',
        opacity: 1,
      },
      '& input': {
        width: '100% !important',
      },
    },
  },
  thumb: {
    width: '100% !important',
    height: '48px !important',
    boxShadow: 'none !important',
    backgroundColor: '#864629 !important',
    color: '#FFFFFF !important',
  },
  track: {
    backgroundColor: 'transparent !important',
    color: '#998A7B !important',
  },

  // Don't remove this empty class.
  checked: {},
});

function SwitchWithLabelInput({ onText, offText, label, checked, ...props }) {
  const { locale } = useContext(LocaleContext);
  const classes = useStyles({ props });

  return (
    <>
      <WrapperLabel>{label}</WrapperLabel>
      <WrapperDiv locale={locale} {...props}>
        <WrapperTypography
          variant="subtitle1"
          style={{
            right: locale === 'ar' && '6px',
            left: locale === 'en' && '6px',
            color: !checked ? 'rgba(255, 255, 255, 1)' : '#998A7B',
            fontFamily: 'outfit_medium',
          }}
        >
          {onText}
        </WrapperTypography>
        <Switch
          checked={checked}
          disableRipple={true}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            locale: classes.locale,
          }}
          {...props}
        />
        <WrapperTypography
          variant="subtitle1"
          style={{
            right: locale === 'ar' && '50%',
            left: locale === 'en' && '50%',
            color: !checked ? '#998A7B' : 'rgba(255, 255, 255, 1)',
            fontFamily: !checked ? 'outfit_medium' : 'outfit_regular',
          }}
        >
          {offText}
        </WrapperTypography>
      </WrapperDiv>
    </>
  );
}

SwitchWithLabelInput.propTypes = {
  checked: bool.isRequired,
};

SwitchWithLabelInput.defaultProps = {
  checked: false,
  width: '100%',
  height: '48px',
  thumbHeight: '40px',
  backgroundColor: 'transparent',
  thumbWidth: '50%',
  thumbMargin: '0px',
  borderRadius: '0px',
  thumbBorderRadius: '0px',
  marginTop: '4px',
};

export default SwitchWithLabelInput;
