import {
  REGISTER_UPLOAD,
  REGISTER_UPLOAD_SUCCESS,
  REGISTER_UPLOAD_FAILURE,
} from 'store/registerUpload/registerUploadAction';

const initialState = {
  loading: false,
  users: [],
  error: '',
};

const registerUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_UPLOAD:
      return { ...state, loading: true };
    case REGISTER_UPLOAD_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: '' };
    case REGISTER_UPLOAD_FAILURE:
      return { ...state, loading: false, users: [], error: action.payload };
    default:
      return state;
  }
};

export default registerUploadReducer;
