import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack, Image } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/commom/MuiBreadcrumbs';
import ArrowLeftIcon from 'assets/images/arrow-left.svg';
import HospitalIcon from 'assets/images/Dashboard/doctor-hospital.svg';
import VideoCallCameraIcon from 'assets/images/Dashboard/Video_Call.svg';
import DefaultImage from 'assets/images/Dashboard/default-list.svg';
import CallIcon from 'assets/images/Dashboard/call.svg';
import MailIcon from 'assets/images/Dashboard/mail.svg';
import ClockIcon from 'assets/images/Dashboard/clock.svg';
import CalerderIcon from 'assets/images/Dashboard/calendar.svg';
import { doctorAppointments } from 'routes/AppUrls';
import AvtarFrameImage from 'assets/images/Dashboard/AvtarFrame.png';
import photoImageIcon from 'assets/images/Dashboard/photoImage.png';
import rightIcon from 'assets/images/Dashboard/check.png';
import ReusableButton from 'components/commom/Button';
import CommonModal from 'components/commom/Modal';
import LabelWithInput from 'components/commom/LabelWithInput';
import { useDispatch } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { getAppointmentDetail } from 'store/getAppointmentDetail/getAppointmentDetailAcition';
import moment from 'moment';
import { updateRequestDetail } from 'store/updateRequestStatus/updateRequestStatusAction';
import { ClearTokenRedirectLogin } from 'utils/general';

const RequestDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const params = useParams();
  const { locale } = useContext(LocaleContext);
  const [isModal, setIsModal] = useState(false);
  const [conf, setConf] = ToastNotification();
  const [appointmentRequestDetail, setAppointmentRequestDetail] = useState('');

  useEffect(() => {
    getRequestDetail();
  }, []);

  const getRequestDetail = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setAppointmentRequestDetail(res?.data);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getAppointmentDetail(external, params?.id));
  };

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Appointments" />,
      Link: doctorAppointments(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Appointment Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);
  const handleAccept = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(doctorAppointments(locale));
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {
      appointment_id: params?.id,
      is_accept: true,
    };
    dispatch(updateRequestDetail(external, body));
  };

  return (
    <Row className="bc-FEF9F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />

        <Row className="bc-FFFFFF p-24 border-1 bColor-EFE3D7">
          <Col xs={12} md={12} className="p-0">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={ArrowLeftIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
                className="cursor-pointer"
                onClick={() => navigate(doctorAppointments(locale))}
              />
              <div className="fs-16 lh-16 fc-292D32 family_outfit_medium_500">
                <FormattedMessage id="Appointment no." />
                {appointmentRequestDetail?.booking_id}
              </div>
            </Stack>
          </Col>

          <Col xs={12} md={12} className="mt-4 border-b pb-4 p-0 bColor-D6DDE6">
            <Row>
              <Col md={12}>
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="flex items-start"
                >
                  <Image
                    src={
                      appointmentRequestDetail?.patient?.profile_pic !== ''
                        ? appointmentRequestDetail?.patient?.profile_pic
                        : DefaultImage
                    }
                    alt={'icon'}
                    style={{
                      height: '88px',
                      width: '88px',
                      borderRadius: '50%',
                    }}
                  />
                  <div>
                    <div className="fs-18 lh-2268 fc-202224 family_outfit_medium_500">
                      {appointmentRequestDetail?.patient?.full_name}
                    </div>
                    <div className="pt-12 fs-14 fc-998A7B family_outfit_regular_400">
                      ID:
                      <span className="fc-3C3C3C family_outfit_medium_500">
                        {appointmentRequestDetail?.patient?.patient_id}
                      </span>
                    </div>
                    <div>
                      <Stack
                        direction="horizontal"
                        gap={5}
                        className="items-center pt-12"
                      >
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <Image src={CallIcon} alt="icon" />
                          <div className="fs-16 lh-201 fc-202224 family_outfit_regular_400">
                            {`${appointmentRequestDetail?.patient?.country_code} ${appointmentRequestDetail?.patient?.contact_number}`}
                          </div>
                        </Stack>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img src={MailIcon} alt="icon" />
                          <div className="fs-16 lh-201 fc-202224 family_outfit_regular_400">
                            {appointmentRequestDetail?.patient?.email}
                          </div>
                        </Stack>
                      </Stack>
                    </div>
                  </div>
                </Stack>
              </Col>
              {/* <Col md={7}>
                <Row>
                  <Col sm={6}>
                    <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                      <FormattedMessage id="Address" />
                    </div>
                    <div className="pt-8 fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                      {'089 Kutch Green Apt. 448'}
                    </div>
                  </Col>
                  <Col sm={6} className="text-right	">
                    <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                      <FormattedMessage id="Joining Date" />
                    </div>
                    <div className="pt-8 fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                      {'23 Nov 2019'}
                    </div>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>

          <Col xs={12} md={12} className="p-0">
            <div className="pt-15">
              <Row className="p-0">
                <Col
                  xs={12}
                  md={12}
                  lg={7}
                  xl={7}
                  className="flex items-center"
                >
                  <Stack
                    direction="horizontal"
                    gap={5}
                    className="items-center"
                  >
                    <Stack
                      direction="horizontal"
                      gap={1}
                      className="flex items-center"
                    >
                      <div className="fs-16 lh-201 fc-998A7B family_outfit_regular_400">
                        Modality:
                      </div>
                      <div>
                        <img
                          src={
                            appointmentRequestDetail?.consultation_type ===
                            'Hospital Visit'
                              ? HospitalIcon
                              : VideoCallCameraIcon
                          }
                          alt="icon"
                        />
                      </div>
                      <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                        {/* Video Consultation */}
                        {appointmentRequestDetail?.consultation_type}
                      </div>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <Image src={CalerderIcon} alt="icon" />
                      <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                        {moment(
                          appointmentRequestDetail?.appointment_date_time
                        ).format('MMMM D, YYYY')}
                      </div>
                    </Stack>
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center"
                    >
                      <Image src={ClockIcon} alt="icon" />
                      <div className="fs-16 lh-201 fc-292D32 family_outfit_medium_500">
                        {moment(
                          appointmentRequestDetail?.appointment_date_time
                        ).format('hh:mmA')}
                      </div>
                    </Stack>
                  </Stack>
                </Col>
                <Col xs={12} md={12} lg={5} xl={5} className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center justify-end p-0"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#864629"
                      fontSize="14px"
                      fontWeight="500"
                      padding="12px 20px"
                      height="40px"
                      bgColor="transparent"
                      brColor="#EFE3D7"
                      fontFamily="outfit_medium"
                      onClick={() => setIsModal(true)}
                    >
                      <FormattedMessage id="Suggest another slot" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="14px"
                      fontWeight="500"
                      padding="12px 20px"
                      height="40px"
                      bgColor="#864629"
                      brColor="#375241"
                      fontFamily="outfit_medium"
                      onClick={() => handleAccept()}
                    >
                      <img
                        src={rightIcon}
                        alt="icon"
                        style={{ height: '18px', width: '18px' }}
                        className="me-2"
                      />
                      <FormattedMessage id="Accept Request" />
                    </ReusableButton>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="mt-16">
          <Row className="bc-FFFFFF p-24 border-1 bColor-EFE3D7">
            <Col xs={12} md={6} className="p-0">
              <div className="fs-16 lh-252  fc-292D32 family_outfit_medium_500">
                <FormattedMessage id="Patient’s form" />
              </div>
              <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400 pt-24">
                <FormattedMessage id="Symptoms" />
              </div>
              <div className="fs-16 lh-201 fc-3C3C3C pt-04 family_outfit_regular_400">
                {appointmentRequestDetail?.symptoms &&
                  appointmentRequestDetail?.symptoms.length > 0 &&
                  appointmentRequestDetail.symptoms
                    .map((item) => item?.name)
                    .filter((name) => name)
                    .join(', ')}
              </div>

              {appointmentRequestDetail?.attachments &&
                appointmentRequestDetail?.attachments.length > 0 && (
                  <>
                    <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                      <FormattedMessage id="Attachments" />
                    </div>

                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center pt-04"
                    >
                      {appointmentRequestDetail?.attachments.map((item, i) => {
                        return (
                          <Image
                            src={item?.image}
                            alt="icon"
                            style={{
                              height: '130px',
                              width: '130px',
                              boxShadow:
                                'rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 5px 0px',
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </>
                )}

              <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                <FormattedMessage id="Description" />
              </div>
              <div className="pt-04 fs-16 lh-201 fc-3C3C3C family_outfit_regular_400">
                {appointmentRequestDetail?.description}
              </div>
            </Col>

            <Col xs={12} md={6} className="p-0 mt-md-0 mt-3">
              {/* <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                <FormattedMessage id="Medications" />
              </div>
              <div className="pt-04 fs-16 lh-201 fc-3C3C3C  family_outfit_regular_400">
                <FormattedMessage id="None" />
              </div>
              <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                <FormattedMessage id="Medical Conditions" />
              </div>
              <div className="pt-04 fs-16 lh-201 fc-3C3C3C  family_outfit_regular_400">
                <FormattedMessage id="None" />
              </div>
              <div className="mt-4 fs-14 lh-176 fc-998A7B family_outfit_regular_400">
                <FormattedMessage id="Allergies" />
              </div>
              <div className="pt-04 fs-16 lh-201 fc-3C3C3C  family_outfit_regular_400">
                <FormattedMessage id="Lorem ipsum" />
              </div> */}
              <div className="fs-16 lh-252 fc-292D32 family_outfit_medium_500">
                <FormattedMessage id="Medical History" />
              </div>
              {appointmentRequestDetail?.medical_history &&
                appointmentRequestDetail?.medical_history.length > 0 &&
                appointmentRequestDetail?.medical_history.map((item, index) => {
                  return (
                    <>
                      <div className="fs-14 lh-176 fc-998A7B family_outfit_regular_400 pt-24">
                        {item?.question}
                      </div>
                      <div className="pt-04 fs-16 lh-201 fc-3C3C3C  family_outfit_regular_400">
                        {item?.answer}
                      </div>
                    </>
                  );
                })}
            </Col>
          </Row>
        </div>
      </Col>

      {/* ///////////////////Modal////////////////////// */}
      <CommonModal
        padding={'24px'}
        width={'500px'}
        modalActive={isModal}
        innerContent={
          <div>
            <Row>
              <Col xs={12}>
                <div className="fs-24 lh-3024 fc-292D32 family_outfit_semiBold_600">
                  Suggest another slot
                </div>

                <Row className="m-0 p-0">
                  <Col md={12} lg={12} className="mt-3 p-0">
                    <LabelWithInput
                      label="Date"
                      type="date"
                      // value={currentTabDetails.shift.start}
                      // disabled={currentTabDetails.isHoliday}
                      // onChange={(e) =>
                      //   handleShiftChange(tabIndex, 'start', e.target.value)
                      // }
                    />
                  </Col>

                  <Col md={12} lg={6} className="mt-3 p-0">
                    <LabelWithInput
                      label="Start Time"
                      type="time"
                      // value={currentTabDetails.shift.start}
                      // disabled={currentTabDetails.isHoliday}
                      // onChange={(e) =>
                      //   handleShiftChange(tabIndex, 'start', e.target.value)
                      // }
                    />
                  </Col>

                  <Col md={12} lg={6} className="mt-3 modal-size">
                    <LabelWithInput
                      label="End Time"
                      type="time"
                      // value={currentTabDetails.shift.start}
                      // disabled={currentTabDetails.isHoliday}
                      // onChange={(e) =>
                      //   handleShiftChange(tabIndex, 'start', e.target.value)
                      // }
                    />
                  </Col>

                  <Col xs={12} md={12} className="mt-3 p-0 flex justify-end">
                    <Stack
                      direction="horizontal"
                      gap={2}
                      className="items-center justify-end p-0"
                    >
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#3C3C3C"
                        fontSize="14px"
                        fontWeight="500"
                        padding="12px 50px"
                        height="44px"
                        bgColor="transparent"
                        brColor="#EFE3D7"
                        fontFamily="outfit_medium"
                        onClick={() => setIsModal(false)}
                      >
                        <FormattedMessage id="Cancel" />
                      </ReusableButton>

                      <ReusableButton
                        fullWidth={false}
                        variant="contained"
                        color="#F5F5F5"
                        fontSize="14px"
                        fontWeight="500"
                        padding="12px 50px"
                        height="44px"
                        bgColor="#375241"
                        brColor="#375241"
                        fontFamily="outfit_medium"
                      >
                        <FormattedMessage id="Done" />
                      </ReusableButton>
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        }
      />
      {/* ///////////////////Modal////////////////////// */}
    </Row>
  );
};

export default RequestDetails;
