import React, { useContext, useRef, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import 'assets/styles/common.scss';
import AbsolutLogo from 'assets/images/absolute-logo.svg';
import AbsoluteLogoMobile from 'assets/images/absolute-logo-phone.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Button from '@mui/material/Button';
import ArrowBack from 'assets/images/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { doctorDashboard, register } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';
import { useDispatch } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { loginUsers } from 'store/login/LoginAction';
import firebase from 'components/firebase/firebase';
import { loginVerifyNumber } from 'store/verifyNumber/verifyNumberAction';
import LocalStorageManager from 'managers/LocalStorageManger';
import { MuiOtpInput } from 'mui-one-time-password-input';
import styled from 'styled-components';

const CustomMuiOtpInput = styled(MuiOtpInput)({
  '& .MuiOutlinedInput-root': {
    border: 'none',
    borderBottom: '1px solid #864629',
    borderRadius: '0px',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '48px',
    color: '#3C3C3C',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '0px',
  },
});

const Login = () => {
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [otpVerification, setOtpVerification] = useState('');
  const [loginScreenVisible, setLoginScreenVisible] = useState(true);
  const [otpValue, setOtpValue] = useState('');

  const handleChange = (newValue) => {
    setOtpValue(newValue);
  };

  const handlePhoneChange = (value, country) => {
    const countryCode = `+${country?.dialCode}`;
    const contactNumber = value.replace(country?.dialCode, '').trim();

    setPhoneNumber(contactNumber);
    setCountryCode(countryCode);
  };
  const phoneNumberSignIn = () => {
    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.innerHTML = '<div id="recaptcha-container"></div>';
      }
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: (response) => {},
            'expired-callback': () => {},
          }
        );
      }
      window.recaptchaVerifier.render().then((widgetId) => {
        const contactNumber = `${countryCode}${phoneNumber.replace(/[\(\)\-\s]/g, '')}`;

        firebase
          .auth()
          .signInWithPhoneNumber(contactNumber, window.recaptchaVerifier)
          .then((confirmationResult) => {
            setLoginScreenVisible(false);
            setOtpVerification(confirmationResult);
          })
          .catch((error) => {
            if (error.code === 'auth/invalid-app-credential') {
              console.error('The application credentials are not valid.');
            }
          });
      });
    } catch (err) {
      console.error('Unexpected error:', err);
      setLoginScreenVisible(false);
    }
  };
  const hanldeLogin = () => {
    if (!phoneNumber) {
      setConf({ msg: 'Please Enter Phone Number', variant: 'error' });
    } else {
      const external = {
        run: true,
        handleFunction: (res) => {},
        verify: true,
        handleVerifyFunction: (res) => {
          phoneNumberSignIn();
        },
        error: true,
        handleFailerFunction: (res) => {
          setConf({ msg: res?.message, variant: 'error' });
        },
      };
      let body = {
        username: phoneNumber.toString(),
      };
      dispatch(loginVerifyNumber(body, external));
    }
  };

  const hanldeResend = () => {
    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.innerHTML = '<div id="recaptcha-container"></div>';
      }
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: (response) => {},
            'expired-callback': () => {},
          }
        );
      }
      window.recaptchaVerifier.render().then((widgetId) => {
        const contactNumber = `${countryCode}${phoneNumber.replace(/[\(\)\-\s]/g, '')}`;

        firebase
          .auth()
          .signInWithPhoneNumber(contactNumber, window.recaptchaVerifier)
          .then((confirmationResult) => {
            setLoginScreenVisible(false);
            setOtpVerification(confirmationResult);
          })
          .catch((error) => {
            setLoginScreenVisible(false);
          });
      });
    } catch (err) {
      setLoginScreenVisible(true);
    }
  };

  const createDoctor = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setConf({ msg: res?.message, variant: 'success' });
        navigate(doctorDashboard(locale));
        LocalStorageManager.setItem('token', res?.data?.key);
      },
      error: true,
      handleFailerFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    let body = {
      username: phoneNumber.toString(),
    };
    dispatch(loginUsers(body, external));
  };

  const handleverifyOtp = () => {
    if (otpValue !== '') {
      otpVerification?.confirm(otpValue).then((result) => {
        createDoctor();
      });
    }
  };

  const handleRegister = () => {
    navigate(register(locale));
  };

  return (
    <>
      {loginScreenVisible ? (
        <Row className="m-0 p-0 landing-image">
          <Row className="m-0 p-0 flex justify-content-center">
            <Col
              xs={12}
              sm={10}
              md={10}
              lg={6}
              xl={6}
              xxl={4}
              className="bg-gray-100 sm:bg-light-100 m-sm-auto m-lg-auto login-card p-0"
            >
              <div class="p-15 sm:p-32">
                <div className="d-none d-sm-flex justify-content-center">
                  <Image src={AbsolutLogo} alt={'icon'} />
                </div>
                <div className="d-flex d-sm-none justify-content-between">
                  <div>
                    <Image src={ArrowBack} alt={'icon'} />
                  </div>
                  <div>
                    <Image src={AbsoluteLogoMobile} alt={'icon'} />
                  </div>
                </div>
                <div className="main-heading pt-8 sm:pt-24 family_outfit_semiBold_600">
                  Login
                </div>
                <div className="main-description pt-12 fs-16 family_outfit_regular_400 lh-208 fc-998A7B">
                  Please enter your details to sign in
                </div>
                <div>
                  <label
                    htmlFor="phone-input"
                    className="pt-24 fs-14 family_outfit_regular_400 lh-176 fc-3C3C3C"
                  >
                    Mobile Number
                  </label>
                  <PhoneInput
                    id="phone-input"
                    country={'in'}
                    inputProps={{
                      name: 'phoneNumber',
                      required: true,
                    }}
                    name={'phoneNumber'}
                    value={`${countryCode}${phoneNumber}`}
                    onChange={(value, country) =>
                      handlePhoneChange(value, country)
                    }
                    placeholder="Enter phone number"
                    inputClass="custom-input-register"
                    containerClass="custom-container-register"
                  />
                </div>
                <div className="sm:pt-28 mt-190 sm:mt-0">
                  <Button
                    variant="contained"
                    className="button w-100"
                    onClick={() => hanldeLogin()}
                  >
                    Login
                  </Button>
                </div>
                <div ref={recaptchaRef}></div>
                <div className="pt-40 flex justify-center fs-18 family_outfit_regular_400 lh-27 fc-7F7F7F">
                  Don’t have an account?
                </div>
                <div
                  className="pt-04 flex justify-center fs-20 family_outfit_medium_500 fc-3C3C3C lh-30 cursor-pointer"
                  onClick={() => handleRegister()}
                >
                  Register
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      ) : (
        <Row className="m-0 p-0 landing-image">
          <Row className="m-0 p-0 flex flex justify-center">
            <Col
              xs={12}
              sm={10}
              md={10}
              lg={6}
              xl={6}
              xxl={4}
              className="bg-gray-100 sm:bg-light-100 m-sm-auto m-lg-auto otp-card p-0"
            >
              <div class="p-15 sm:p-32">
                <div className="d-none d-sm-flex justify-content-center">
                  <Image src={AbsolutLogo} alt={'icon'} />
                </div>
                <div className="d-flex d-sm-none justify-content-between">
                  <div>
                    <Image src={ArrowBack} alt={'icon'} />
                  </div>
                  <div>
                    <Image src={AbsoluteLogoMobile} alt={'icon'} />
                  </div>
                </div>
                <div className="main-heading pt-8 sm:pt-24 family_outfit_semiBold_600">
                  Verification
                </div>
                <div className="pt-12 fs-16 family_outfit_regular_400 lh-208 fc-998A7B pt-12">
                  Enter the OTP has been sent to 8796412361
                </div>
                <div className="pt-24 sm:pt-40">
                  <CustomMuiOtpInput
                    length={6}
                    value={otpValue}
                    onChange={handleChange}
                  />
                </div>
                <div className="fs-18 family_outfit_regular_400 lh-27 fc-7F7F7F pt-40 flex justify-center">
                  Did not receive the OTP?
                </div>
                <div
                  className="fs-20 family_outfit_medium_500 fc-3C3C3C lh-30 pt-04 flex justify-center cursor-pointer ..."
                  onClick={() => hanldeResend()}
                >
                  Resend
                </div>
                <div ref={recaptchaRef}></div>
                <div className="sm:pt-40 mt-80 sm:mt-0">
                  <Button
                    className="button w-100 family_outfit_semiBold_600 normal-case"
                    onClick={() => handleverifyOtp()}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
};
export default Login;
