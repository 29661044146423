import { EmailValidation } from 'utils/helper';
import { FormattedMessage } from 'react-intl';

const CheckValidation = (value) => {
  const formError = {
    submit: true,
    providerName: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    email: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    mobileNumber: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    state: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    city: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    stateId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    cityId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    phone: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    country_code: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    providerType: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    highestQualification: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    highestQualificationId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    latitude: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    longitude: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    streetAddress: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    zipCode: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    title: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    titleSlug: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    bio: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    profileId: {
      error: '',
      errorMessage: <FormattedMessage id="field is required!" />,
    },
    // stateCode: {
    //   error: {},
    //   errorMessage: <FormattedMessage id="field is required!" />,
    // },
    // stateCodeId: {
    //   error: [],
    //   errorMessage: <FormattedMessage id="field is required!" />,
    // },
    // amount: {
    //   error: {},
    //   errorMessage: <FormattedMessage id="field is required!" />,
    // },
    // uploadListId: {
    //   error: [],
    //   errorMessage: <FormattedMessage id="field is required!" />,
    // },
  };

  const emptyKeys = [];
  for (let key in value) {
    emptyKeys.push(key);
  }

  for (let i = 0; i < emptyKeys.length; i++) {
    if (!value[emptyKeys[i]]) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
    }

    if (
      value[emptyKeys[i]] &&
      emptyKeys[i] === 'email' &&
      !EmailValidation(value[emptyKeys[i]])
    ) {
      formError[emptyKeys[i]].error = true;
      formError.submit = false;
      formError[emptyKeys[i]].errorMessage = (
        <FormattedMessage id="Please Enter Valid Email!" />
      );
    }
  }
  return formError;
};

export default CheckValidation;
