import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

// Action Creators
export const getProfile = () => ({
  type: GET_PROFILE,
});

export const getProfileSuccess = (users) => ({
  type: GET_PROFILE_SUCCESS,
  payload: users,
});

export const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  payload: error,
});

// Thunk Function
export const getProfileData = (external) => {
  return async (dispatch) => {
    dispatch(getProfile());
    try {
      const response = await getMethodWithToken(url?.doctorProile);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getProfileSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getProfileFailure(error?.message));
    }
  };
};
