import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const REGISTER_FINAL = 'REGISTER_FINAL';
export const REGISTER_FINAL_SUCCESS = 'REGISTER_FINAL_SUCCESS';
export const REGISTER_FINAL_FAILURE = 'REGISTER_FINAL_FAILURE';

// Action Creators
export const registerFinalRequest = () => ({
  type: REGISTER_FINAL,
});

export const registerFinalSuccess = (users) => ({
  type: REGISTER_FINAL_SUCCESS,
  payload: users,
});

export const registerFinalFailure = (error) => ({
  type: REGISTER_FINAL_FAILURE,
  payload: error,
});

// Thunk Function

export const registerFinalPost = (body, external, userId, requestId) => {
  return async (dispatch) => {
    dispatch(registerFinalRequest());
    try {
      const response = await postMethod(
        url.postReview + userId + '/' + requestId + '/',
        body
      );
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(registerFinalSuccess(response));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(registerFinalFailure(error));
      if (external?.error === true) {
        external?.handleErrorFunction(error);
      }
    }
  };
};
