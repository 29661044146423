import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_PATIENT_DETAIL = 'GET_PATIENT_DETAIL';
export const GET_PATIENT_DETAIL_SUCCESS = 'GET_PATIENT_DETAIL_SUCCESS';
export const GET_PATIENT_DETAIL_FAILURE = 'GET_PATIENT_DETAIL_FAILURE';

// Action Creators
export const getPatientDetailRequest = () => ({
  type: GET_PATIENT_DETAIL,
});

export const getPatientDetailSuccess = (users) => ({
  type: GET_PATIENT_DETAIL_SUCCESS,
  payload: users,
});

export const getPatientDetailFailure = (error) => ({
  type: GET_PATIENT_DETAIL_FAILURE,
  payload: error,
});

// Thunk Function
export const getPatientDetail = (external, id) => {
  return async (dispatch) => {
    dispatch(getPatientDetailRequest());
    try {
      const response = await getMethodWithToken(
        url?.getPatientDetail + id + '/'
      );
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getPatientDetailSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getPatientDetailFailure(error?.message));
    }
  };
};
