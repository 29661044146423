import React, { useContext } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { string } from 'prop-types';
import { LocaleContext } from 'routes/Router';

const WrapperLink = styled(Link)`
  font-size: ${(props) => props.size + 'px'} !important;
  font-weight: ${(props) => props.fontWeight} !important;
  font-family: ${(props) => props.fontFamily} !important;
  color: ${(props) =>
    props.disabled === true ? '#292D32' : '#998A7B'} !important;
  margin: ${(props) => props.margin + 'px'} !important;
  padding: ${(props) => props.padding + 'px'} !important;
  text-decoration: ${(props) => props.TextDecoration} !important;
  cursor: ${(props) => props.cursor} !important;
  pointer-events: ${(props) => (props.disabled === true ? 'none' : '')}
    ${(props) =>
      props.lineHeight &&
      `
        line-height:  ${props.lineHeight + 'px'} !important;
    `}
    ${(props) =>
      props.borderBottom &&
      `
        border-bottom:  1px solid #FFFFFF;
    `};
`;

function MuiLink(props) {
  return <WrapperLink {...props}>{props.children}</WrapperLink>;
}

MuiLink.propTypes = {
  color: string.isRequired,
};

MuiLink.defaultProps = {
  size: 14,
  fontFamily: 'Akkurat',
  cursor: 'pointer',
  fontWeight: 400,
  textColor: '#0054F6',
  TextDecoration: 'none',
};

export default MuiLink;
