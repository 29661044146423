import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';
import 'components/navbar/navbar.scss';
import langIconEn from 'assets/images/navbar/arabicLangIcon.png';
import langIconAr from 'assets/images/navbar/englishLangIcon.png';
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import ToastNotification from 'components/commom/Toast';
import { getProfileData } from 'store/doctorProfile/doctorProfileAction';
import { ClearTokenRedirectLogin } from 'utils/general';
import LocalStorageManager from 'managers/LocalStorageManger';

const CustomNavbar = () => {
  const navigate = useNavigate();
  const { locale, setLocale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const [profileValue, setProfileValue] = useState('');

  const LanguageSwitcher = (value) => {
    setLocale(value);
  };
  useEffect(() => {
    profileData();
  }, []);

  const profileData = () => {
    const external = {
      run: true,
      handleFunction: (res) => {
        setProfileValue(res?.data);
        LocalStorageManager.setItem('doctorId', res?.data?.id);
      },
      authorize: true,
      handleAuthorizeFunction: (res) => {
        // ClearTokenRedirectLogin(navigate);
      },
      error: true,
      handleErrorFunction: (res) => {
        setConf({ msg: res?.message, variant: 'error' });
      },
    };
    dispatch(getProfileData(external));
  };

  return (
    <Navbar className={`d-flex ${locale === 'en' ? 'navbarEn' : 'navbarAr'}`}>
      <Container fluid>
        <Navbar.Toggle aria-controls="navbar-nav" />
        {/* <Navbar.Collapse id="navbar-nav"> */}
        <div
          className={`d-flex ${locale === 'en' ? 'ml-auto' : 'mr-auto'} items-center`}
        >
          {/* <Nav.Link
            className="d-flex align-items-center"
            onClick={() =>
              locale === 'en' ? LanguageSwitcher('ar') : LanguageSwitcher('en')
            }
          >
            <img
              src={locale === 'en' ? langIconAr : langIconEn}
              alt="Language Icon"
              width="40"
              height="40"
              className={locale === 'en' ? 'mr-5' : 'ml-5'}
            />{' '}
          </Nav.Link> */}
          <div
            className={`d-flex align-items-center ${locale === 'en' ? 'ms-4' : 'me-4'}`}
          >
            <Avatar className={locale === 'en' ? 'me-3' : 'ms-3'}>
              {profileValue?.profile_image !== '' ? (
                <Image src={profileValue?.profile_image} alt="icon" />
              ) : (
                profileValue?.full_name
                  ?.split(' ')
                  .map((word) => word[0])
                  .join('')
                  .toUpperCase()
              )}
            </Avatar>
            <p
              className={`p-0 m-0 fs-14 fc-404040 family_outfit_semiBold_600 ${locale === 'en' ? 'me-4' : 'ms-4'}`}
            >
              {profileValue?.full_name}
            </p>
          </div>
        </div>
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
