import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const REGISTER_VERIFY = 'REGISTER_VERIFY';
export const REGISTER_VERIFY_SUCCESS = 'REGISTER_VERIFY_SUCCESS';
export const REGISTER_VERIFY_FAILURE = 'REGISTER_VERIFY_FAILURE';

// Action Creators
export const registerVerifyRequest = () => ({
  type: REGISTER_VERIFY,
});

export const registerVerifySuccess = (users) => ({
  type: REGISTER_VERIFY_SUCCESS,
  payload: users,
});

export const registerVerifyFailure = (error) => ({
  type: REGISTER_VERIFY_FAILURE,
  payload: error,
});

// Thunk Function

export const registerVerifyPost = (body, external) => {
  return async (dispatch) => {
    dispatch(registerVerifyRequest());
    try {
      const response = await postMethod(url.postVerifyToken, body);
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(registerVerifySuccess(response));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(registerVerifyFailure(error));
      if (external?.error === true) {
        external?.handleErrorFunction(error);
      }
    }
  };
};
