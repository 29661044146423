import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

// Action Creators
export const getDashboardRequest = () => ({
  type: GET_DASHBOARD,
});

export const getDashboardSuccess = (users) => ({
  type: GET_DASHBOARD_SUCCESS,
  payload: users,
});

export const getDashboardFailure = (error) => ({
  type: GET_DASHBOARD_FAILURE,
  payload: error,
});

// Thunk Function
export const getDashboard = (external) => {
  return async (dispatch) => {
    dispatch(getDashboardRequest());
    try {
      const response = await getMethodWithToken(url?.doctorDashboard);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getDashboardSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getDashboardFailure(error?.message));
    }
  };
};
