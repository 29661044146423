import axios from 'axios';
import { getMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_REVIEW_DETAIL = 'GET_REVIEW_DETAIL';
export const GET_REVIEW_DETAIL_SUCCESS = 'GET_REVIEW_DETAIL_SUCCESS';
export const GET_REVIEW_DETAIL_FAILURE = 'GET_REVIEW_DETAIL_FAILURE';

// Action Creators
export const getReviewRequest = () => ({
  type: GET_REVIEW_DETAIL,
});

export const getReviewSuccess = (users) => ({
  type: GET_REVIEW_DETAIL_SUCCESS,
  payload: users,
});

export const getReviewFailure = (error) => ({
  type: GET_REVIEW_DETAIL_FAILURE,
  payload: error,
});

// Thunk Function
export const getReviewDetail = (userId, external) => {
  return async (dispatch) => {
    dispatch(getReviewRequest());
    try {
      const response = await getMethod(url?.getReviewDetail + userId + '/');
      // dispatch(getCitySuccess(response?.data));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
          dispatch(getReviewSuccess(response?.data));
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getReviewFailure(error?.message));
    }
  };
};
