import axios from 'axios';
import { getMethod, getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_MASTER_DATA = 'GET_MASTER_DATA';
export const GET_MASTER_DATA_SUCCESS = 'GET_MASTER_DATA_SUCCESS';
export const GET_MASTER_DATA_FAILURE = 'GET_MASTER_DATA_FAILURE';

// Action Creators
export const getMasterRequest = () => ({
  type: GET_MASTER_DATA,
});

export const getMasterSuccess = (users) => ({
  type: GET_MASTER_DATA_SUCCESS,
  payload: users,
});

export const getMasterFailure = (error) => ({
  type: GET_MASTER_DATA_FAILURE,
  payload: error,
});

// Thunk Function
export const getMasterData = (external) => {
  return async (dispatch) => {
    dispatch(getMasterRequest());
    try {
      const response = await getMethodWithToken(url?.getSwitch);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getMasterSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 400) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getMasterFailure(error?.message));
    }
  };
};
