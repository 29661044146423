import axios from 'axios';
import { getMethod, postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_PROVIDER_TYPE = 'GET_PROVIDER_TYPE';
export const GET_PROVIDER_TYPE_SUCCESS = 'GET_PROVIDER_TYPE_SUCCESS';
export const GET_PROVIDER_TYPE_FAILURE = 'GET_PROVIDER_TYPE_FAILURE';

// Action Creators
export const getProviderTypeRequest = () => ({
  type: GET_PROVIDER_TYPE,
});

export const getProviderTypeSuccess = (users) => ({
  type: GET_PROVIDER_TYPE_SUCCESS,
  payload: users,
});

export const getProviderTypeFailure = (error) => ({
  type: GET_PROVIDER_TYPE_FAILURE,
  payload: error,
});

// Thunk Function
export const registerProviderType = (value, external) => {
  return async (dispatch) => {
    dispatch(getProviderTypeRequest());
    try {
      const response = await getMethod(url?.getSwitch + '?slug=' + value);
      dispatch(getProviderTypeSuccess(response?.data));
      if (external?.run === true) {
        if (
          response?.data?.status_code === 200 ||
          response?.data?.status_code === 201
        ) {
          external?.handleFunction(response?.data);
        } else {
          if (external?.error === true) {
            external?.handleErrorFunction(response?.data);
          }
        }
      }
    } catch (error) {
      dispatch(getProviderTypeFailure(error?.message));
    }
  };
};
