import axios from 'axios';
import { getMethodWithToken } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const GET_APPOINTMENT_LIST = 'GET_APPOINTMENT_LIST';
export const GET_APPOINTMENT_LIST_SUCCESS = 'GET_APPOINTMENT_LIST_SUCCESS';
export const GET_APPOINTMENT_LIST_FAILURE = 'GET_APPOINTMENT_LIST_FAILURE';

// Action Creators
export const getAppointmentRequest = () => ({
  type: GET_APPOINTMENT_LIST,
});

export const getAppointmentSuccess = (users) => ({
  type: GET_APPOINTMENT_LIST_SUCCESS,
  payload: users,
});

export const getAppointmentFailure = (error) => ({
  type: GET_APPOINTMENT_LIST_FAILURE,
  payload: error,
});

// Thunk Function
export const getAppointment = (external, tab) => {
  return async (dispatch) => {
    dispatch(getAppointmentRequest());
    try {
      let requesrListUrl = '';
      if (tab === 1) {
        requesrListUrl = url?.getSchedule + '?appointment_type=request';
      } else if (tab === 2) {
        requesrListUrl = url?.getSchedule + '?appointment_type=upcoming';
      } else if (tab === 3) {
        requesrListUrl = url?.getSchedule + '?appointment_type=pending';
      }
      const response = await getMethodWithToken(requesrListUrl);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(getAppointmentSuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 401) {
        if (external?.authorize === true) {
          external?.handleAuthorizeFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(getAppointmentFailure(error?.message));
    }
  };
};
