import axios from 'axios';
import { postMethod } from 'utils/services/apiService';
import url from 'utils/services/urls.json';

// Action Types
export const LOGIN_NUMBER_VERIFY = 'LOGIN_NUMBER_VERIFY';
export const LOGIN_NUMBER_VERIFY_SUCCESS = 'LOGIN_NUMBER_VERIFY_SUCCESS';
export const LOGIN_NUMBER_VERIFY_FAILURE = 'LOGIN_NUMBER_VERIFY_FAILURE';

// Action Creators
export const loginVerify = () => ({
  type: LOGIN_NUMBER_VERIFY,
});

export const loginVerifySuccess = (users) => ({
  type: LOGIN_NUMBER_VERIFY_SUCCESS,
  payload: users,
});

export const loginVerifyFailure = (error) => ({
  type: LOGIN_NUMBER_VERIFY_FAILURE,
  payload: error,
});

// Thunk Function

export const loginVerifyNumber = (body, external) => {
  return async (dispatch) => {
    dispatch(loginVerify());
    try {
      const response = await postMethod(url.phoneNumberVerify, body);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(loginVerifySuccess(response?.data));
        if (external?.run === true) {
          external?.handleFunction(response?.data);
        }
      } else if (response?.status === 400) {
        if (external?.verify === true) {
          external?.handleVerifyFunction(response?.data);
        }
      } else {
        external?.handleErrorFunction(response?.data);
      }
    } catch (error) {
      dispatch(loginVerifyFailure(error));
    }
  };
};
