import React, { useContext } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import SuccessIcon from 'assets/images/succes.svg';
import 'assets/styles/common.scss';
import { useNavigate } from 'react-router-dom';
import { login } from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';

const RegisterSuccess = () => {
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const handleLogin = () => {
    navigate(login(locale));
  };
  return (
    <>
      <Row className="m-0 p-0 landing-image">
        <Row className="m-0 p-0">
          <Col
            xs={12}
            sm={10}
            md={10}
            lg={12}
            xl={10}
            xxl={10}
            className="m-auto"
          >
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={7}
                xl={7}
                xxl={7}
                className="bg-light-100 sm:m-auto p-0 succes-card"
              >
                <div class="p-24">
                  <Row>
                    <Col sm={12}>
                      <div className="flex justify-center pt-40">
                        <Image src={SuccessIcon} alt={'icon'} />
                      </div>
                      <div className="flex justify-center pt-24 fs-32 fc-292D32 lh-416 family_outfit_semiBold_600">
                        Request Sent
                      </div>
                      <div className="flex justify-center text-center pt-8 fs-16 lh-16 fc-7F7F7F family_outfit_regular_400">
                        Our administrator will go through your request and
                        revert back accordingly
                      </div>
                    </Col>
                  </Row>
                  <div className="pt-40">
                    <Button
                      variant="contained"
                      className="button w-100"
                      onClick={() => handleLogin()}
                    >
                      Back to Home
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default RegisterSuccess;
