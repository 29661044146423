import React, { useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function CommonModal(props) {
  const style = {
    width: props?.width ? props?.width : '100%',
    height: 'fit-content',
    maxWidth: 1260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '0px',
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
        '@media (max-width: 760px)': {
          alignItems: 'flex-start !important', // Adjust for xs breakpoint
        },
      }}
      open={props?.modalActive}
      onClose={props?.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{props.innerContent}</Box>
    </Modal>
  );
}

CommonModal.defaultProps = {
  width: 862,
  padding: '32',
};

export default CommonModal;
